import { useState } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

type Props = {
  id: number | string;
  action: "approve" | "reject";
  message?: string;
};

export default function useChangeStatusPartial() {
  const [isFetching, setIsFetching] = useState(false);
  const [refresh, setRefresh] = useState<Date>();

  const changeStatus = async ({ id, action, message }: Props) => {
    try {
      setIsFetching(true);

      await api.post(`/v1/measurement-partials/status/${id}/${action}`, { message: message });

      setRefresh(new Date());
      toastAlert({
        type: "success",
        message: `Item ${action === "approve" ? "aprovado" : "reprovado"} com sucesso.`,
        title: "Sucesso",
      });
    } catch (error: any) {
      const msg = !!error.response.data.message ? error.response.data.message : "Confira sua conexão para tentar novamente.";
      toastAlert({
        type: "error",
        message: msg,
        title: `Erro ao ${action === "approve" ? "aprovar" : "reprovar"} item`,
      });
    } finally {
      setIsFetching(false);
    }
  };

  return { changeStatus, isFetching, refresh };
}
