import Report from "../../../models/Report";

export function ReturnCost(obj: Report, type: string) {
  if ((obj.measure_refused_by && !obj.measure_approved_by && type === "rejected") || (obj.status_id === 3 && type === "rejected"))
    return obj.pay_cost;
  if (!obj.measure_refused_by && type === "rejected") return null;

  if (obj.measure_approved_by && type === "approved") return obj.pay_cost;
  if (!obj.measure_approved_by && type === "approved") return null;
}
