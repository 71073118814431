import { Tag } from "antd";

export default function returnStatusEvidence(status: string, measure = false) {
  if (status === "approved") {
    return (
      <Tag color={"green"} key={status}>
        Completo
      </Tag>
    );
  } else if (status === "rejected" || status === "refused") {
    return (
      <Tag color={"volcano"} key={status}>
        Recusado
      </Tag>
    );
  } else {
    if (measure) {
      if (status === "pending") {
        return (
          <Tag color={"geekblue"} key={status}>
            Pendente
          </Tag>
        );
      } else {
        return (
          <Tag color={"purple"} key={status}>
            Não iniciado
          </Tag>
        );
      }
    }

    return (
      <Tag color={"geekblue"} key={status}>
        Pendente
      </Tag>
    );
  }
}
