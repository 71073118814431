import { useCallback } from "react";
import Report from "../../../models/Report";

export function CalculateTotalRefusedReport(reports: any[]) {
  const total = useCallback(() => {
    let totalCost = 0;
    reports.forEach((report: Report) => {
      if (!!report.measure_refused_by) {
        if (report.rejected_cost) totalCost += +report.rejected_cost;
      }
    });
    return totalCost;
  }, [reports]);

  return total();
}
