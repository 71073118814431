import { Button } from "antd";
import { useState } from "react";
import { FaEye } from "react-icons/fa6";

export function NavigateButton({
  navFunction,
  funcProps,
}: {
  navFunction: (data: any) => void;
  funcProps: any;
}) {
  const [loading, setLoading] = useState(false);

  return (
    <Button
      type="primary"
      loading={loading}
      disabled={loading}
      id="open-nav"
      className="wp-see-more"
      onClick={() => {
        setLoading(true);
        navFunction({ ...funcProps, afterLoading: () => setLoading(false) });
      }}
    >
      <FaEye />
      Abrir
    </Button>
  );
}
