import { z } from "zod";
export const ContractHiredWithWpsSchema = z.object({
  active: z.boolean(),
  amount: z.string(),
  description: z.string(),
  email: z.string().email(),
  hired_description: z.string(),
  name: z.string(),
  password: z.string(),
  phone: z.string(),
  wp_type: z.string(),
  wps_selected: z.array(z.number()).nonempty("WPS selection is required"),
});
