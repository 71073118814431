import { useState } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

type PropsCreate = {
  formData: {
    wpCode: string;
    discipline: number;
    subDiscipline: number;
    description: string;
    status: number;
    model: number[];
    activities: string[];
    justification: string;
  };
  cwa_id?: number | string;
};

export const useCreateWp = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [refresh, setRefresh] = useState<Date>();
  const [status, setStatus] = useState(0);

  const createWp = async ({ formData, cwa_id }: PropsCreate) => {
    setIsFetching(true);

    await api
      .post(`/v1/wps/create`, { ...formData, cwa_id })
      .then((response) => {
        setStatus(200);
        toastAlert({
          type: "success",
          message: "WP criado com sucesso. Ele já pode ser visto na lista de WPs.",
          title: "Sucesso",
        });
        setRefresh(new Date());
      })
      .catch((error: any) => {
        toastAlert({
          type: "error",
          message:
            "Não foi possível criar WP, verifique sua conexão e tente novamente.",
          title: "Erro ao criar WP",
        });
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  return { createWp, isFetching, refresh, status };
};
