import { useCallback, useEffect, useState } from "react";
import api from "../../config/axios";

type Props = {
  wpRequisitionId?: string | number;
  refresh: null | Date;
  loadingInitialValue?: boolean;
};

export const useFetchWpRequisition = ({ wpRequisitionId, refresh, loadingInitialValue }: Props) => {
  const [wpRequisition, setWpRequisition] = useState<any>();
  const [isFetchingWpRequisition, setIsFetchingWpRequisition] = useState(!!loadingInitialValue);
  const [error, setError] = useState<Error | null>(null);

  const returnWp = useCallback(() => {
    if (wpRequisitionId) {
      setIsFetchingWpRequisition(true);
      api
        .get(`v1/requisitions/${wpRequisitionId}`)
        .then((response) => {
          setWpRequisition(response.data);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setIsFetchingWpRequisition(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wpRequisitionId, refresh]);

  useEffect(() => {
    returnWp();
  }, [returnWp]);

  return { wpRequisition, isFetchingWpRequisition, error };
};
