import { useCallback, useEffect, useState } from "react";
import api from "../../config/axios";

type Props = {
  activity_id?: string | number;
  setModelList: (data: any) => void;
  refreshModelList: Date;
};

export const useFetchModelsByActivity = ({
  activity_id,
  setModelList,
  refreshModelList,
}: Props) => {
  const [isLoadingModels, setIsLoadingModels] = useState(true);

  const returnData = useCallback(async () => {
    if (activity_id) {
      setIsLoadingModels(true);
      await api
        .get(`/v1/files/get-models-by-activity-id/${activity_id}`)
        .then(async (response) => {
          setModelList(response?.data);
        });

      setIsLoadingModels(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity_id, refreshModelList]);

  useEffect(() => {
    returnData();
  }, [returnData]);

  return { isLoadingModels };
};
