import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Divider, Form, Pagination, Table } from "antd";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import api from "../../services/Api";
import { toastAlert } from "../../utils/toastAlert";
import { FormProvider } from "../form/FormProvider";
import { InputNumber, Select, Switch, TextArea } from "../form/fields";
import "./contract.scss";
import { SelectActivity } from "./selectActivity";
import { SelectHired } from "./selectHired";
import { Select as SelectANTD } from "antd";
import HiredForm from "./HiredForm/HiredForm";
import { wpsOptions } from "./utils/wpsOptions";
import { useFetchWpsByType } from "../../hooks/wps/useFetchWpsByType";
import { PaginationProps } from "antd/lib/pagination";
import { columns } from "./utils/columns";

import { createContractData } from "./utils/createContractData";
import { getSchema } from "./utils/getSchema";

type Hired = {
  name: string;
  email: string;
};

function ContractCard({
  afterSubmit,
  onClosePopup,
  onGoBack,
}: {
  afterSubmit?: (name?: string, email?: string) => void;
  onClosePopup?: VoidFunction;
  onGoBack?: VoidFunction;
}) {
  const { activity_id } = useParams();
  const [form] = Form.useForm();
  const isPopup = !!onClosePopup;
  const [loading, setLoading] = useState(false);
  const [newHired, setNewHired] = useState(false);
  const [associateWps, setAssociateWps] = useState(false);
  const [typeWp, setTypeWp] = useState("");
  const [current, setCurrent] = useState(1);
  const onChange: PaginationProps["onChange"] = (page) => {
    setCurrent(page);
  };
  const [selectedHired, setSelectedHired] = useState<Hired>({
    name: "",
    email: "",
  });
  const schema = getSchema(newHired, associateWps);

  const methods = useForm<any>({
    resolver: zodResolver(schema),
    defaultValues: { active: true, activity_id },
  });

  const {
    handleSubmit,
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = methods;

  const values = watch();
  const { wps, total, isFetching } = useFetchWpsByType({ type: typeWp, current });

  const handleCreateContract = (values: any) => {
    const contractData = createContractData(values, isPopup, newHired, associateWps, activity_id);
    api
      .post("/v1/contracts/create", contractData)
      .then((response) => {
        toastAlert({
          type: "success",
          message: `Contrato criado com sucesso. Já é possível acessá-lo através da página de contratos`,
          title: "Contrato criado",
        });
        setLoading(false);
        !!afterSubmit && afterSubmit(selectedHired.name, selectedHired.email);
        !!onClosePopup && onClosePopup();
      })
      .catch((error) => {
        setLoading(false);

        toastAlert({
          type: "error",
          message: `Não foi possível criar contrato. Verifique as informações e tente novamente.`,
          title: "Contrato não foi criado",
        });
      });
  };
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setValue("wps_selected", selectedRowKeys);

      //console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
    },
  };
  return (
    <FormProvider
      methods={methods}
      form={form}
      labelCol={{ xs: 4, lg: isPopup ? 4 : 5, xl: 4, xxl: isPopup ? 4 : 3 }}
      onFinish={handleSubmit(handleCreateContract)}
      style={{
        display: "flex",
        gap: 8,
        flexDirection: "column",
      }}
    >
      <TextArea id="contract-description" name="description" label="Descrição" placeholder="Digite a descrição" />

      {isPopup ? (
        <InputNumber
          id="contract-amount"
          name="amount"
          type="number"
          label="Valor"
          prefix="R$"
          placeholder="Digite o valor"
          style={{ width: "100%" }}
        />
      ) : null}

      {!newHired ? (
        <Select name="hired_id" label="Contratada">
          <SelectHired
            id="contract-hired_id"
            value={values.hired_id}
            setValue={(value: string, data: Hired) => {
              setSelectedHired(data);
              setValue("hired_id", value);
              trigger("hired_id");
            }}
          />
        </Select>
      ) : (
        <HiredForm form={form} setValue={setValue} />
      )}
      <div className="container-newHired-button">
        <Button type="primary" onClick={() => setNewHired(!newHired)}>
          {newHired ? "Usar contratada já existente" : "Criar nova contratada"}
        </Button>
      </div>

      <Divider />
      <div className="container-newHired-button">
        <Button type="primary" onClick={() => setAssociateWps(!associateWps)}>
          {associateWps ? "Associar atividade" : "Associar WPS"}
        </Button>
      </div>
      {isPopup && !associateWps ? (
        <>
          <Select name="activity_id" label="Atividade">
            <SelectActivity
              id="contract-activity_id"
              value={values.activity_id}
              setValue={(value: string) => {
                setValue("activity_id", value);
                trigger("activity_id");
              }}
            />
          </Select>
          <Switch id="contract-active" label="Ativo" name="active" />
        </>
      ) : (
        <>
          <Select name="wp_type" label="Tipo de WP">
            <SelectANTD
              options={wpsOptions}
              onChange={(e) => {
                setValue("wp_type", e);
                setTypeWp(e);
              }}
            />
          </Select>
          {!!wps && (
            <div className="table-pagination">
              <Table
                columns={columns}
                dataSource={wps}
                pagination={false}
                loading={isFetching}
                size="small"
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
              />
              <div className="container-pagination">
                <Pagination current={current} onChange={onChange} total={total} pageSize={30} showSizeChanger={false} />
              </div>
            </div>
          )}
        </>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 8,
            marginTop: 0,
          }}
        >
          {!!onClosePopup || !!onGoBack ? <Button onClick={onClosePopup || onGoBack}>Cancelar</Button> : null}

          <Button id="contract-submit" htmlType="submit" loading={loading} disabled={loading} className="activity_creation-submit" type="primary">
            Criar contrato
          </Button>
        </div>
      </div>
    </FormProvider>
  );
}

export default ContractCard;
