import { useState } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

type Props = {
  refetch: () => void;
};

export const useReproveActivity = ({ refetch }: Props) => {
  const [isReproving, setIsReproving] = useState(false);

  const reproveActivity = async (id: number | string, message: string) => {
    try {
      setIsReproving(true);
      await api.post(`v1/measurement-bulletins-activities/reprove/${id}`, {
        message: message,
      });
      toastAlert({
        type: "success",
        message: "Atividade reprovada com sucesso.",
        title: "Reprovação de Atividade",
      });
    } catch (error: any) {
      toastAlert({
        type: "error",
        message: "Erro ao reprovar a atividade. Tente novamente.",
        title: "Erro na Reprovação",
      });
    } finally {
      setIsReproving(false);
      refetch();
    }
  };

  return { reproveActivity, isReproving };
};
