import { z } from "zod";
import { ContractSchema } from "../../../schemas/contract";
import { ContractHiredSchema } from "../../../schemas/contractHired";
import { ContractHiredWithWpsSchema } from "../../../schemas/ContractHiredWithWpsSchema";
import { ContractWithWpsSchema } from "../../../schemas/ContractWithWpsSchema";

export const getSchema = (isHired: boolean, hasWps: boolean): z.ZodSchema<any> => {
  if (isHired && hasWps) return ContractHiredWithWpsSchema;
  if (isHired) return ContractHiredSchema;
  if (hasWps) return ContractWithWpsSchema;
  return ContractSchema;
};
