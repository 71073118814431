import { Modal } from "antd";
import MeasureCreation from "../MeasureCreation";
import "./cwaPage.scss";

function MeasureModal({
  isModalOpen,
  handleCancel,
}: {
  isModalOpen: boolean;
  handleCancel: () => void;
}) {
  return (
    <Modal
      footer={null}
      closeIcon={true}
      width={600}
      className="cwa-measure-modal"
      open={isModalOpen}
      onCancel={handleCancel}
    >
      <MeasureCreation isPopup />
    </Modal>
  );
}

export default MeasureModal;
