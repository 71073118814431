import { Spin, Table } from "antd";
import { Key, ReactNode, useEffect, useState } from "react";
import { table2Columns, table2HiredColumns, table3Columns, table4HiredColumns, table5Columns, table5HiredColumns } from "../../utils/columns";

export function ExpandedTable2({
  table,
  setExpandedData1,
  setExpandedData2,
  record,
  expandedRowRender,
  canNavigate,
}: {
  table: {
    data: any;
    isLoading: boolean;
    isLoadingId: any;
    total: number;
  };
  setExpandedData1: any;
  setExpandedData2: ({ key }: { key: Key }) => Promise<false | undefined>;
  record: any;
  expandedRowRender: (record: any) => ReactNode;
  canNavigate?: boolean;
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentKeys, setCurrentKeys] = useState<any[]>([]);
  const parentRecordId = record.id;

  useEffect(() => {
    setExpandedData1({ key: record.id, page: currentPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // Table 2
  return (
    <>
      <Table
        columns={canNavigate ? table2Columns : table2HiredColumns}
        dataSource={table.data[record.id] || []}
        expandable={{
          expandedRowRender,
          onExpandedRowsChange: (keys) => {
            if (currentKeys.length < keys.length) {
              setExpandedData2({ key: keys[keys.length - 1] });
            }
            setCurrentKeys([...keys]);
          },
        }}
        onRow={(record, rowIndex) => {
          return {
            className: parentRecordId === table?.isLoadingId ? "ant-spin-blur" : "",
          };
        }}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          pageSize: 30,
          onChange(page, pageSize) {
            setCurrentPage(page);
          },
          total: table.total,
          showSizeChanger: false,
        }}
        locale={emptyText(parentRecordId, table?.isLoadingId)}
      />
    </>
  );
}

export function ExpandedTable3({
  table,
  setExpandedData3,
  record,
  expandedRowRender,
}: {
  table: {
    data: any;
    isLoading: boolean;
    isLoadingId: any;
    total: number;
  };
  setExpandedData3: ({ key }: { key: Key }) => Promise<false | undefined>;
  record: any;
  expandedRowRender: (record: any) => ReactNode;
}) {
  // const [currentPage, setCurrentPage] = useState(1);
  const [currentKeys, setCurrentKeys] = useState<any[]>([]);
  const parentRecordId = record.id;

  //Table 3

  return (
    <Table
      columns={table3Columns}
      dataSource={table.data[record.id]}
      expandable={{
        expandedRowRender,
        onExpandedRowsChange: (keys) => {
          if (currentKeys.length < keys.length) {
            setExpandedData3({ key: keys[keys.length - 1] });
          }
          setCurrentKeys([...keys]);
        },
      }}
      showHeader={false}
      pagination={false}
      onRow={() => {
        return {
          className: parentRecordId === table?.isLoadingId ? "ant-spin-blur" : "",
        };
      }}
      locale={emptyText(parentRecordId, table?.isLoadingId)}
    />
  );
}

export function ExpandedTable4({
  table,
  record,
  expandedRowRender,
  canNavigate,
  setExpandedDataIWP,
}: {
  table: {
    data: any;
    isLoading: boolean;
    isLoadingId: any;
    total: number;
  };
  record: any;
  expandedRowRender: (record: any) => ReactNode;
  canNavigate?: boolean;
  setExpandedDataIWP: ({ key }: { key: Key }) => Promise<false | undefined>;
}) {
  const parentRecordId = record.key;

  //Table 4
  const iwp = Object.keys(table.data)[0];
  const [currentKeys, setCurrentKeys] = useState<any[]>([]);

  return (
    <>
      {iwp?.includes("iwp") ? (
        <>
          <Table
            columns={table4HiredColumns}
            dataSource={table.data[record.key]}
            expandable={{
              expandedRowRender,
              onExpandedRowsChange: (keys) => {
                if (currentKeys.length < keys.length) {
                  setExpandedDataIWP({ key: keys[keys.length - 1] });
                }
                setCurrentKeys([...keys]);
              },
            }}
            pagination={false}
            onRow={() => {
              return {
                className: parentRecordId === table?.isLoadingId ? "ant-spin-blur" : "",
              };
            }}
            locale={emptyText(parentRecordId, table?.isLoadingId)}
          />
        </>
      ) : (
        <Table
          columns={table4HiredColumns}
          dataSource={table.data[record.key]}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              className: parentRecordId === table?.isLoadingId ? "ant-spin-blur" : "",
            };
          }}
          rowClassName="table-row-activity"
          locale={emptyText(parentRecordId, table?.isLoadingId)}
        />
      )}
    </>
  );
}

const emptyText = (parentRecordId: any, loadingDataId: any) => ({
  emptyText: parentRecordId === loadingDataId ? <Spin /> : <>Sem dados</>,
});

export function ExpandedTable5({
  table,

  setExpandedDataIWP,
  record,
  expandedRowRender,
  canNavigate,
}: {
  table: {
    data: any;
    isLoading: boolean;
    isLoadingId: any;
    total: number;
  };
  setExpandedDataIWP: ({ key }: { key: Key }) => Promise<false | undefined>;
  record: any;
  expandedRowRender: (record: any) => ReactNode;
  canNavigate?: boolean;
}) {
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <Table
      columns={table5Columns}
      dataSource={table.data[record.key] || []}
      pagination={{
        position: ["bottomCenter"],
        current: currentPage,
        pageSize: 30,
        onChange(page, pageSize) {
          setCurrentPage(page);
        },
        total: table.total,
        showSizeChanger: false,
      }}
    />
  );
}
