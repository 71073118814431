import { useCallback, useEffect, useState } from "react";
import api from "../../../config/axios";

 
type Cwas = [{
  created_at: string; // ISO Date string
  cwa_code: string;
  deleted_at: string | null;
  description: string;
  gis_boundary: string | null;
  ground_level: string | null;
  id: number;
  plot_plan_drawing: string | null;
  project_information_id: number;
  updated_at: string; // ISO Date string
}];

export const useFetchCwas = () => {
  const [cwas, setCwas] = useState<Cwas[]>([]);
  

  const returnCwas = useCallback(() => {
    api
      .get(`/v1/cwas`)
      .then((response) => {      
        setCwas(response.data);
        })
      .catch((err) => {
        console.error("Erro ao buscar CWAs:", err);
      })
      .finally(() => {

      });
  }, []);

  useEffect(() => {
    returnCwas();
  }, [returnCwas]);

  return { cwas };
};