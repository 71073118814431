import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Collapse, Form } from "antd";
import { useEffect } from "react";
import {
  FieldError,
  FieldErrors,
  FieldValues,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { z } from "zod";
import { useFetchActivityStatus } from "../../hooks/activity/useFetchActivityStatus";
import { useEditWp } from "../../hooks/wps/useEditWp";
import { useFetchDisciplines } from "../../hooks/wps/useFetchDisciplines";
import { SubOwnerWpListSchema, WpListSchema } from "../../schemas/wp";
import { DataType } from "../../types/wp";
import AddOrEditWp from "../AddOrEditWP";
import { FormProvider } from "../form/FormProvider";
import "./WpEdit.scss";

type Props = {
  handleCancel: () => void;
  afterSubmit?: () => void;
  selectedItems?: DataType[];
  setHideMainModal: (newValue: boolean) => void;
  isSubOwner: boolean;
};

export function WpEdit({
  handleCancel,
  afterSubmit,
  selectedItems,
  setHideMainModal,
  isSubOwner,
}: Props) {
  const [form] = Form.useForm();
  const schema = isSubOwner ? SubOwnerWpListSchema : WpListSchema;
  type FormType = z.infer<typeof schema>;

  const methods = useForm<FormType>({
    resolver: zodResolver(schema),
    defaultValues: {
      wps: selectedItems?.map((item) => ({
        id: item.id,
        wpCode: item.name,
        alternativeName: item.alternative_name,
        discipline: item.discipline_id,
        subDiscipline: item.sub_discipline_id,
        description: item.description || "",
        status: item.status_id,
        model: item.model || [],
        modelsToCreate: [],
        modelsToDelete: [],
        activities: item.activities,
        activitiesToCreate: [],
        activitiesToDelete: [],
        justification: item.justification,
        newActivitiesToCreate: [],
      })),
    },
  });
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = methods;
  const values = watch();

  const { fields } = useFieldArray({
    control,
    name: "wps",
  });

  const { disciplines } = useFetchDisciplines();
  const { status } = useFetchActivityStatus();

  const { editWp, isFetching, status: httpstatus } = useEditWp();

  const handleEditWp = (values: any) => {
    editWp({ ...values });
  };

  useEffect(() => {
    if (httpstatus === 200) {
      handleCancel();
      afterSubmit && afterSubmit();
    }
  }, [httpstatus]);

  const wpSetValue = (name: string, value: any, index: number) => {
    const formName: any = `wps.${index}.` + name;
    setValue(formName, value);
    trigger(formName);
  };
  const formHelp = (value: any) => "Atual: " + (!!value ? value : "-");

  return (
    <FormProvider
      methods={methods}
      form={form}
      labelCol={{ xs: 5, md: 8 }}
      className="form-edit-container-WP"
      onFinish={handleSubmit(handleEditWp)}
    >
      {!!selectedItems ? (
        selectedItems?.length > 1 ? (
          <Collapse
            className="collapse-list"
            defaultActiveKey={["0"]}
            items={fields?.map((item: any, index) => ({
              key: item.key,
              label: selectedItems ? selectedItems[index].name : "",
              children: (
                <AddOrEditWp
                  type="edit"
                  editData={selectedItems ? selectedItems[index] : undefined}
                  disciplineList={disciplines}
                  statusList={status}
                  index={index}
                  formData={{
                    values: values.wps[index],
                    errors: errors?.wps?.[
                      index.toString() as keyof FieldError
                    ] as FieldErrors<FieldValues> | undefined,
                    setValue: (name: string, value: any) =>
                      wpSetValue(name, value, index),
                    name: `wps.${index}.`,
                    help: formHelp,
                  }}
                  setHideMainModal={setHideMainModal}
                />
              ),
            }))}
          />
        ) : (
          <AddOrEditWp
            type="edit"
            editData={selectedItems ? selectedItems[0] : undefined}
            disciplineList={disciplines}
            statusList={status}
            index={0}
            formData={{
              values: values.wps[0],
              errors: errors?.wps?.["0"] as
                | FieldErrors<FieldValues>
                | undefined,
              setValue: (name: string, value: any) =>
                wpSetValue(name, value, 0),
              name: `wps.0.`,
              help: formHelp,
            }}
            setHideMainModal={setHideMainModal}
          />
        )
      ) : null}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 8,
            marginTop: 0,
          }}
        >
          <Button onClick={handleCancel}>Cancelar</Button>
          <Button
            htmlType="submit"
            loading={isFetching}
            disabled={isFetching}
            type="primary"
            id="save-data"
          >
            Salvar dados
          </Button>
        </div>
      </div>
    </FormProvider>
  );
}
