import { useCallback, useEffect, useState } from "react";
import api from "../../config/axios";

type Props = {
  activityId?: string | number;
  refresh: null | Date;
  loadingInitialValue?: boolean;
};

export const useFetchActivityValidations = ({ activityId, refresh, loadingInitialValue }: Props) => {
  const [activityValidations, setActivityValidations] = useState<any>();
  const [isFetchingActivityValidations, setIsFetchingActivityValidations] = useState(!!loadingInitialValue);
  const [error, setError] = useState<Error | null>(null);

  const fetchActivityValidations = useCallback(() => {
    if (activityId) {
      setIsFetchingActivityValidations(true);
      api
        .get(`v1/validation-evidence/activity-validations/${activityId}/`)
        .then((response) => {
          if (response.status === 204) {
            setActivityValidations([]);
          } else {
            setActivityValidations(response.data); // Dados retornados
          }
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setIsFetchingActivityValidations(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityId, refresh]);

  useEffect(() => {
    fetchActivityValidations();
  }, [fetchActivityValidations]);

  return {
    activityValidations,
    isFetchingActivityValidations,
    error,
  };
};
