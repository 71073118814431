import "./validationsPage.scss";
import { useState } from "react";
import { Button, Col, Row, Table, Typography } from "antd";
import Layout from "../../components/Template/Layout";
import CollapseCard from "../../components/Template/Card";
import { useFetchValidationEvidences } from "../../hooks/validations/useFetchValidationEvidences";
import ModalCreateValidation from "./components/ModalCreateValidation";
import { validationEvidenceColumns } from "./constants/columns";
const { Title } = Typography;

export default function ValidationsPage() {
  const [current, setCurrent] = useState(1);
  const [dataTable, setDataTable] = useState([]);
  const [refresh, setRefresh] = useState<Date>();
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(!isOpen);
    setRefresh(new Date());
  };

  const { isFetching, total } = useFetchValidationEvidences({
    current,
    setTables: setDataTable,
    refresh: refresh,
  });

  return (
    <Layout pageTitle="Tipos de evidencias" breadcrumb="Home ⟶ Tipos de evidencias">
      <Row gutter={16} className="page-row">
        <Col xs={24} md={6} className="mb-2">
          <CollapseCard title="Total">
            <Title id="activities-total" level={2} className="total-card">
              {total}
            </Title>
          </CollapseCard>
        </Col>
        <Col xs={24} md={18} className="mb-2">
          <CollapseCard title="Ações">
            <Col span={24} className="mb-2 activity-filters">
              <div className="actions-container">
                <Button
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  Criar tipo de evidência
                </Button>
              </div>
            </Col>
          </CollapseCard>
        </Col>
        <Col xs={24} md={24} className="mb-2">
          <CollapseCard title="Tabela de tipos de evidências">
            <Row>
              <Col span={24}>
                <Table
                  size="small"
                  dataSource={dataTable}
                  columns={validationEvidenceColumns}
                  loading={isFetching}
                  pagination={{
                    position: ["bottomCenter"],
                    current,
                    pageSize: 30,
                    onChange(page) {
                      setCurrent(page);
                    },
                    total,
                    showSizeChanger: false,
                  }}
                  locale={{ emptyText: "Sem dados" }}
                />
              </Col>
            </Row>
          </CollapseCard>
        </Col>
      </Row>
      <ModalCreateValidation isOpen={isOpen} handleClose={handleClose} />
    </Layout>
  );
}
