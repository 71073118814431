import { useCallback, useEffect, useState } from "react";
import api from "../../config/axios";
import { SubDiscipline } from "../../models/SubDicipline";

type Props = {
  discipline_id: number;
  refresh: null | Date;
};

export const useFetchSubDisciplines = ({ discipline_id, refresh }: Props) => {
  const [subDisciplines, setSubDisciplines] = useState<SubDiscipline[]>([]);
  const [isFetchingSub, setIsFetching] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const returnSubDisciplines = useCallback(() => {
    if (discipline_id > 0) {
      setIsFetching(true);
      api
        .get(`/v1/wps/sub-disciplines/${discipline_id}`)
        .then((response) => {
          setSubDisciplines(response.data);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setIsFetching(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discipline_id, refresh]);

  useEffect(() => {
    returnSubDisciplines();
  }, [returnSubDisciplines]);

  return {
    subDisciplines,
    isFetchingSub,
    error,
  };
};
