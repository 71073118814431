import { useCallback } from "react";
import { MeasurementPartial } from "../../../models/MeasurementPartial";

const useMaxPercentage = () => {
  return useCallback((data: MeasurementPartial[]): number => {
    const filteredMeasurements = data.filter((item) => item.status_id === 4).map((item) => parseFloat(item.percentage));

    if (filteredMeasurements.length === 0) {
      return 0;
    }

    const maxPercentage = Math.max(...filteredMeasurements);

    return maxPercentage;
  }, []);
};

export default useMaxPercentage;
