import {
  Button,
  Col,
  Grid,
  Pagination,
  PaginationProps,
  Row,
  Table,
  Typography,
} from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import CollapseCard from "../../components/Template/Card";
import Layout from "../../components/Template/Layout";
import { columns, mobileColumns } from "./utils/columns";

import ModalCreateEmployeeOrHired from "../../components/ModalCreateEmployeeOrHired";
import { useFetchEmployees } from "../../hooks/hiredOrEmployee/useFetchEmployees";
import { toastAlert } from "../../utils/toastAlert";

const { Title } = Typography;
const { useBreakpoint } = Grid;

type ModalDataType = {
  isOpen: boolean;
  type: "create" | "edit";
  id: number | undefined;
};

function EmployeePage() {
  const { sm } = useBreakpoint();
  const role = localStorage.getItem("user_role");
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(new Date());
  const [modalData, setModalData] = useState<ModalDataType>({
    isOpen: false,
    type: "create",
    id: undefined,
  });
  const { hired_id } = useParams();

  // chamada api
  const { total, employees, isFetching } = useFetchEmployees({
    hired_id,
    page,
    refresh,
  });

  const onChange: PaginationProps["onChange"] = (page) => {
    setPage(page);
  };

  const handleOpenModal = ({
    id,
    type,
  }: {
    id?: number;
    type: "create" | "edit";
  }) => {
    setModalData({ isOpen: true, type, id });
  };

  return (
    <>
      <Layout
        pageTitle="Lista de colaboradores da contratada"
        breadcrumb="Home ⟶ Contratadas ⟶ Colaboradores"
        actions={
          <>
            {!(role || "").includes("Subowner") && (
              <Button
                className="button-secondary"
                onClick={() => handleOpenModal({ type: "create" })}
                id="create-employee"
              >
                Criar colaborador
              </Button>
            )}
          </>
        }
      >
        <Row gutter={16} className="page-row">
          <Col xs={24} md={6} className="mb-2">
            <CollapseCard title="Total">
              {!!total ? (
                <Title level={2} className="total-card">
                  {total}
                </Title>
              ) : null}
            </CollapseCard>
          </Col>
          <Col xs={24} md={18} className="mb-2">
            <CollapseCard
              title="Tabela de Colaboradores"
              className="card-table-no-padding"
            >
              <Row>
                <Col span={24} style={{ overflow: "auto" }}>
                  <Table
                    className="table-projects"
                    columns={!sm ? mobileColumns : columns}
                    dataSource={employees}
                    pagination={false}
                    loading={isFetching}
                    size="small"
                    locale={{ emptyText: "Sem dados" }}
                    style={{ minWidth: "600px" }}
                  />
                </Col>

                <Col
                  span={24}
                  className="text-center mt-4"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "5px",
                  }}
                >
                  <Pagination
                    current={page}
                    onChange={onChange}
                    total={total}
                    pageSize={30}
                    showSizeChanger={false}
                  />
                </Col>
              </Row>
            </CollapseCard>
          </Col>
        </Row>
        {modalData.isOpen ? (
          <ModalCreateEmployeeOrHired
            isModalOpen={modalData.isOpen}
            handleCancel={() =>
              setModalData((prevState) => ({ ...prevState, isOpen: false }))
            }
            afterSubmit={() => {
              setRefresh(new Date());
              toastAlert({
                type: "success",
                message:
                  "Acesse a página de colaboradores da contratada para ver todos.",
                title: "Colaborador criado",
              });
            }}
            type="employees"
            hired_id={hired_id}
          />
        ) : null}
      </Layout>
    </>
  );
}

export default EmployeePage;
