import { useCallback, useEffect, useState } from "react";
import api from "../../config/axios";

type Props = {
  activity_id?: string | number;
  setFileList: (data: any) => void;
  refreshFileList?: Date;
};

export const useFetchActivityFiles = ({ activity_id, setFileList, refreshFileList }: Props) => {
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);

  const returnWp = useCallback(async () => {
    if (activity_id) {
      setIsLoadingFiles(true);
      await api
        .get(`/v1/files/fetchFilesByActivityId/${activity_id}`)
        .then(async (response) => {
          setFileList(response?.data);
        })
        .finally(async () => {
          setIsLoadingFiles(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity_id, refreshFileList]);

  useEffect(() => {
    returnWp();
  }, [returnWp]);

  return { isLoadingFiles };
};
