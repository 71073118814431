import { useState } from "react";
import { phoneMask } from "../../../utils/masks";
import { Input, TextArea } from "../../form/fields";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

type Props = {
  form: any;
  setValue: any;
};

export default function HiredForm({ form, setValue }: Props) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <Input name="name" label="Nome" placeholder="Digite o nome" />

      <Input name="email" type="email" label="E-mail" placeholder="Digite o e-mail" />

      <Input
        name="phone"
        type="phone"
        label="Telefone"
        placeholder="Digite o telefone"
        onChange={(event) => {
          setValue("phone", phoneMask(event.target.value || ""));
          form.setFieldValue("phone", phoneMask(event.target.value));
        }}
      />

      <TextArea name="hired_description" label="Descrição" placeholder="Digite a descrição" />

      <Input
        name="password"
        type={showPassword ? "text" : "password"}
        label="Senha"
        placeholder="Digite a senha"
        suffix={
          <div onClick={() => setShowPassword(!showPassword)} style={{ cursor: "pointer" }}>
            {showPassword ? <EyeInvisibleOutlined style={{ paddingRight: 8 }} /> : <EyeOutlined style={{ paddingRight: 8 }} />}
          </div>
        }
      />
    </>
  );
}
