import { TableColumnsType } from "antd";
import { statusList } from "../../../constants/status";

export const columns: TableColumnsType<any> = [
  {
    title: "Atividades",
    dataIndex: "atividades",
    key: "atividades",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (name, record) => (
      <div>
        {statusList[statusList.findIndex((item) => item.value === name)]?.label}
      </div>
    ),
  },
  {
    title: "Responsável",
    dataIndex: "responsavel",
    key: "responsavel",
  },
  { title: "", dataIndex: "actions", width: 110, key: "actions" },
];
