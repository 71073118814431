import { Tag } from "antd";

export default function returnStatusActivity(status: string) {
  if (status === "Completo") {
    return (
      <Tag color={"green"} key={status}>
        Completo
      </Tag>
    );
  } else if (status === "Em andamento" || status === "Pendente") {
    return (
      <Tag color={"geekblue"} key={status}>
        Em andamento
      </Tag>
    );
  } else if (status === "Reprovado") {
    return (
      <Tag color={"volcano"} key={status}>
        Recusado
      </Tag>
    );
  } else {
    return (
      <Tag color={"purple"} key={status}>
        Não iniciado
      </Tag>
    );
  }
}
