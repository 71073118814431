import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Col, Form, Image, Row, Typography, message } from "antd";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo-fluit.png";
import { FormProvider } from "../../components/form/FormProvider";
import { Input } from "../../components/form/fields";
import {
  ResetPasswordFormType,
  ResetPasswordSchema,
} from "../../schemas/resetPassword";
import api from "../../services/Api";
import "./resetPassword.scss";

const { Text } = Typography;

export default function ForgotPassword() {
  const [form] = Form.useForm();
  const methods = useForm<ResetPasswordFormType>({
    resolver: zodResolver(ResetPasswordSchema),
  });

  const { handleSubmit } = methods;

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const token = location.search.replace("?token=", "");

  const handleFormSubmit = (values: ResetPasswordFormType) => {
    setIsLoading(true);

    api
      .post(
        "/v1/reset-password", //ver como ler do .env
        {
          email: values.email,
          password: values.password,
          confirmPassword: values.confirmPassword,
          token: token,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          message.success(response.data);
          navigate("/login");
        }
      })
      .catch((error) => {
        if (error.response && error.response?.status === 401) {
          message.error(
            error.response?.data ||
              "Erro ao enviar e-mail, tente novamente mais tarde",
            5
          );
        } else {
          message.error(
            error.response?.data?.message ||
              "Ocorreu um erro ao processar a solicitação, tente novamente mais tarde."
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Row
      justify="space-around"
      align="middle"
      className="rowLogin login-background"
    >
      <Col sm={12} md={8} className="loginBox">
        <FormProvider
          methods={methods}
          form={form}
          onFinish={handleSubmit(handleFormSubmit)}
        >
          <Col span={24}>
            <Image src={logo} preview={false} className="logo mb-4" />
            <>
              <Col span={24} className="mb-2">
                <Input
                  placeholder="E-mail"
                  type="email"
                  name="email"
                  helpClassName="input-help"
                />
              </Col>

              <Col span={24} className="mb-2">
                <Input
                  size="small"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Senha"
                  helpClassName="input-help"
                  suffix={
                    <div
                      onClick={() => setShowPassword(!showPassword)}
                      style={{ cursor: "pointer" }}
                    >
                      {showPassword ? (
                        <EyeInvisibleOutlined style={{ paddingRight: 8 }} />
                      ) : (
                        <EyeOutlined style={{ paddingRight: 8 }} />
                      )}
                    </div>
                  }
                  style={{ maxWidth: 250 }}
                />
              </Col>
              <Col span={24}>
                <Input
                  size="small"
                  name="confirmPassword"
                  type={showPassword ? "text" : "password"}
                  placeholder="Confirme a senha"
                  helpClassName="input-help"
                  suffix={
                    <div
                      onClick={() => setShowPassword(!showPassword)}
                      style={{ cursor: "pointer" }}
                    >
                      {showPassword ? (
                        <EyeInvisibleOutlined style={{ paddingRight: 8 }} />
                      ) : (
                        <EyeOutlined style={{ paddingRight: 8 }} />
                      )}
                    </div>
                  }
                  style={{ maxWidth: 250 }}
                />
              </Col>

              <Col span={24} className="mt-2">
                <Button
                  className="submit pt-1 pb-1"
                  htmlType="submit"
                  loading={isLoading}
                >
                  Mudar senha
                </Button>
              </Col>

              <Col span={24} className="mt-1">
                <Text
                  onClick={() => navigate("/login")}
                  className="forgotPassword"
                >
                  Voltar para o login
                </Text>
              </Col>
            </>
          </Col>
        </FormProvider>
      </Col>
    </Row>
  );
}
