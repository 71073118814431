import { TableColumnsType } from "antd";
import { formatCurrency } from "../../../utils/formatCurrency";
import returnStatusEvidence from "../../../utils/returnStatusEvidence";
interface DataType {
  id: number;
  key: React.Key;
  content: string;
  children?: DataType[];
}

export const columns: TableColumnsType<DataType> = [
  { title: "Código", dataIndex: "code", key: "code" },
  { title: "Descrição", dataIndex: "name", key: "name" },
  { title: "Tipo", dataIndex: "type", key: "type" },
  {
    title: "Responsável",
    dataIndex: "responsible_name",
    key: "responsible_name",
  },

  { title: "", dataIndex: "actions", key: "actions", width: "120px" },
];
