import { useEffect, useState } from "react";
import api from "../../services/Api";
import SelectInputSearch from "../SelectInputSearch";
import "./contract.scss";

let getHiredSearch: AbortController;

type Hired = {
  name: string;
  email: string;
  id?: string;
};

export function SelectHired({
  value,
  setValue,
  id = "select",
}: {
  value: any;
  setValue: (value: string, data: Hired) => void;
  id?: string;
}) {
  const [search, setSearch] = useState("");
  const [loadingHireds, setLoadingHireds] = useState(true);
  const [hiredList, setHiredList] = useState<any[] | undefined>();

  useEffect(() => {
    getHiredsData();
  }, []);

  const handleSearch = () => {
    setLoadingHireds(true);

    if (getHiredSearch) getHiredSearch.abort();
    getHiredSearch = new AbortController();

    if (search !== "") {
      api
        .post(
          "/v1/hireds/search",
          { search },
          { signal: getHiredSearch?.signal }
        )
        .then((r) => {
          if (r.status === 200) {
            setHiredList(
              r.data.map((hired: any) => ({
                value: hired.id,
                label: hired.name,
                email: hired.email,
              }))
            );
          }
        })
        .finally(() => {
          setLoadingHireds(false);
        })
        .catch(() => {});
    } else {
      getHiredsData();
    }
  };

  async function getHiredsData() {
    setLoadingHireds(true);

    await api
      .get(`/v1/hireds/all`)
      .then((response) => {
        if (response.status === 200) {
          setHiredList(
            response.data.data.map((hired: any) => ({
              value: hired.id,
              label: hired.name,
              email: hired.email,
            }))
          );
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoadingHireds(false);
      });
  }

  const onChange = (value: any) => {
    const selectedHired = hiredList?.filter((item) => item.value === value)[0];
    setValue(value, {
      name: selectedHired?.label,
      email: selectedHired?.email,
    });
  };

  return (
    <SelectInputSearch
      id={id}
      placeholder="Selecione um contratado"
      onChange={onChange}
      value={value}
      onSearch={(value: string) => setSearch(value)}
      search={search}
      options={hiredList || []}
      getData={handleSearch}
      loading={loadingHireds}
    />
  );
}
