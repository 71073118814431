import { ColProps, Form, FormInstance } from "antd";
import { KeyboardEvent, ReactNode } from "react";
import { FormProvider as RHFForm, UseFormReturn } from "react-hook-form";

type Props = {
  children: ReactNode;
  methods: UseFormReturn<any>;
  onFinish?: (values: any) => void;
  labelCol?: ColProps | undefined;
  form: FormInstance<any> | undefined;
  style?: any;
  className?: string;
  submitOnEnter?: boolean;
};

export function FormProvider({
  children,
  onFinish,
  methods,
  labelCol,
  form,
  style,
  className,
  submitOnEnter,
}: Props) {
  const checkKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.code === "Enter" && !submitOnEnter) e.preventDefault();
  };

  return (
    <RHFForm {...methods}>
      <Form
        form={form}
        labelCol={labelCol}
        onFinish={onFinish}
        className={className}
        style={style}
        onKeyDown={(e) => checkKeyDown(e)}
      >
        {children}
      </Form>
    </RHFForm>
  );
}
