import { useCallback, useEffect, useState } from "react";
import api from "../../config/axios";
import { Discipline } from "../../models/Discipline";

export const useFetchActivityStatus = () => {
  const [status, setStatus] = useState<Discipline[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const returnStatus = useCallback(() => {
    setIsFetching(true);
    api
      .get(`/v1/activity/all-status`)
      .then((response) => {
        setStatus(response.data);
      })
      .catch((err) => {
        //setError(err);
      })
      .finally(() => {
        setIsFetching(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    returnStatus();
  }, [returnStatus]);

  return {
    status,
    isFetching,
  };
};
