import { Button, Col, Grid, Pagination, PaginationProps, Row, Table, TableColumnsType, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../components/SearchInput";
import CollapseCard from "../../components/Template/Card";
import Layout from "../../components/Template/Layout";
import api from "../../services/Api";
import "./hiredPage.scss";

import ModalCreateEmployeeOrHired from "../../components/ModalCreateEmployeeOrHired";
import { toastAlert } from "../../utils/toastAlert";

import EditHired from "./components/EditHired/Edithired";

const { Title } = Typography;
const { useBreakpoint } = Grid;

interface DataType {
  key: React.Key;
  content: string;
  children?: DataType[];
}

type ModalDataType = {
  isOpen: boolean;
  type: "create" | "edit";
  id: number | undefined;
};

function HiredPage() {
  const { sm } = useBreakpoint();
  const role = localStorage.getItem("user_role");
  const [isLoading, setIsLoading] = useState(true);

  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [dataTable, setDataTable] = useState();
  // const [pageSize, setPageSize] = useState(0);
  const [refreshTableData, setRefreshTableData] = useState(new Date());
  const [modalData, setModalData] = useState<ModalDataType>({
    isOpen: false,
    type: "create",
    id: undefined,
  });
  const navigate = useNavigate();

  const onChange: PaginationProps["onChange"] = (page) => {
    setCurrent(page);
  };
  async function getData() {
    setIsLoading(true);

    await api
      .get(`/v1/hireds/all?page=${current}`)
      .then((response) => {
        const data = response.data.data;
        const table = data.map((obj: any) => ({
          ...obj,
          key: obj.id,

          actions: (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "12px" }}>
              <Button type="primary" onClick={() => navigate("/hireds/" + obj.id)} id="open-hired">
                Abrir
              </Button>
              <EditHired hired={obj} setRefresh={() => setRefreshTableData(new Date())} />
            </div>
          ),
        }));

        setDataTable(table);
        setTotal(response.data.total);
        // setPageSize(data.per_page);
      })
      .finally(() => setIsLoading(false));
  }
  // search
  const [search, setSearch] = useState("");
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const handleSearch = (search: string) => {
    setIsLoading(true);

    if (search !== "") {
      api
        .post("/v1/hireds/search", {
          search: search,
        })
        .then((r) => {
          const data = r.data;
          const table = data.map((obj: any) => ({
            ...obj,
            key: obj.id,
            actions: (
              <>
                <Button type="primary" onClick={() => navigate("/hireds/" + obj.id)} id="open-hired">
                  Abrir
                </Button>
              </>
            ),
          }));

          setDataTable(table);
          setTotal(table.length);
          // setPageSize(table.length);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      getData();
    }
  };

  // end of search
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, refreshTableData]);

  const columns: TableColumnsType<DataType> = [
    { title: "Nome", dataIndex: "name", key: "name" },
    { title: "Descrição", dataIndex: "description", key: "description" },
    { title: "E-mail", dataIndex: "email", key: "email" },
    { title: "", dataIndex: "actions", key: "actions" },
  ];

  const mobileColumns: TableColumnsType<DataType> = [
    { title: "Nome", dataIndex: "name", key: "name" },
    { title: "E-mail", dataIndex: "email", key: "email" },
    { title: "", dataIndex: "actions", key: "actions" },
  ];

  const handleOpenModal = ({ id, type }: { id?: number; type: "create" | "edit" }) => {
    setModalData({ isOpen: true, type, id });
  };

  return (
    <>
      <Layout
        pageTitle="Lista de Contratadas"
        breadcrumb="Home ⟶ Contratadas"
        actions={
          <>
            {!(role || "").includes("Subowner") && (
              <Button className="button-secondary" onClick={() => handleOpenModal({ type: "create" })} id="create_hired">
                Criar Contratada
              </Button>
            )}
          </>
        }
      >
        <Row gutter={16} className="page-row">
          <Col xs={24} md={6} className="mb-2">
            <CollapseCard title="Total">
              {!!total ? (
                <Title level={2} className="total-card">
                  {total}
                </Title>
              ) : null}
            </CollapseCard>
          </Col>
          <Col xs={24} md={18} className="mb-2">
            <CollapseCard title="Tabela de Contratadas" className="card-table-no-padding">
              <Row>
                <Col span={24} className="mb-2 text-right">
                  <SearchInput
                    type="text"
                    handleChange={handleChangeSearch}
                    value={search}
                    handleSearch={handleSearch}
                    loading={isLoading}
                    placeholder="Busque por nome"
                  />
                </Col>
                <Col span={24} style={{ overflow: "auto" }}>
                  <Table
                    className="table-projects"
                    columns={!sm ? mobileColumns : columns}
                    dataSource={dataTable}
                    pagination={false}
                    loading={isLoading}
                    size="small"
                    locale={{ emptyText: "Sem dados" }}
                    style={{ minWidth: "600px" }}
                  />
                </Col>

                <Col
                  span={24}
                  className="text-center mt-4"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "5px",
                  }}
                >
                  <Pagination current={current} onChange={onChange} total={total} pageSize={30} showSizeChanger={false} />
                </Col>
              </Row>
            </CollapseCard>
          </Col>
        </Row>
        {modalData.isOpen ? (
          <ModalCreateEmployeeOrHired
            type="hireds"
            isModalOpen={modalData.isOpen}
            handleCancel={() => setModalData((prevState) => ({ ...prevState, isOpen: false }))}
            afterSubmit={() => {
              setRefreshTableData(new Date());
              toastAlert({
                type: "success",
                message: `Nova contratada já pode ser vista na tabela.`,
                title: "Criada com sucesso",
              });
            }}
          />
        ) : null}
      </Layout>
    </>
  );
}

export default HiredPage;
