import { Modal } from "antd";
import { CreatedActivity } from "../../schemas/activity";
import { WpCreateActivity } from "../WpCreateActivity";
import "./modalCreateWpActivity-styles.scss";

type Props = {
  isModalOpen: boolean;
  handleCancel: () => void;
  handleSubmit: (formData: CreatedActivity) => void;
  wpFormData: {
    id: number | undefined;
    subDiscipline: number | undefined;
    projectInformationId: string;
  };
};

export default function ModalCreateWpActivity({
  isModalOpen,
  handleCancel,
  handleSubmit,
  wpFormData,
}: Props) {
  return (
    <Modal
      title={`Criar atividade`}
      closeIcon={true}
      width={600}
      footer={null}
      open={isModalOpen}
      onCancel={handleCancel}
      rootClassName="wp-create-activity-modal"
    >
      <WpCreateActivity
        handleCancel={handleCancel}
        wpFormData={wpFormData}
        handleSubmit={handleSubmit}
      />
    </Modal>
  );
}
