import { Col, Row } from "antd";
import Layout from "../../components/Template/Layout";
import { PasswordCard } from "./components/PasswordCard";
import { UserDataCard } from "./components/UserDataCard";
import "./userProfile.scss";

function UserProfile() {
  return (
    <>
      <Layout pageTitle="Perfil" breadcrumb="Perfil">
        <Row gutter={16} className="page-row">
          <Col xs={24} lg={12} xxl={8} className="mb-2">
            <UserDataCard />
          </Col>

          <Col xs={24} lg={12} xxl={8} className="mb-2">
            <PasswordCard />
          </Col>
        </Row>
      </Layout>
    </>
  );
}

export default UserProfile;
