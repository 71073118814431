import { Table, Tabs } from "antd";
import "../measurePage.scss";

import { columns } from "../utils/columns";
import { evidenceTabs } from "../utils/tabs";
import { evidenceColumns } from "./constants/evidenceColumns";

function EvidenceTabs({
  activeKey,
  validacaoConts,
  onChange,
  dataSource,
  loading,
  current,
  setCurrent,
  total,
}: {
  activeKey: string | undefined;
  onChange: ((activeKey: string) => void) | undefined;
  dataSource: any[] | undefined;
  validacaoConts: Record<string, number>; 
  loading: boolean;
  current: number;
  setCurrent: any;
  total: number;
}) {
  return (
    <>
      <Tabs defaultActiveKey="1" type="card" size="small" items={evidenceTabs(validacaoConts)} activeKey={activeKey} onChange={onChange} className="fluit-tabs" />
      <Table
        size="small"
        dataSource={dataSource}
        columns={evidenceColumns}
        loading={loading}
        scroll={{
          x: "800px",
        }}
        pagination={{
          position: ["bottomCenter"],
          current,
          pageSize: 30,
          onChange(page, pageSize) {
            setCurrent(page);
          },
          total,
          showSizeChanger: false,
        }}
      />
    </>
  );
}

export default EvidenceTabs;
