import { Button, Input, Modal } from "antd";
import { useState } from "react";
import DatePickerComponent from "../../../components/DatePicker/DatePicker";
import { Project } from "../../../models/Project";
import { Contract } from "../../../models/Contract";
import ReportSelect from "../../ReportPage/components/ReportSelect";
import "./modalCreateBm-styles.scss";
const { TextArea } = Input;

type CreateBmProps = {
  formData: {
    date: Date[];
    observation: string;
    name: string;
  };
  handleClose: VoidFunction;
};

type Props = {
  createBm: ({ formData, handleClose }: CreateBmProps) => Promise<void>;
  isModalOpen: boolean;
  handleClose: VoidFunction;
  projects: Project[];
  contracts: Contract[];
};

export default function ModalCreateBm({ isModalOpen, handleClose, createBm, contracts, projects }: Props) {
  const [observation, setObservation] = useState("");
  const [name, setName] = useState("");
  const [contract, setContract] = useState();
  const [project, setProject] = useState();
  const [date, setDate] = useState<Date[]>([]);
  const formData = {
    date: date,
    observation: observation,
    name: name,
    project: project,
    contract: contract,
  };
  return (
    <Modal
      title="Criar Boletim de medição"
      open={isModalOpen}
      width={560}
      onCancel={() => handleClose()}
      footer={[
        <Button key="back" onClick={() => handleClose()}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          danger
          onClick={() => {
            createBm({ formData, handleClose });
          }}
        >
          Gerar Bm
        </Button>,
      ]}
    >
      <DatePickerComponent setValue={setDate} label={"Selecione o período"} />
      <Input
        placeholder="Nome"
        style={{ marginTop: 8 }}
        onChange={(event) => {
          setName(event.target.value);
        }}
      />
      <div className="container-filter-modal">
        <ReportSelect
          values={contracts?.map((item) => {
            return {
              label: item.description,
              value: item.id,
            };
          })}
          label="Contrato"
          handleChange={(e: any) => setContract(e)}
          showAll={false}
        />
        <ReportSelect
          values={projects?.map((item) => {
            return {
              label: item.description,
              value: item.id,
            };
          })}
          label="Projeto"
          handleChange={(e: any) => setProject(e)}
          showAll={false}
        />
      </div>
      <TextArea
        placeholder="Observação"
        rows={3}
        onChange={(event) => {
          setObservation(event.target.value);
        }}
        style={{ marginTop: 8 }}
      />
    </Modal>
  );
}
