import { Button, Table, TableColumnsType } from "antd";
import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import "./previousActivities.scss";

export interface DataType {
  key: React.Key;
  name: string;
}

export function ExpandedPreviousActivity({
  id,
  currentActivity,
}: {
  id: string | number;
  currentActivity: any;
}) {
  const navigate = useNavigate();

  const columns: TableColumnsType<DataType> = [
    { title: "Nome", dataIndex: "name", key: "name" },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (data) => <div className="align-right">{data}</div>,
    },
  ];

  const expandedRowRender = (data: any): ReactNode => {
    return (
      <ExpandedPreviousActivity
        id={data.id}
        currentActivity={currentActivity.parent_activity}
      />
    );
  };

  // Table 2
  return (
    <Table
      size="small"
      columns={columns}
      showHeader={false}
      dataSource={[
        {
          ...currentActivity,
          actions: (
            <>
              <Button
                type="primary"
                onClick={() => navigate("/activity/" + currentActivity.id)}
              >
                Ir para atividade
              </Button>
            </>
          ),
        },
      ]}
      expandable={
        !!currentActivity?.parent_activity ? { expandedRowRender } : undefined
      }
      pagination={false}
    />
  );
}
