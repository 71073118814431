import { Button, Drawer, Grid, Transfer } from "antd";
import { TransferDirection } from "antd/es/transfer";
import { useState } from "react";
import { useAddManyActivities } from "../../hooks/contracts/useAddManyActivities";
import "./hired.scss";

const { useBreakpoint } = Grid;

function HiredActivitiesModal({
  hired,
  allActivities,
  isModalOpen,
  handleCancel,
  handleConfirm,
  afterSubmit,
}: {
  hired: any;
  allActivities: any[];
  isModalOpen: boolean;
  handleCancel: VoidFunction;
  handleConfirm: VoidFunction;
  afterSubmit?: VoidFunction;
}) {
  const { xxl } = useBreakpoint();
  const [hiredActivities, setHiredActivities] = useState<string[]>([]);
  // const [loading, setLoading] = useState<boolean>(true);

  // const [dataSource, setDataSource] = useState(
  const dataSource = allActivities.map((activity) => ({
    key: `${activity.value}`,
    title: activity.label,
    description: activity.label,
  }));

  const onChange = (
    newTargetKeys: string[],
    direction: TransferDirection,
    moveKeys: string[]
  ) => {
    setHiredActivities(newTargetKeys);
  };

  const { handleAddActivities, isSubmitting } = useAddManyActivities();

  return (
    <Drawer
      title="Atribuir Atividades"
      width="100vw"
      open={isModalOpen}
      onClose={handleCancel}
      closable={false}
      footer={
        <div className="activities-modal-footer">
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>

          <Button
            key="submit"
            type="primary"
            disabled={isSubmitting}
            onClick={() =>
              handleAddActivities({
                hired,
                hiredActivities,
                handleConfirm,
                errorMessage:
                  "Selecione as atividades e clique na seta primeiro",
              })
            }
          >
            Adicionar atividades
          </Button>
        </div>
      }
    >
      {/* {loading || hiredActivities ? (
        <Spin />
      ) : ( */}
      <Transfer
        dataSource={dataSource}
        targetKeys={hiredActivities}
        onChange={onChange}
        showSearch
        titles={["Todas as Atividades", "Atribuídas a contratada"]}
        render={(item) => item.title}
        oneWay
        style={{
          height: "100%",
        }}
        listStyle={{
          height: "100%",
          width: xxl ? "48%" : "47%",
        }}
      />
      {/* )} */}
    </Drawer>
  );
}

export default HiredActivitiesModal;
