import { z } from "zod";

export const ContractHiredSchema = z.object({
  active: z.boolean(),
  activity_id: z.string(),
  amount: z.string(),
  description: z.string(),
  email: z.string().email(),
  hired_description: z.string(),
  name: z.string(),
  password: z.string(),
  phone: z.string(),
});

export type ContractFormType = z.infer<typeof ContractHiredSchema>;
