import { Upload, UploadFile } from "antd";

import { useEffect, useState } from "react";
import "../wpView.scss";
import { InfoData } from "./InfoData";

type Props = { wpRequisition: any };
type FileListItem = UploadFile & { id?: number };

export function Models({ wpRequisition }: Props) {
  const modelIdsToDelete = wpRequisition?.content?.modelsToDelete || [];
  const unchangedModels = wpRequisition?.models || [];

  const fileListToCreate = (wpRequisition?.modelsToCreate || []).map(
    (model: any) => formatData(model, "create")
  );
  const fileListToDelete = (wpRequisition?.modelsToDelete || []).map(
    (model: any) => formatData(model, "error")
  );
  const [unchangedfileList, setUnchangedFileList] = useState<FileListItem[]>(
    unchangedModels?.map((model: any) => formatData(model))
  );

  const allFileList = fileListToCreate
    .concat(fileListToDelete)
    .concat(unchangedfileList);

  useEffect(() => {
    setUnchangedFileList(
      unchangedModels?.map((model: any) => ({
        uid: model.upload_id || model.id,
        name: model.show_name,
        status: modelIdsToDelete.includes(model.upload_id) ? "error" : "done",
        url: model.url,
        id: model.upload_id,
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(wpRequisition)]);

  return (
    <>
      <InfoData
        name="Modelos"
        value={allFileList.length === 0 ? "Sem modelos" : <></>}
      />
      {/* <Carousel autoplay>
              {selectedItem?.models.map((model: Model) => (
                <Image src={model.url} alt={model.show_name} height={150} />
              ))}
            </Carousel> */}
      {allFileList.length > 0 ? (
        <div className="upload-container wp-view-upload">
          <Upload
            className="create-file-green-border"
            listType="picture-card"
            fileList={fileListToCreate
              .concat(fileListToDelete)
              .concat(unchangedfileList)}
            disabled={true}
          />
        </div>
      ) : null}
    </>
  );
}

const formatData = (model: any, status?: string) => ({
  uid: model.upload_id || model.id,
  name: model.show_name,
  status: status || "done",
  url: model.url,
  id: model.upload_id,
});
