import axios from "axios";
// import dotenv from "dotenv";

const access_token = localStorage.getItem("access_token");
//console.log(access_token);
const api = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    Authorization: `Bearer ${access_token}`,
  },
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error?.response?.status &&
      error?.response?.status === 401 &&
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/" &&
      error?.response?.config?.method === "get"
    ) {
      window.location = "/login" as any;
    }
    //
    else {
      if (
        (error?.config?.method && error?.config?.method === "get") ||
        (error?.response?.config?.method &&
          error?.response?.config?.method === "get")
      ) {
        // window.location = `/error/${error?.response?.status || 500}` as any;
        return;
      }
      //
      else {
        throw error;
      }
    }
  }
);

export default api;
