import { useEffect, useReducer, useState } from "react";
import {
  AuthenticatedContext,
  AuthenticatedDispatchContext,
} from "../../context/AuthContext";
import Login from "../../pages/Login/index";

function authReducer(state: any, action: any): any {
  return { logged: action.logged, expired: action.expired ?? false };
}

const AuthWrapper = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [auth, dispatchAuth] = useReducer(authReducer, {
    logged: false,
    expired: false,
  });
  const path = window.location.pathname;
  const resetPassword = path === "/reset-password";
  useEffect(() => {
    if (loading) {
      const access_token = localStorage.getItem("access_token");
      if (access_token) {
        dispatchAuth({ logged: true });
        setLoading(false);
      } else {
        dispatchAuth({ logged: false });
        if (auth.logged === false) {
          if (path !== "/login" && path !== "/reset-password") {
            window.location.href = "/login";
          }
        }
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <AuthenticatedContext.Provider value={auth}>
      <AuthenticatedDispatchContext.Provider value={dispatchAuth}>
        {!loading &&
          (auth.logged || resetPassword ? props.children : <Login />)}
      </AuthenticatedDispatchContext.Provider>
    </AuthenticatedContext.Provider>
  );
};

export default AuthWrapper;
