import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../config/axios";

type Props = {
  hired_id?: string | number;
  page: number;
  refresh?: Date;
};

export const useFetchEmployees = ({ hired_id, page, refresh }: Props) => {
  const [employees, setEmployees] = useState([]);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  const [isFetching, setIsFetching] = useState(false);

  const [error, setError] = useState<Error | null>(null);

  const returnEmployees = useCallback(() => {
    setIsFetching(true);
    api
      .get(`/v1/employees/${hired_id}/all?page=${page}`)
      .then((response) => {
        const data = response.data.data;
        const table = data.map((obj: any) => ({
          ...obj,
          key: obj.id,
          // actions: (
          //   <>
          //     <Button
          //       type="primary"
          //       onClick={() => navigate("/hireds/" + obj.id)}
          //     >
          //       Abrir
          //     </Button>
          //   </>
          // ),
        }));

        setEmployees(table);
        setTotal(response.data.total);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [hired_id, page, error, refresh]);

  useEffect(() => {
    returnEmployees();
  }, [returnEmployees]);

  return {
    employees,
    isFetching,
    error,
    total,
  };
};
