import { BellFilled } from "@ant-design/icons";
import { Badge, Popover } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../services/Api";
import { toastAlert } from "../../../utils/toastAlert";
import "./header.scss";

function Notifications(props: any) {
  const navigate = useNavigate();

  const [openPopover, setOpenPopover] = useState(false);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    getNotifications();
    const interval = setInterval(() => getNotifications(), 15000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  function getNotifications() {
    api.get("/v1/notification/latest").then((response) => {
      if (response.status === 200) {
        setNotifications(response?.data?.notifications || []);
        setUnreadCount(response?.data?.not_read);
      }
    });
  }

  const handleMarkAsRead = () => {
    setNotifications((prevState) =>
      prevState.map((item) => ({ ...item, read: true }))
    );

    api
      .post(`/v1/notification/many`, {
        notifications: notifications?.map((notification) => notification.id),
      })
      .then((response) => {
        getNotifications();
      })
      .catch((error) => {
        toastAlert({
          type: "error",
          message: `Verifique sua conexão e tente novamente.`,
          title: "Erro ao marcar notificações como lidas",
        });
      });
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpenPopover(newOpen);
    if (!newOpen) {
      handleMarkAsRead();
    }
  };

  return (
    <Popover
      overlayClassName="notifications"
      title="Notificações"
      trigger="click"
      placement="bottom"
      open={openPopover}
      onOpenChange={handleOpenChange}
      content={
        <div className="notifications-list">
          {notifications?.map((notification) => (
            <span
              className={`notifications-list-item${
                notification.read ? " read" : ""
              }`}
              onClick={() => {
                handleMarkAsRead();
                navigate(notification.url || "/notifications");
              }}
            >
              {notification.content}
            </span>
          ))}
          <span
            className="notifications-list-action"
            onClick={() => navigate("/notifications")}
          >
            Ver todas
          </span>
        </div>
      }
      style={{ padding: 0 }}
    >
      <Badge
        count={unreadCount}
        size="small"
        offset={[0, 20]}
        className="notification-icon"
      >
        <BellFilled
          style={{ color: "white", fontSize: "24px", cursor: "pointer" }}
        />
      </Badge>
    </Popover>
  );
}

export default Notifications;
