import { Switch as AntdSwitch, Form, SwitchProps } from "antd";
import { useFormContext } from "react-hook-form";

type Props = SwitchProps & {
  label: string;
  name: string;
};

export default function Switch({ label, name, onChange, ...others }: Props) {
  const {
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext();

  const values = watch();

  return (
    <Form.Item
      name={name}
      label={label}
      help={errors[name] ? (errors[name]?.message as string) : undefined}
      validateStatus={!!errors[name] ? "error" : undefined}
    >
      <AntdSwitch
        checked={values[name]}
        {...others}
        onChange={(checked, event) => {
          onChange ? onChange(checked, event) : setValue(name, checked);
          errors[name] && trigger(name);
        }}
      />
    </Form.Item>
  );
}
