import { z } from "zod";
import { requiredString } from "../utils/validations";

export const HiredEmployeeSchema = z.object({
  name: requiredString,
  email: requiredString.email("Email inválido"),
  phone: requiredString,
  description: requiredString,
  password: requiredString,
  hired_id: z.number().or(requiredString).optional(),
});

export type HiredEmployeeFormType = z.infer<typeof HiredEmployeeSchema>;
