import { UserOutlined } from "@ant-design/icons";
import { Avatar, Col, Grid, Image, Layout, Row, Typography } from "antd";
import { useState } from "react";
import { FaBars } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo-fluit.png";
import { useFetchUserInfo } from "../../../hooks/user/useFetchUserInfo";
import Drawer from "../Drawer";
import "./header.scss";
import Notifications from "./notifications";

const { Text } = Typography;
const { Content } = Layout;
const { useBreakpoint } = Grid;

function HeaderSystem(props: any) {
  const navigate = useNavigate();
  const { sm } = useBreakpoint();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const { userPhoto } = useFetchUserInfo();

  const handleLogout = (e: any) => {
    localStorage.clear();
    localStorage.setItem("acceptLGPD", "true");
    window.location.href = "/login";
  };

  return (
    <Content className="site-layout header-layout" style={{ margin: "0 auto" }}>
      <Row className="header-row pb-1" align={"middle"}>
        <Col>
          <Image
            src={logo}
            preview={false}
            className={`logo${
              ["", "/login"].includes(pathname) ? "_login" : "_dashboard"
            }`}
            onClick={() => navigate("/home/")}
          />
        </Col>

        <Col
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "36px",
          }}
        > 
          <div onClick={() => navigate("/profile")} className="user-avatar">
            {!!userPhoto ? (
              <Avatar src={userPhoto} className="avatar-profile-photo" />
            ) : (
              <Avatar icon={<UserOutlined />} className="avatarImage" />
            )}

            {sm ? (
              <>
                <Col>
                  <Col sm={24} className="mbn-1">
                    <Text className="header-text">
                      {localStorage.getItem("user_name")}
                    </Text> 
                  </Col>
                  <Col sm={24} className="mt-05">
                    <Text className="fs-10 pt-2 text-white">
                      {localStorage.getItem("user_role")}                    
                    </Text>
                  </Col>    
                </Col>
              </>
            ) : null}
          </div>

          <Notifications />

          <Col className="logout-button" onClick={() => setOpen(true)}>
            <span className="header-text">
              <FaBars size={sm ? 20 : 24} />
            </span>
          </Col>

          <Col className="logout-button" onClick={handleLogout}>
            <span className="header-text">
              <FiLogOut size={sm ? 20 : 24} />
            </span>
          </Col>
        </Col>
      </Row>
      {open ? <Drawer open={open} onClose={() => setOpen(false)} /> : null}
    </Content>
  );
}

export default HeaderSystem;
