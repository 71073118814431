import { useCallback } from "react";
import api from "../../config/axios";
import dayjs from "dayjs";
type Props = {
  refresh?: Date;
  startDate?: string;
  endDate?: string;
  project: number | string;
  hired: string;
  contract: string;
};
type PropsExport = {
  id?: string;
};
export const useExportBM = ({ id }: PropsExport) => {
  const exportBm = useCallback(async ({ startDate, endDate, project, hired, contract }: Props) => {
    try {
      const response = await api.get(
        `/v1/measurement-bulletins/export/${id}?startDate=${startDate}&endDate=${endDate}&project=${project}&hired=${hired}&contract=${contract}`,
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
        }
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `[FLUIT]-BM-${dayjs().format("DD-MM-YYYY-HH-mm")}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting Report XLSX:", error);
    }
  }, []);

  return exportBm;
};
