import formatDate from "../../../utils/formatDate";
import { formatPercentage } from "../../../utils/formatPercentage";
import returnStatusMeasurementPartials from "../../../utils/returnStatusMeasurementPartials";

export const partialsColumns = [
  { title: "Nome da Atividade", dataIndex: "name" },
  { title: "Código da Atividade", dataIndex: "code" },
  { title: "Percentual Solicitado", dataIndex: "percentage", render: (percentage: any) => <>{percentage ? formatPercentage(percentage) : "-"}</> },
  { title: "Status", dataIndex: "solicitation_status_id", render: (status: any) => <>{returnStatusMeasurementPartials(status)}</> },
  { title: "Data de Aprovação", dataIndex: "approved_date", render: (date: any) => <>{date ? formatDate(date) : "-"}</> },
  { title: "Data de Reprovação", dataIndex: "reproved_date", render: (date: any) => <>{date ? formatDate(date) : "-"}</> },
  { title: "Justificativa", dataIndex: "justification" },
  { title: "", dataIndex: "actions" },
];
