import { useCallback, useEffect, useState } from "react";
import api from "../../config/axios";

export const useFetchUserInfo = () => {
  const [user, setUser] = useState();
  const [userPhoto, setUserPhoto] = useState(
    localStorage.getItem("user_photo")
  );
  const [isFetching, setIsFetching] = useState(false);

  const returnUser = useCallback(() => {
    setIsFetching(true);
    api
      .get(`/v1/user/me`)
      .then((response) => {
        const data = response.data;
        setUser(data);
        setUserPhoto(data.user_photo);
        localStorage.setItem("user_photo", data.user_photo);
      })
      .catch((err) => {
        //  setError(err);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, []);

  useEffect(() => {
    returnUser();
  }, [returnUser]);

  return {
    user,
    userPhoto,
    isFetching,
  };
};
