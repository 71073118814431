import { Button, Modal } from "antd";
import ValidationEvidenceForm from "./FormCreateValidation";
type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

export default function ModalCreateValidation({ isOpen, handleClose }: Props) {
  return (
    <Modal title={`Criar novo tipo de evidências`} footer={[]} width={"50%"} open={isOpen} onCancel={handleClose}>
      <div className="container-radio mt-2">
        <ValidationEvidenceForm handleClose={handleClose} />
      </div>
    </Modal>
  );
}
