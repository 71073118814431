import { useState } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

type Props = {
  formData: {
    date?: string;
    id?: number | string;
  };
};
export const usePostDate = () => {
  const [isPosting, setIsPosting] = useState(false);
  const [refresh, setRefresh] = useState<Date>();

  const postDate = async ({ formData }: Props) => {
    try {
      setIsPosting(true);

      await api.post(`/v1/activity/${formData.id}/date`, {
        ...formData,
      });
      toastAlert({
        type: "success",
        message: "Nova data de execução salva.",
        title: "A data de execução da atividade foi editada e salva.",
      });
      setRefresh(new Date());
    } catch (error: any) {
      toastAlert({
        type: "error",
        message: "Não foi possível atribuir essa data, tente novamente.",
        title: "Erro ao mudar a data da atividade",
      });
    } finally {
      setIsPosting(false);
    }
  };

  return { postDate, isPosting, refresh };
};
