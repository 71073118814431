import { TableColumnsType } from "antd";
interface DataType {
  key: React.Key;
  content: string;
  children?: DataType[];
}
export const columns: TableColumnsType<DataType> = [
  { title: "Nome", dataIndex: "name", key: "name" },
  { title: "Descrição", dataIndex: "description", key: "description" },
  { title: "E-mail", dataIndex: "email", key: "email" },
];

export const mobileColumns: TableColumnsType<DataType> = [
  { title: "Nome", dataIndex: "name", key: "name" },
  { title: "E-mail", dataIndex: "email", key: "email" },
];
