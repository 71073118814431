
import React from "react";
import { Steps, Button } from "antd";

const { Step } = Steps;

interface StepItem {
    title: string;
    description: string;
    component: React.ReactNode;
}

interface StepsComponentProps {
    steps: StepItem[];
    currentStep: number;
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
    isNextDisabled?: boolean;
    onNext?: () => void; // Adiciona a função onNext
}

const StepsComponent: React.FC<StepsComponentProps> = ({ steps, currentStep, setCurrentStep, isNextDisabled = false, onNext }) => {
    const next = () => {
        if (onNext) onNext(); // Executa a função onNext antes de avançar
        setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
    };

    const prev = () => setCurrentStep((prev) => Math.max(prev - 1, 0));

    return (
        <div>
            <Steps current={currentStep}>
                {steps.map((step, index) => (
                    <Step key={index} title={step.title} description={step.description} />
                ))}
            </Steps>
            <div className="steps-content" style={{ marginTop: 16 }}>
                {steps[currentStep].component}
            </div>
            <div className="steps-action" style={{ marginTop: 16 }}>
                {currentStep > 0 && <Button onClick={prev}>Voltar</Button>}
                {currentStep < steps.length - 1 && (
                    <Button type="primary" onClick={next} style={{ marginLeft: 8 }} disabled={isNextDisabled}>
                        Próximo
                    </Button>
                )}
            </div>
        </div>
    );
};

export default StepsComponent;
