import { useState } from "react";
import api from "../../config/axios";

import { Notification } from "../../models/Notification";
import { toastAlert } from "../../utils/toastAlert";

type Props = {
  notification: Notification;
};
type PropsMany = {
  notifications: Notification[];
};
export const useReadNotifications = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState<Date>();
  const [id, setId] = useState(0);

  let debounceTimeout: NodeJS.Timeout;
  const markAsRead = async ({ notification }: Props) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    setId(notification.id);
    setIsLoading(true);
    debounceTimeout = setTimeout(async () => {
      try {
        await api.patch(`/v1/notification/${notification?.id}`);
        setRefresh(new Date());
        toastAlert({
          type: "success",
          message: `Você ainda pode a encontrar na página de notificações.`,
          title: "Notificação marcada como lida.",
        });
      } catch (error) {
        toastAlert({
          type: "error",
          message: `Verifique sua conexão e tente novamente.`,
          title: "Não foi possível marcar como lida",
        });
      } finally {
        setIsLoading(false);
      }
    }, 200); // Aguarde 4 segundos antes de realizar a chamada real para a API
  };

  const markAsReadMany = async ({ notifications }: PropsMany) => {
    setIsLoading(true);
    await api
      .post(`/v1/notification/many`, {
        notifications: notifications?.map((data) => data.id),
      })
      .then((response) => {
        setRefresh(new Date());
        toastAlert({
          type: "success",
          message: `Você ainda pode a encontrar na página de notificações.`,
          title: "Notificações marcadas como lidas.",
        });
      })
      .catch((error) => {
        toastAlert({
          type: "error",
          message: `Verifique sua conexão e tente novamente.`,
          title: "Não foi possível marcar as notificações como lidas",
        });
      })
      .finally(() => setIsLoading(false));
  };

  return { isLoading, refresh, markAsRead, markAsReadMany, id };
};
