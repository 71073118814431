import { z } from "zod";

export const ValidationEvidenceSchema = z.object({
  //file_type: z.string().max(50, "Máximo de 50 caracteres").min(1, "Campo obrigatório"),
  title: z.string().min(1, "Campo obrigatório"),
  contract_id: z.number().min(1, "Campo obrigatório"),
  description: z.string().optional(),
  requires_control: z.boolean(),
  requires_quality: z.boolean(),
  dynamic_validation: z.string().optional(),
  is_required: z.boolean(),
});

export type ValidationEvidenceFormType = z.infer<typeof ValidationEvidenceSchema>;
