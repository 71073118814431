import { useCallback } from "react";
import Report from "../../../models/Report";

export function CalculateTotalReport(reports: any[]) {
  const total = useCallback(() => {
    let totalCost = 0;
    reports.forEach((report: Report) => {
      totalCost += +report.cost;
      if (report?.rejected_cost) totalCost += +report?.rejected_cost;
    });
    return totalCost;
  }, [reports]);

  return total();
}
