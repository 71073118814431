import { z } from "zod";

export const schema_edit_hired = z.object({
  name: z.string().nonempty("O nome é obrigatório"),
  description: z.string().nonempty("A descrição é obrigatória"),
  email: z.string().email("Formato de e-mail inválido"),
  phone: z.string().min(10, "Número de telefone inválido"),
  tenant_id: z.number().positive("ID inválido"),
  user_id: z.string().nonempty("User ID é obrigatório"),
});
