import { Modal } from "antd";
import CreateEmployeeOrHired from "../CreateEmployeeOrHired";
import "./modalCreateEmployeeOrHired.scss";
type Props = {
  isModalOpen: boolean;
  handleCancel: () => void;
  afterSubmit: VoidFunction;
  hired_id?: number | string;
  type: string;
};

function ModalCreateEmployeeOrHired({
  isModalOpen,
  handleCancel,
  afterSubmit,
  hired_id,
  type,
}: Props) {
  const title = type === "employees" ? "Colaborador" : "Contratada";
  return (
    <Modal
      title={`Criar ${title}`}
      closeIcon={true}
      footer={null}
      width={600}
      open={isModalOpen}
      onCancel={handleCancel}
    >
      <CreateEmployeeOrHired
        onClosePopup={handleCancel}
        afterSubmit={afterSubmit}
        type={type}
        hired_id={hired_id}
      />
    </Modal>
  );
}

export default ModalCreateEmployeeOrHired;
