import { z } from "zod";
import { requiredNumber, requiredString } from "../utils/validations";

export const ActivitySchema = z
  .object({
    name: requiredString,
    type: requiredString,
    code: requiredString,
    description: requiredString,
    estimateHours: z.string().optional(),
    subDiscipline: requiredString.or(z.number()),
    parentActivityId: z.number().optional(),
    projectInformationId: requiredNumber,
    createDefault: z.boolean(),
    cwpId: z.number().or(z.string()).optional(),
  })
  .refine((data) => data.type !== "IWP", {
    message: "Campo obrigatório",
    path: ["cwpId"],
  });

export type ActivityFormType = z.infer<typeof ActivitySchema>;

export type CreatedActivity = ActivityFormType & { uniqueName: string };
