import { z } from "zod";
import { requiredNumber, requiredString } from "../utils/validations";

export const ContractSchema = z.object({
  description: requiredString,
  amount: requiredNumber.or(requiredString),
  hired_id: requiredNumber.or(requiredString),
  activity_id: requiredNumber.or(requiredString),
  active: z.boolean(),
});

export type ContractFormType = z.infer<typeof ContractSchema>;
