import { Button } from "antd";
import { useState } from "react";

export function NavigateButton({
  navFunction,
  funcProps,
  name,
}: {
  navFunction: (data: any) => void;
  funcProps: any;
  name: string;
}) {
  const [loading, setLoading] = useState(false);

  return (
    <Button
      type="primary"
      size="small"
      loading={loading}
      disabled={loading}
      id="open-nav"
      onClick={() => {
        setLoading(true);
        navFunction({ ...funcProps, afterLoading: () => setLoading(false) });
      }}
    >
      Abrir {name}
    </Button>
  );
}
