import { useParams } from "react-router-dom";
import ErrorCard from "../../components/ErrorCard";
import "./ErrorPage.scss";

export default function ErrorPage({ errorType }: { errorType?: number }) {
  const { error } = useParams();
  const statusError = !!errorType ? errorType : error ? error : 500;

  return (
    <>
      <div className="containerErrorPage">
        <div className="contentError">
          <img src="/logo-fluit.png" className="logoErrorScreen" alt="" />
          <ErrorCard statusCode={+statusError} />
        </div>
      </div>
    </>
  );
}
