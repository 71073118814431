import axios from "axios";

const access_token = localStorage.getItem("access_token");

const api = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    Authorization: `Bearer ${access_token}`,
  },
});

export default api;
