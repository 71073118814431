import { Badge } from "antd";

export const statusTabs = [
  {
    label: <Badge status="processing" text="Atividades" />,
    key: "activities",
    children: <></>,
  },
  {
    label: <Badge status="processing" text="Validação de evidência" />,
    key: "evidence",
    children: <></>,
  },
  {
    label: <Badge status="processing" text="Medição" />,
    key: "measurement",
    children: <></>,
  },
];

export const activityTabs = (statusCounts: Record<string, number>) => [
  {
    label: <Badge status="default" text={`Não iniciadas (${statusCounts[1] || 0})`} />,
    key: "1",
    children: <></>,
  },
  {
    label: <Badge status="warning" text={`Pendentes (${statusCounts[2] || 0})`} />,
    key: "2",
    children: <></>,
  },
  {
    label: <Badge status="success" text={`Completas (${statusCounts[3] || 0})`} />,
    key: "3",
    children: <></>,
  },
  {
    label: <Badge status="error" text={`Recusadas (${statusCounts[4] || 0})`} />,
    key: "4",
    children: <></>,
  },
];


export const measureTabs = (measurementCont: Record<string, number>) => [
  {
    label: <Badge status="default" text={`Pendentes (${measurementCont['pending'] || 0})`}/>,
    key: "1",
    children: <></>,
  },
  {
    label: <Badge status="success" text={`Aprovadas (${measurementCont['approved'] || 0})`} />,
    key: "4",
    children: <></>,
  },
  {
    label: <Badge status="error" text={`Reprovadas (${measurementCont['rejected'] || 0})`} />,
    key: "3",
    children: <></>,
  },
];

export const evidenceTabs = (validacaoConts: Record<string, number>) => [
  {
    label: <Badge status="default" text={`Pendentes (${validacaoConts['pending'] || 0})`}  />,
    key: "1",
    children: <></>,
  },
  {
    label: <Badge status="success" text={`Aprovadas (${validacaoConts['approved'] || 0})`} />,
    key: "4",
    children: <></>,
  },
  {
    label: <Badge status="error" text={`Reprovadas (${validacaoConts['rejected'] || 0})`} />,
    key: "3",
    children: <></>,
  },
];
