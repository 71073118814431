import { useEffect, useState } from "react";
import ContractView from "../../components/ContractView";
import Layout from "../../components/Template/Layout";
import api from "../../services/Api";
import "./contractsPage.scss";

import { PaginationProps, Spin } from "antd";
import { useParams } from "react-router-dom";
import CollapseCard from "../../components/Template/Card";

function ContractViewPage() {
  const { contract_id } = useParams();
  const [contract, setContract] = useState();
  const [total, setTotal] = useState();
  const [activities, setActivities] = useState([]);
  const [refresh, setRefresh] = useState(new Date());
  const [current, setCurrent] = useState(1);
  const onChange: PaginationProps["onChange"] = (page) => {
    setCurrent(page);
  };

  useEffect(() => {
    api.get(`/v1/contracts/${contract_id}?page=${current}`).then((response) => {
      if (response?.status === 200) {
        const contract = response.data.contract;
        setTotal(response.data.activities.total);
        const activities = response.data.activities.data.map((obj: any) => ({
          ...obj,
          key: obj.id,
        }));

        setContract(contract);
        setActivities(activities);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, current]);

  return (
    <>
      <Layout pageTitle="Detalhes do Contrato" breadcrumb={`Contratos ⟶ Contrato #${contract_id}`}>
        {!!contract ? (
          <ContractView
            contract={contract}
            total={total}
            activities={activities}
            handleRefresh={() => setRefresh(new Date())}
            current={current}
            onChange={onChange}
          />
        ) : (
          <CollapseCard title="Dados">
            <Spin />
          </CollapseCard>
        )}
      </Layout>
    </>
  );
}

export default ContractViewPage;
