import { Button, Col, Grid, Row, Spin, Table, TableColumnsType } from "antd";

import { PaginationProps } from "antd/lib/pagination";
import { Key, ReactNode, useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { statusList } from "../../constants/status";
import { Activity } from "../../models/Activity";
import api from "../../services/Api";
import "./cwpView.scss";
import { ExpandedTable } from "./expandTable";
import { useFetchIwps } from "../../hooks/iwps/fetchIwps";
import { activityColumns, iwpColumns, iwpColumnsXxl } from "./utils/columns";
import SearchInput from "../SearchInput";

const { useBreakpoint } = Grid;

export type TableType = {
  ipws: { data: any; isLoading: boolean; isLoadingId: any; total: number };
  activities: {
    data: any;
    isLoading: boolean;
    isLoadingId: any;
    total: number;
  };
};

function CwpView({
  cwpId,
  initialActivities,
  setTotalIwps,
}: {
  cwpId: number;
  initialActivities: Activity[];
  setTotalIwps: (n: number) => void;
}) {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(1);
  const [search, setSearch] = useState("");
  const [apiSearch, setApiSearch] = useState("");
  const { xxl } = useBreakpoint();
  const [currentKeys, setCurrentKeys] = useState<any[]>([]);
  const [tables, setTables] = useState<TableType>({
    ipws: { data: [], isLoading: true, isLoadingId: null, total: 0 },
    activities: { data: [], isLoading: true, isLoadingId: null, total: 0 },
  });

  const { isFetching, total } = useFetchIwps({
    cwpId,
    current,
    setTables,
    search: apiSearch,
  });

  const expandedRowRender = (data: any): ReactNode => (
    <ExpandedTable
      table={tables.activities}
      record={data}
      setExpandedData={async ({
        key,
        page,
      }: {
        key: Key;
        page?: number | undefined;
      }) => await setExpandedData({ key, page, setTables, navigate })}
    />
  );

  const onChange: PaginationProps["onChange"] = (page) => {
    setCurrent(page);
  };

  return (
    <Row gutter={16}>
      {isFetching ? (
        <Spin />
      ) : (
        <>
          <Col span={24} className="mb-2">
            <div className="search-input-container">
              <SearchInput
                handleChange={(e) => setSearch(e.target.value)}
                handleSearch={() => setApiSearch(search)}
                value={search}
                type="string"
                placeholder="Procure por IWP"
              />
            </div>

            <div style={{ overflow: "auto" }}>
              <Table
                className="table-default-style"
                dataSource={tables.ipws.data}
                columns={xxl ? iwpColumnsXxl : iwpColumns}
                expandable={{
                  expandedRowRender,
                  onExpandedRowsChange: (keys) => {
                    if (currentKeys.length < keys.length) {
                      setExpandedData({
                        key: keys[keys.length - 1],
                        page: 1,
                        setTables,
                        navigate,
                      });
                    }
                    setCurrentKeys([...keys]);
                  },
                }}
                pagination={{
                  current,
                  onChange,
                  total,
                  pageSize: 30,
                  showSizeChanger: false,
                }}
                style={{ minWidth: "600px" }}
              />
            </div>
          </Col>
          <Col span={24}>
            <div style={{ overflow: "auto" }}>
              <Table
                className="table-default-style"
                dataSource={initialActivities}
                columns={activityColumns}
                style={{ minWidth: "600px" }}
              />
            </div>
          </Col>
        </>
      )}
    </Row>
  );
}

export default CwpView;

const setExpandedData = async ({
  key,
  page,
  navigate,
  setTables,
}: {
  key: Key;
  page?: number | undefined;
  navigate: NavigateFunction;
  setTables: React.Dispatch<React.SetStateAction<TableType>>;
}) => {
  if (!key) return false;

  setTables((prevState) => ({
    ...prevState,
    activities: { ...prevState.activities, isLoading: true, isLoadingId: key },
  }));
  await api
    .get(`/v1/activity/iwp/${key}?page=` + (page || 1))
    .then((response) => {
      if (response.status === 200) {
        const data = response.data?.data?.map((obj: any) => ({
          ...obj,
          key: obj.id,
          atividades: obj.name,
          responsavel: obj.responsible_name || "-",
          status: obj.status_id,
          actions: (
            <Button
              type="primary"
              onClick={() => navigate("/activity/" + obj.id)}
            >
              Abrir
            </Button>
          ),
        }));
        setTables((prevState) => ({
          ...prevState,
          activities: {
            ...prevState.activities,
            data: { ...prevState.activities.data, [key]: data || [] },
            total: response.data.total,
          },
        }));
      }

      setTables((prevState) => ({
        ...prevState,
        activities: {
          ...prevState.activities,
          isLoading: false,
          isLoadingId: null,
        },
      }));
    });
};
