import { useCallback, useEffect, useState } from "react";
import api from "../../config/axios";

type Props = {
  activity_id?: string | number;
  refresh?: Date;
};

export const useFetchActivityHistory = ({ activity_id, refresh }: Props) => {
  const [history, setHistory] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const returnHistory = useCallback(() => {
    if (activity_id) {
      setIsFetching(true);
      api
        .get(`/v1/activity/${activity_id}/history`)
        .then((response) => {
          const data = response.data;
          setHistory(data);
        })
        .catch((err) => {
          //  setError(err);
        })
        .finally(() => {
          setIsFetching(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity_id, refresh]);

  useEffect(() => {
    returnHistory();
  }, [returnHistory]);

  return {
    history,
    isFetching,
  };
};
