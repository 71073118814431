import { useState } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

type Props = {
  formData: {
    name: string;
    email: string;
    phone: string;
    description: string;
    password: string;
    hired_id?: number | string;
  };
  afterSubmit: () => void;
  onClosePopup: () => void;
  type: string;
};
export const useCreateHiredOrEmployee = ({
  formData,
  afterSubmit,
  onClosePopup,
  type,
}: Props) => {
  const [isFetching, setIsFetching] = useState(false);
  const name = type === "employees" ? "colaborador" : "contratada";

  const handleFormSubmit = async (values: any) => {
    setIsFetching(true);

    await api
      .post(`/v1/${type}/create`, formData)
      .then((response) => {
        afterSubmit();
        onClosePopup();
      })
      .catch((error) => {
        toastAlert({
          type: "error",
          message: `${error.response.data.message}`,
          title: `Não foi possível criar ${name} `,
        });
      })
      .finally(() => setIsFetching(false));
  };

  return { handleFormSubmit, isFetching };
};
