import { Col, Image, Row, Typography } from "antd";

import { useState } from "react";
import logo from "../../assets/images/logo-fluit.png";

import { ForgotPasswordForm } from "./components/ForgotPasswordForm";
import { LoginForm } from "./components/LoginForm";
import PolicyModal from "./components/modal";

import "./login.scss";

const { Text } = Typography;

function App() {
  const localStorageOpenAcceptLGPDModal = localStorage.getItem("acceptLGPD");
  const [openAcceptLGPDModal, setOpenAcceptLGPDModal] = useState<boolean>(
    true && !localStorageOpenAcceptLGPDModal
  );

  const [isPasswordForgotten, setIsPasswordForgotten] = useState(false);

  const forgotPassword = () => {
    setIsPasswordForgotten(!isPasswordForgotten);
  };

  return (
    <>
      <Row
        justify="space-around"
        align="middle"
        className={`rowLogin login-background`}
      >
        <Col className="loginBox">
          <Col span={24} style={{ height: 89 }}>
            <Image src={logo} preview={false} className="logo mb-4" />
          </Col>
          {!isPasswordForgotten ? <LoginForm /> : <ForgotPasswordForm />}

          <Col span={24} className="mt-1">
            <Text
              id="go-to-forgot-password"
              onClick={forgotPassword}
              className="forgotPassword"
              style={{ cursor: "pointer" }}
            >
              {!isPasswordForgotten ? "Esqueci minha senha" : "Voltar"}
            </Text>
          </Col>
        </Col>
        <PolicyModal
          isModalOpen={openAcceptLGPDModal}
          handleClose={() => {
            setOpenAcceptLGPDModal(false);
            localStorage.setItem("acceptLGPD", "true");
          }}
        />
      </Row>
    </>
  );
}

export default App;
