import { Button, Col, Progress, Row, Table, TableColumnsType, Typography } from "antd";
import { useEffect, useState } from "react";
import SearchInput from "../../components/SearchInput";
import Layout from "../../components/Template/Layout";
import api from "../../services/Api";
import "./projectsPage.scss";

import type { PaginationProps } from "antd";
import { Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import CollapseCard from "../../components/Template/Card";
import { formatPercentage } from "../../utils/formatPercentage";
const { Title } = Typography;

interface DataType {
  key: React.Key;
  name: string;
  description: string;
}

function ProjectsPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [dataTable, setDataTable] = useState();
  // const [pageSize, setPageSize] = useState(0);
  // search states and functions

  const [search, setSearch] = useState("");
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const handleSearch = (search: string) => {
    setIsLoading(true);
    if (search !== "") {
      api
        .post("/v1/projects/search", {
          search: search,
        })
        .then((r) => {
          const data = r.data;
          const table = data?.map((obj: any) => ({
            ...obj,
            key: obj.id,
            actions: (
              <>
                <Button type="primary" onClick={() => handleSetProject(obj)} id="open-project">
                  Abrir
                </Button>
              </>
            ),
          }));

          setDataTable(table);
          setTotal(table.length);
          // setPageSize(table.length);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      getData();
    }
  };

  // end of search
  const navigate = useNavigate();

  const columns: TableColumnsType<DataType> = [
    { title: "Nome", dataIndex: "name", key: "name" },
    { title: "Descrição", dataIndex: "description", key: "description" },
    { title: "Consumido", dataIndex: "percent", key: "percent", width: 150 },
    { title: "", dataIndex: "actions", key: "actions", width: 200, align: "right" },
  ];

  async function getData() {
    setIsLoading(true);

    await api
      .get("/v1/projects/all")
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data;
          const table = data.map((obj: any) => ({
            ...obj,
            percent: <Progress percent={+((obj.pay_cost / obj.total_cost) * 100).toFixed(2)} />,
            key: obj.id,
            actions: (
              <>
                <Button type="primary" onClick={() => handleSetProject(obj)} id="open-project">
                  Abrir
                </Button>
              </>
            ),
          }));

          setDataTable(table);
          setTotal(response.data.total);
          // setPageSize(data.per_page);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetProject = (obj: any) => {
    navigate(`/project/${obj.id}/cwas`);
  };

  const onChange: PaginationProps["onChange"] = (page) => {
    setCurrent(page);
  };

  return (
    <>
      <Layout pageTitle="Lista de Projetos" breadcrumb="Home ⟶ Projetos">
        <Row gutter={16} className="page-row">
          <Col xs={24} md={6} className="mb-2">
            <CollapseCard title="Total">
              {!!total ? (
                <Title level={2} className="total-card">
                  {total}
                </Title>
              ) : null}
            </CollapseCard>
          </Col>
          <Col xs={24} md={18}>
            <CollapseCard title="Tabela de Projetos" className="card-table-no-padding">
              <Row>
                <Col span={24}>
                  <Col span={24} className="mb-2 text-right">
                    <SearchInput
                      type="text"
                      handleChange={handleChangeSearch}
                      value={search}
                      handleSearch={handleSearch}
                      loading={isLoading}
                      placeholder="Busque por nome"
                    />
                  </Col>

                  <Table
                    className="table-projects"
                    columns={columns}
                    dataSource={dataTable}
                    pagination={false}
                    loading={isLoading}
                    size="small"
                    locale={{ emptyText: "Sem dados" }}
                  />
                </Col>

                <Col
                  span={24}
                  className="text-center mt-4"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "5px",
                  }}
                >
                  <Pagination current={current} onChange={onChange} total={total} simple={false} showSizeChanger={false} />
                </Col>
              </Row>
            </CollapseCard>
          </Col>
        </Row>
      </Layout>
    </>
  );
}

export default ProjectsPage;
