import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../config/axios";
import { Button } from "antd";

type Props = {
  page: number;
  refresh?: Date;
  project?: number;
};

export const useFetchFlow = ({ page, refresh, project }: Props) => {
  const [activities, setActivities] = useState([]);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const [projects, setProjects] = useState([]);

  const returnFlow = useCallback(() => {
    setIsFetching(true);
    api
      .get(`/v1/activity/flow?page=${page}&project=${project ? project : ""}`)
      .then((response) => {
        const data = response.data.activities.data;

        const projects = response.data.projects.map((obj: any) => ({
          value: obj.id,
          label: obj.name,
        }));
        setProjects(projects);
        const table = data.map((obj: any) => ({
          ...obj,
          key: obj.id,
          actions: (
            <>
              <Button type="primary" onClick={() => navigate("/activity/" + obj.id)}>
                Abrir
              </Button>
            </>
          ),
        }));

        setActivities(table);

        setTotal(response.data.activities.total);
      })
      .catch((err) => {})
      .finally(() => {
        setIsFetching(false);
      });
  }, [page, refresh, project]);

  const searchFlow = (search: string) => {
    if (!!search) {
      setIsFetching(true);
      api
        .post(`/v1/activity/flow/search`, {
          search,
        })
        .then((response) => {
          const data = response.data.activities.data;
          const table = data.map((obj: any) => ({
            ...obj,
            key: obj.id,
            actions: (
              <>
                <Button type="primary" onClick={() => navigate("/activity/" + obj.id)}>
                  Abrir
                </Button>
              </>
            ),
          }));
          setActivities(table);
          setTotal(response.data.activities.total || 0);
        })
        .catch((err) => {})
        .finally(() => {
          setIsFetching(false);
        });
    } else {
      returnFlow();
    }
  };
  useEffect(() => {
    returnFlow();
  }, [returnFlow]);

  return {
    searchFlow,
    activities,
    isFetching,
    projects,
    total,
  };
};
