import { Badge, Button, Col, Row, Table, Tabs, Typography } from "antd";
import { useEffect, useState } from "react";
import SearchInput from "../../components/SearchInput";
import Layout from "../../components/Template/Layout";
import api from "../../services/Api";
import "./qualityPage.scss";

import { FaCheck } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { MeasureFileListActions } from "../../components/MeasureFileListActions";
import CollapseCard from "../../components/Template/Card";
import ReportSelect from "../ReportPage/components/ReportSelect";
import formatDate from "../../utils/formatDate";
import { TextCropToltip } from "../../components/TextCropToltip";
import dayjs from "dayjs";
import DatePickerComponent from "../../components/DatePicker/DatePicker";
const { Title } = Typography;

const unChangebleColumns = [
  { title: "Nome", dataIndex: "show_name" },

  { title: "Código", dataIndex: "code" },
  { title: "Step de medição", dataIndex: "activity_name" },
  {
    title: "Descrição da atividade",
    dataIndex: "joined_wp_description",
    key: "joined_wp_description",
    render: (description: any) => <TextCropToltip text={description} max_quantity={35} />,
  },
  { title: "Etapa", dataIndex: "etapa" },
  { title: "Data de realização", dataIndex: "activity_date", render: (name: any) => <>{name ? formatDate(name) : "-"}</> },
  {
    title: "Data de criação",
    dataIndex: "created_at",
    render: (name: any) => <>{new Date(name).toLocaleDateString("pt-BR")}</>,
  },
];
const refuseColumn = [
  ...unChangebleColumns,
  {
    title: "Motivo da recusa",
    dataIndex: "refuse_justification",
  },
  { dataIndex: "actions", width: 150 },
];

const qualityDateColumn = {
  dataIndex: "quality_date",
  width: 200,
  render: (name: any, record: any) => (
    <div className={"file-table-list-date " + (!!record.uploadData?.quality_approved_by ? "positive-text-color" : "negative-text-color")}>
      {!!name ? (
        <>
          {!!record.uploadData?.quality_approved_by ? <FaCheck size={18} /> : <IoClose size={20} />}
          {new Date(name + "T00:00:00").toLocaleDateString("pt-BR")}
        </>
      ) : (
        ""
      )}
    </div>
  ),
};

function QualityPage() {
  const [isLoading, setIsLoading] = useState<boolean | undefined>(true);
  const [dataTable, setDataTable] = useState([]);
  const [current, setCurrent] = useState(1);
  const [currentStatus, setCurrentStatus] = useState("pending");
  const [total, setTotal] = useState(0);
  const [date, setDate] = useState<Date[]>([]);
  const [filteredTotal, setFilteredTotal] = useState(0);
  const [projectId, setProjectId] = useState("0");
  const [projects, setProjects] = useState([]);
  const [roles, setRoles] = useState<string[]>([]);
  const [columns, setColumns] = useState<any[]>([...unChangebleColumns, { dataIndex: "quality_date" }, { dataIndex: "actions", width: 150 }]);
  const [refreshFileList, setRefreshFileList] = useState(new Date());
  const handleChangeProject = (e: any) => {
    setProjectId(e);
  };
  const navigate = useNavigate();

  const tabs = [
    {
      label: <Badge status="default" text="Pendentes" />,
      key: "pending",
      children: <></>,
    },
    {
      label: <Badge status="success" text="Aprovadas" />,
      key: "approved",
      children: <></>,
    },
    {
      label: <Badge status="error" text="Recusadas" />,
      key: "refused",
      children: <></>,
    },
  ];

  const [search, setSearch] = useState("");
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const handleSearch = (search: string) => {
    setIsLoading(true);
    if (search !== "") {
      api
        .post(`/v1/activity-upload/quality/` + currentStatus + "/search", {
          search: search,
        })
        .then((r) => {
          const data = r.data;
          setTotal(data.length);
          const table = data.map((obj: any) => ({
            ...obj,
            actions: (
              <div
                style={{
                  display: "flex",
                  gap: 8,
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <MeasureFileListActions
                  file={obj}
                  hideActions={false}
                  refreshList={() => setRefreshFileList(new Date())}
                  roles={roles}
                  setTableIsLoading={setIsLoading}
                  disableApprove={currentStatus === "approved"}
                  disableRefuse={currentStatus === "refused"}
                  alwaysShowRefuseAfterApprove={currentStatus === "approved"}
                />
                <Button type="primary" onClick={() => navigate("/activity/" + obj.schedule_activity_id)}>
                  Ir para atividade
                </Button>
              </div>
            ),
          }));

          setFilteredTotal(r.data.length);
          setDataTable(table);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      fetchItemsByStatus();
    }
  };

  useEffect(() => {
    api.get(`/v1/user/roles`).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        setRoles(data.roles);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchItemsByStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, currentStatus, refreshFileList, roles, projectId, date]);

  useEffect(() => {
    setColumns([
      ...unChangebleColumns,
      {
        ...qualityDateColumn,
        title: currentStatus === "approved" ? "Aprovada em" : currentStatus === "refused" ? "Recusada em" : "",
      },
      { dataIndex: "actions", width: 150 },
    ]);
  }, [currentStatus]);

  const fetchItemsByStatus = async () => {
    setIsLoading(true);
    const startDate = date?.length > 0 ? dayjs(date[0])?.format("YYYY-MM-DD") : "";
    const endDate = date?.length > 0 ? dayjs(date[1])?.format("YYYY-MM-DD") : "";
    await api
      .get(`/v1/activity-upload/quality/${currentStatus}?page=${current}&project=${projectId}&startDate=${startDate}&endDate=${endDate}`)
      .then((response) => {
        const data = response.data.data.data;
        setTotal(response.data.data.total);
        setProjects(response.data.projects);

        const table = data.map((obj: any) => ({
          ...obj,
          actions: (
            <div
              style={{
                display: "flex",
                gap: 8,
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <MeasureFileListActions
                file={obj}
                hideActions={false}
                refreshList={() => setRefreshFileList(new Date())}
                roles={roles}
                setTableIsLoading={setIsLoading}
                disableApprove={currentStatus === "approved"}
                disableRefuse={currentStatus === "refused"}
                alwaysShowRefuseAfterApprove={currentStatus === "approved"}
              />
              <Button type="primary" onClick={() => navigate("/activity/" + obj.schedule_activity_id)}>
                Ir para atividade
              </Button>
            </div>
          ),
        }));

        setDataTable(table);
        setFilteredTotal(response.data.data.total);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const onChange = (key: string) => {
    setCurrentStatus(key);
    setCurrent(1);
    setSearch("");
  };

  return (
    <>
      <Layout pageTitle="Lista de Qualidade" breadcrumb="Home ⟶ Qualidade">
        <Row gutter={16} className="page-row">
          <Col xs={24} md={6} className="mb-2">
            <CollapseCard title="Total">
              {!!total ? (
                <Title id="activities-total" level={2} className="total-card">
                  {total}
                </Title>
              ) : null}
            </CollapseCard>
          </Col>
          <Col xs={24} md={18} className="mb-2">
            <CollapseCard title="Filtros">
              <Col span={24} className="mb-2 activity-filters">
                <div className="filter-quality-container">
                  <div className="filter-quality-drops">
                    <ReportSelect
                      label="Selecione o projeto"
                      handleChange={handleChangeProject}
                      values={projects?.map((project: any) => {
                        return { value: project.id, label: project.name };
                      })}
                    />
                    <DatePickerComponent setValue={setDate} label={"Selecione o período"} />
                  </div>
                  <div className="container-search">
                    <SearchInput
                      type="text"
                      handleChange={handleChangeSearch}
                      value={search}
                      handleSearch={handleSearch}
                      loading={isLoading}
                      placeholder="Busque por um nome"
                    />
                  </div>
                </div>
              </Col>
            </CollapseCard>
          </Col>
          <Col xs={24} md={24} className="mb-2">
            <CollapseCard title="Tabela de Qualidade">
              <Row>
                <Col span={24}>
                  <Tabs
                    defaultActiveKey="1"
                    type="card"
                    size="small"
                    items={tabs}
                    activeKey={currentStatus}
                    onChange={onChange}
                    className="fluit-tabs"
                  />

                  <Table
                    size="small"
                    dataSource={dataTable}
                    columns={currentStatus === "refused" ? refuseColumn : columns}
                    loading={isLoading}
                    pagination={{
                      position: ["bottomCenter"],
                      current: current,
                      pageSize: 30,
                      onChange(page, pageSize) {
                        setCurrent(page);
                      },
                      total: filteredTotal,
                      showSizeChanger: false,
                    }}
                  />
                </Col>
              </Row>
            </CollapseCard>
          </Col>
        </Row>
      </Layout>
    </>
  );
}

export default QualityPage;
