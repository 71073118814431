import React from "react";
import { List, Typography } from "antd";
import "../BatchAdvance.scss";

const { Title, Text } = Typography;

type UpdateBankProps = {
  selectedRows: any[];
};

const UpdateBank: React.FC<UpdateBankProps> = ({ selectedRows }) => {
  return (
    <div className="update-bank-container">
      <Title level={3}>Confirmação de avanço físico</Title>
      <Text className="update-text">Itens selecionados para atualização:</Text>

      <List
        className="selected-items-list"
        bordered
        dataSource={selectedRows}
        renderItem={(row: any) => (
          <>
            <List.Item className="selected-item">
              <div className="item-content">
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                  <Text>
                    <span className="text-label">Step:</span> <span className="text-label-row">{row.step}</span>
                    <span className="text-label">de:</span> <span className="text-label-row">{row.dbPercent}%</span>
                    <span className="text-label">para:</span> <span className="text-label-row">{row.excelPercent}%</span>
                  </Text>
                </div>
              </div>
            </List.Item>
          </>
        )}
      />
    </div>
  );
};

export default UpdateBank;
