import { useState, useEffect } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

type MeasurementData = {
  step: string; 
};

export const useFetchMeasurementData = (steps: string[]) => {
  const [data, setData] = useState<MeasurementData[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchMeasurementData = async () => {
      if (!steps || steps.length === 0) return; 
      setLoading(true);
      try {
        const response = await api.post("v1/measurement-rules/fetch-measurement-data", { steps });
        const fetchedData = response.data;

        // Reordenar os dados conforme a ordem original dos steps
        const orderedData = steps.map(step => 
            fetchedData.find((item: any) => item.step === step) || { step }
        );

      setData(orderedData);
      } catch (error: any) {
        toastAlert({
          type: "error",
          message: "Erro ao buscar os dados de medição. Tente novamente.",
          title: "Erro na Busca",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchMeasurementData();
  }, [steps]);

  return { data, loading };
};
