import { Table, Tabs } from "antd";
import "../measurePage.scss";

import { columns } from "../utils/columns";
import { measureTabs } from "../utils/tabs";
import { measurementColumns } from "./constants/measurementColumns";

function MeasureTabs({
  activeKey,
  measurementCont,
  onChange,
  dataSource,
  loading,
  current,
  setCurrent,
  total,
}: {
  activeKey: string | undefined;
  onChange: ((activeKey: string) => void) | undefined;
  measurementCont :Record<string, number>; 
  dataSource: any[] | undefined;
  loading: boolean;
  current: number;
  setCurrent: any;
  total: number;
}) {
  return (
    <>
      <Tabs defaultActiveKey="1" type="card" size="small" items={measureTabs(measurementCont)} activeKey={activeKey} onChange={onChange} className="fluit-tabs" />
      <Table
        size="small"
        dataSource={dataSource}
        columns={measurementColumns}
        scroll={{
          x: "800px",
        }}
        loading={loading}
        pagination={{
          position: ["bottomCenter"],
          current,
          pageSize: 30,
          onChange(page, pageSize) {
            setCurrent(page);
          },
          total,
          showSizeChanger: false,
        }}
      />
    </>
  );
}

export default MeasureTabs;
