import { TableColumnsType } from "antd";
import { Progress } from "antd";

export const validationEvidenceColumns: TableColumnsType<any> = [
  { title: "Título", dataIndex: "title", key: "title" },
  { title: "Descrição", dataIndex: "description", key: "description" },
  {
    title: "Requer Controle",
    dataIndex: "requires_control",
    key: "requires_control",
    render: (value) => (value ? "Sim" : "Não"),
  },
  {
    title: "Requer Qualidade",
    dataIndex: "requires_quality",
    key: "requires_quality",
    render: (value) => (value ? "Sim" : "Não"),
  },
  {
    title: "Obrigatório",
    dataIndex: "is_required",
    key: "is_required",
    render: (value) => (value ? "Sim" : "Não"),
  },
  {
    title: "Contrato",
    dataIndex: "contract_name",
    key: "contract_name",
  },
];
