
import { ReactNode } from "react";

export function InfoData({
  name,
  value,
}: {
  name: string;
  value?: ReactNode | string | number;
}) {
  return (
    <div
      className="mb-05"
      style={{ display: "flex", gap: 4, flexDirection: "row" }}
    >
      <b>{name}: </b>
      {value || "-"}
    </div>
  );
}
