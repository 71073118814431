import "../validationsPage.scss";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Col, Checkbox, Form, Select } from "antd";
import { useForm } from "react-hook-form";
import { ValidationEvidenceFormType, ValidationEvidenceSchema } from "../validation/ValidationEvidenceSchema";
import { FormProvider } from "../../../components/form/FormProvider";
import { Input } from "../../../components/form/fields";
import { useFetchContracts } from "../../../hooks/contracts/useFetchContracts";
import { usePostValidationEvidence } from "../../../hooks/validations/usePostValidationEvidence";
type Props = {
  handleClose: () => void;
};
export default function ValidationEvidenceForm({ handleClose }: Props) {
  const defaultValues: ValidationEvidenceFormType = {
    title: "",
    description: "",
    requires_control: false,
    requires_quality: false,
    dynamic_validation: "",
    contract_id: 0,
    is_required: false,
  };
  const methods = useForm<ValidationEvidenceFormType>({
    resolver: zodResolver(ValidationEvidenceSchema),
    defaultValues,
  });
  const [form] = Form.useForm();
  const { contracts } = useFetchContracts({
    current: 1,
  });
  const { postValidationEvidence } = usePostValidationEvidence();

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = methods;
  const requiresControl = watch("requires_control");
  const requiresQuality = watch("requires_quality");
  const isRequired = watch("is_required");
  const hasDinamycField = watch("dynamic_validation");
  const contract: any = +watch("contract_id") || null;
  const onSubmit = async (data: ValidationEvidenceFormType) => {
    await postValidationEvidence(data);
    handleClose();
  };

  return (
    <FormProvider methods={methods} form={form} onFinish={handleSubmit(onSubmit)}>
      <Col span={24} className="mt-2">
        <Input name="title" placeholder="Título" />
      </Col>

      <Col span={24} className="mt-2">
        <Input name="description" placeholder="Descrição" />
      </Col>
      <Col span={24} className="mt-2">
        {!!contracts && (
          <>
            {" "}
            <Select
              value={contract}
              placeholder="Selecione o contrato"
              style={{ width: "100%" }}
              onChange={(e) => {
                setValue("contract_id", +e);
              }}
              options={contracts?.map((item: any) => ({
                value: item.id,
                label: item.description,
              }))}
            />{" "}
            {errors.contract_id?.message && <span className="error_iput">Campo obrigatório</span>}
          </>
        )}
      </Col>

      <Col span={24} className="mt-2">
        <Checkbox name="requires_control" checked={requiresControl} onChange={(e) => setValue("requires_control", e.target.checked)}>
          Requer aprovação de controle
        </Checkbox>
      </Col>

      <Col span={24} className="mt-2">
        <Checkbox name="requires_quality" checked={requiresQuality} onChange={(e) => setValue("requires_quality", e.target.checked)}>
          Requer aprovação de qualidade
        </Checkbox>
      </Col>

      <div className="dynamic-field-container">
        <Col span={8} className="mt-2">
          <Input name="dynamic_validation" placeholder="Campo de validação" />
        </Col>
        <Col className="mt-2">
          <Checkbox
            name="is_required"
            checked={isRequired}
            onChange={(e) => setValue("is_required", e.target.checked)}
            disabled={!!hasDinamycField ? false : true}
          >
            Campo de validação obrigatório
          </Checkbox>
        </Col>
      </div>

      <div className="mt-2 container-button-footer">
        <Button type="primary" htmlType="submit" loading={false}>
          Enviar
        </Button>
      </div>
    </FormProvider>
  );
}
