import { FaCheck } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import formatDate from "../../../utils/formatDate";
import { TextCropToltip } from "../../TextCropToltip";
import { formatDateISO } from "../../../utils/formatDateISO";

export const columns = [
  {
    title: "Nome",
    dataIndex: "show_name",
    render: (name: any) => (
      <>
        <TextCropToltip text={name} max_quantity={20} />
      </>
    ),
  },

  {
    title: "Data de criação",
    dataIndex: "created_at",
    render: (name: any) => <>{name ? formatDateISO(name) : "-"}</>,
  },
  {
    title: "Data de execução",
    dataIndex: "activity_date",
    render: (name: any) => <>{name ? formatDate(name) : "-"}</>,
  },
  {
    title: "Etapa",
    dataIndex: "etapa",
  },
  {
    title: "Step de medição",
    dataIndex: "step",
    key: "step",
    render: (step: any) => <TextCropToltip text={step} max_quantity={35} />,
  },
  {
    title: "Qualidade",
    dataIndex: "quality_approved_date",
    render: (name: any, record: any) => (
      <>
        {record.requires_quality ? (
          <div className={"file-table-list-date " + (!!record?.quality_approved_by ? "positive-text-color" : "negative-text-color")}>
            {record?.quality_approved_by || record?.quality_refused_by ? (
              <>
                {!!record.quality_approved_by ? <FaCheck size={18} /> : <IoClose size={20} />}
                {new Date(
                  (record?.quality_approved_by ? record?.quality_approved_date : record?.quality_refused_date) + "T00:00:00"
                ).toLocaleDateString("pt-BR")}
              </>
            ) : (
              ""
            )}
          </div>
        ) : (
          <>-</>
        )}
      </>
    ),
  },
  {
    title: "Controle",
    dataIndex: "control_approved_date",
    render: (name: any, record: any) => (
      <>
        {record.requires_control ? (
          <div className={"file-table-list-date " + (!!record?.control_approved_by ? "positive-text-color" : "negative-text-color")}>
            {record?.control_approved_by || record?.control_refused_by ? (
              <>
                {!!record?.control_approved_by ? <FaCheck size={18} /> : <IoClose size={20} />}
                {new Date(
                  (record?.control_approved_by ? record?.control_approved_date : record?.control_refused_date) + "T00:00:00"
                ).toLocaleDateString("pt-BR")}
              </>
            ) : (
              ""
            )}
          </div>
        ) : (
          <>-</>
        )}
      </>
    ),
  },
  { dataIndex: "actions", width: 90 },
];
