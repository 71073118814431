import { useCallback, useEffect, useState } from "react";
import api from "../../config/axios";
import { CommentActivity } from "../../models/CommentActivity";

type Props = {
  activity_id?: string | number;
  refresh?: Date;
};

export const useFetchComments = ({ activity_id, refresh }: Props) => {
  const [comments, setComments] = useState<CommentActivity[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const returnComments = useCallback(() => {
    if (activity_id) {
      setIsFetching(true);
      api
        .get(`/v1/activity/${activity_id}/comment/all`)
        .then((response) => {
          setComments(response.data);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setIsFetching(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity_id, refresh]);

  useEffect(() => {
    returnComments();
  }, [returnComments]);

  return {
    comments,
    isFetching,
    error,
  };
};
