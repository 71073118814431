import { Radio, RadioChangeEvent, Space } from "antd";

import returnStatusMeasurementPartials from "../../utils/returnStatusMeasurementPartials";
import useMaxPercentage from "./utils/useMaxPercentage";

type Props = {
  onChange: (e: RadioChangeEvent) => void;
  value: string | number;
  options: any[];
  direction?: "vertical" | "horizontal";
  executedValue?: number;
  className?: string;
  partials_measurements: any[];
};
export default function RadioButtons({
  onChange,
  value,
  options,
  direction = "vertical",
  executedValue = -1,
  className,
  partials_measurements,
}: Props) {
  const maxPercentageCallback = useMaxPercentage();
  const maxPercentage = maxPercentageCallback(partials_measurements);
  const executed = +executedValue > 0 ? +executedValue : -1;

  return (
    <Radio.Group onChange={onChange} value={value}>
      <Space direction={direction}>
        {options.map((option: any) => {
          const hasMeasure = partials_measurements?.find((item) => +item.percentage === +option.value);

          return (
            <div>
              <Radio
                value={option.value}
                disabled={
                  (+option.value < +executed && +hasMeasure?.status_id !== 3) ||
                  (+option.value <= maxPercentage && hasMeasure?.measurement_status !== 3) ||
                  (+hasMeasure?.status_id === 1 && hasMeasure?.measurement_status !== 3) ||
                  +hasMeasure?.status_id === 5
                }
                className={className}
              >
                {option.name}
              </Radio>
              {!!hasMeasure?.status_id && hasMeasure?.status_id !== 3 && returnStatusMeasurementPartials(+hasMeasure?.status_id)}
              {!!hasMeasure?.status_id && returnStatusMeasurementPartials(+hasMeasure?.measurement_status)}
            </div>
          );
        })}
      </Space>
    </Radio.Group>
  );
}
