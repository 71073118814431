import {
  InputNumber as AntdInputNumber,
  Form,
  InputNumberProps
} from "antd";
import { useFormContext } from "react-hook-form";

type Props = InputNumberProps & {
  label: string;
  name: string;
  placeholder: string;
};

export default function InputNumber({
  label,
  name,
  placeholder,
  onChange,
  ...others
}: Props) {
  const {
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext();

  const values = watch();

  return (
    <Form.Item
      name={name}
      label={label}
      help={errors[name] ? (errors[name]?.message as string) : undefined}
      validateStatus={!!errors[name] ? "error" : undefined}
    >
      <AntdInputNumber
        placeholder={placeholder}
        value={values[name]}
        {...others}
        onChange={(event) => {
          onChange ? onChange(event) : setValue(name, event?.toString());
          errors[name] && trigger(name);
        }}
      />
    </Form.Item>
  );
}
