import { useState } from "react";
import api from "../../config/axios";
import { UserDataFormType } from "../../schemas/userData";
import { toastAlert } from "../../utils/toastAlert";

export const useUpdateProfile = () => {
  const [isFetching, setIsFetching] = useState(false);

  const handleSubmitProfile = async (values: UserDataFormType) => {
    if (
      values.name === localStorage.getItem("user_name") &&
      values.email === localStorage.getItem("user_email")
    ) {
      toastAlert({
        type: "warning",
        message: `Altere os valores para poder salvar.`,
        title: "Atenção",
      });
      return;
    }

    setIsFetching(true);

    await api
      .put("/v1/user/change-profile", {
        name: values.name,
        email: values.email,
      })
      .then((r) => {
        localStorage.setItem("user_name", values.name);
        localStorage.setItem("user_email", values.email);
        window.location.reload();
      })
      .catch((err) => {
        toastAlert({
          type: "error",
          message:
            err?.response?.data?.message ||
            `Verifique sua conexão com a internet e tente novamente.`,
          title: "Erro ao atualizar dados",
        });
      })
      .finally(() => setIsFetching(false));
  };

  return { handleSubmitProfile, isFetching };
};
