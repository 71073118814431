import { Modal, Spin } from "antd";
import { useEffect, useState } from "react";
import api from "../../services/Api";
import { DataType } from "../../types/wp";
import { WpAdd } from "../WpAdd";
import { WpEdit } from "../WpEdit";
import { WpView } from "../WpView";
import "./modalAddOrEdit-styles.scss";

type Props = {
  isModalOpen: boolean;
  handleCancel: () => void;
  type: "create" | "edit" | "view";
  selectedItems?: DataType[];
  selectedItem?: { id: number; type: string } | undefined;
  afterSubmit?: () => void;
};

export default function ModalAddOrEditWp({
  isModalOpen,
  handleCancel,
  type,
  selectedItems,
  selectedItem,
  afterSubmit,
}: Props) {
  const [roles, setRoles] = useState<string[]>([]);
  const [hideModal, setHideModal] = useState(false);
  const isSubOwner = roles.length > 0 && roles[0].includes('Subowner')

  const data = {
    create: {
      title: "Criar",
      component: (
        <WpAdd
          handleCancel={handleCancel}
          afterSubmit={afterSubmit}
          setHideMainModal={(newValue) => setHideModal(newValue)}
          isSubOwner={isSubOwner}
        />
      ),
    },
    edit: {
      title: "Editar",
      component: (
        <WpEdit
          handleCancel={handleCancel}
          selectedItems={selectedItems}
          afterSubmit={afterSubmit}
          setHideMainModal={(newValue) => setHideModal(newValue)}
          isSubOwner={isSubOwner}
        />
      ),
    },
    view: {
      title: `Aprovar ${
        selectedItem?.type === "WP_EDIT" ? "edição de" : "criação de"
      }`,
      component: (
        <WpView
          handleCancel={handleCancel}
          selectedItem={selectedItem}
          afterSubmit={afterSubmit}
        />
      ),
    },
  };

  useEffect(() => {
    api.get(`/v1/user/roles`).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        setRoles(data.roles);
      }
    });
  }, []);

  return (
    <Modal
      title={`${data[type].title} WP`}
      closeIcon={true}
      width={900}
      footer={null}
      open={isModalOpen}
      onCancel={handleCancel}
      rootClassName={`wp-add-edit-modal${hideModal ? " hide-wp-modal" : ""}`}
    >
      <div className={type === "view" ? "" : "container-WP-modal"}>
        {roles.length > 0 ? (
          data[type].component
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              padding: 24,
            }}
          >
            <Spin />
          </div>
        )}
      </div>
    </Modal>
  );
}
