import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import { Activity } from "../../../models/Activity";
import "../wpView.scss";
import { InfoData } from "./InfoData";

type Props = { wpRequisition: any; selectedItemType: string };

export function Activities({ wpRequisition, selectedItemType }: Props) {
  const unchangedActivities = () => {
    // ids que aparecem duas vezes, em atividades para criar e que já existem
    let sameIds: any = {};
    const activities = wpRequisition?.activities || [];
    const activitiesToCreate = (wpRequisition?.activitiesToCreate || []).map(
      (item: Activity) => item.id
    );

    return activities.filter((item: Activity) => {
      if (activitiesToCreate.includes(item.id)) {
        if (sameIds[item.id]) {
          return true;
        } else {
          sameIds[item.id] = true;
          return false;
        }
      } else {
        return true;
      }
    });
  };

  return (
    <InfoData
      name="Atividades"
      value={
        <span style={{ display: "flex", flexDirection: "column" }}>
          {(wpRequisition?.content?.newActivitiesToCreate || [])
            .concat(wpRequisition?.activitiesToCreate || [])
            .map((activity: Activity) => (
              <span
                className={
                  selectedItemType !== "WP_CREATE" ? "wp-view-added" : ""
                }
              >
                {selectedItemType !== "WP_CREATE" ? <CheckOutlined /> : null}
                {activity?.name}
              </span>
            ))}
          {wpRequisition?.activitiesToDelete.map((activity: Activity) => (
            <div className="wp-view-removed">
              <CloseOutlined /> <s>{activity?.name}</s>
            </div>
          ))}
          {unchangedActivities().map((activity: Activity) => (
            <span>{activity?.name}</span>
          ))}
        </span>
      }
    />
  );
}
