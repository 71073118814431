import "./style.scss";

import  { useMemo, useState } from 'react';
import {Tooltip } from 'antd';

type Props = {
  text: string;
  max_quantity: number;
};

export function TextCropToltip({text,  max_quantity }: Props) {
     
  const [arrow, setArrow] = useState('Show');
  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }

    if (arrow === 'Show') {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  return (<>
  { !!text &&  <Tooltip placement="topRight" title={text} arrow={mergedArrow}>            
      {text.slice(0, max_quantity)}{text.length > max_quantity ? '...' : ''}
    </Tooltip>
  }
  </>      );
}
