import { z } from "zod";

export const ContractWithWpsSchema = z.object({
  description: z.string().min(1, "Description is required"),
  amount: z.union([z.number(), z.string()]),
  hired_id: z.union([z.number(), z.string()]),
  wp_type: z.string(),
  wps_selected: z.array(z.number()).nonempty("WPS selection is required"),
  active: z.boolean(),
});
