import "./validationSelect.scss";
import { ValidationEvidence } from "../../../../../../models/ValidationEvidence";
import { FaAngleRight } from "react-icons/fa";

type Props = {
  validations?: ValidationEvidence[];
  setValidation: (validation_id: ValidationEvidence) => void;
};

export default function ValidationSelect({ validations, setValidation }: Props) {
  return (
    <div className="contrainer-selects">
      {validations?.map((item) => (
        <div
          className="container-select-validation"
          onClick={() => {
            setValidation(item);
          }}
        >
          <div className="container-text-validation">
            <span className="title-validation">{item.title}</span>
            <span className="description-validation">{item.description}</span>
          </div>
          <FaAngleRight size={20} />
        </div>
      ))}
      <div
        className="container-select-validation"
        onClick={() => {
          setValidation({ id: null } as any);
        }}
      >
        <div className="container-text-validation">
          <span className="title-validation">Sem validações</span>
        </div>
        <FaAngleRight size={20} />
      </div>
    </div>
  );
}
