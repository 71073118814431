import AppLink from "../AppLink";
import "./errorCard.scss";
type Props = {
  statusCode?: number;
};

type Error = {
  title: string;
  message: string;
  svgIcon: string;
  href: string;
  label: string;
};

export default function ErrorCard({ statusCode = 500 }: Props) {
  const variants: Record<number, Error> = {
    500: {
      title: "Ops! Ocorreu um erro",
      message:
        "Fique  tranquilo, já estamos cientes e trabalhando na correção.",
      svgIcon: "",
      href: "/home",
      label: "Voltar para tela inicial",
    },
    429: {
      title: "Ops! Ocorreu um erro",
      message: "Parece que você fez requisições demais muito rápido.",
      svgIcon: "",
      href: "/home",
      label: "Voltar para tela inicial",
    },
    404: {
      title: "Ops! Ocorreu um erro",
      message: "Parece que essa página não foi encontrada.",
      svgIcon: "",
      href: "/home",
      label: "Voltar para tela inicial",
    },
    403: {
      title: "Ops! Ocorreu um erro.",
      message: "Parece que você não tem acesso a esse conteúdo.",
      svgIcon: "",
      href: "/home",
      label: "Voltar para tela inicial",
    },
    401: {
      title: "Ops! Esta página não está disponível",
      message: "Você precisa estar autenticado para ter acesso a esse conteúdo",
      svgIcon: "",
      href: "/",
      label: "Voltar para tela inicial",
    },
    400: {
      title: "Ops! Estamos trabalhando nesse conteúdo.",
      message: "Fique  tranquilo, em breve iremos disponibilizá-lo.",
      svgIcon: "",
      href: "/home",
      label: "Voltar para tela inicial",
    },
  };

  const content = variants[statusCode] || variants[500];

  return (
    <div className="containerErro">
      <div className="containerErrorImage">
        <img src="/robotError.svg" alt="Robo" className="imageError" />
      </div>
      <h1>{content.title}</h1>
      <span className="errorMessage">{content.message}</span>
      <AppLink href={content.href}>{content.label}</AppLink>
    </div>
  );
}
