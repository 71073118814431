import { Col, Row, Typography } from "antd";
import { AiOutlineDatabase } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import CollapseCard from "../../components/Template/Card";
import Layout from "../../components/Template/Layout";
import "./dashboardPage.scss";

const { Text } = Typography;

function DashboardPage() {
  const navigate = useNavigate();

  return (
    <>
      <Layout>
        <CollapseCard size="small" title="Dashboard" extra={""}>
          <Row align={"middle"} className="mt-4 mb-6">
            <Col
              className="dashboard-menu"
              onClick={() => navigate("/projects")}
            >
              <Col className="text-center">
                <AiOutlineDatabase size={"4em"} />
              </Col>
              <Col className="text-center">
                <Text>Projetos</Text>
              </Col>
            </Col>
          </Row>
        </CollapseCard>
      </Layout>
    </>
  );
}

export default DashboardPage;
