import React from "react";
import "./appLink.scss";
type AppLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

function AppLink({ children, ...props }: AppLinkProps) {
  return (
    <div className="appLinkContainer">
      <a className="appLink" href={props.href}>
        {children}
      </a>
      <div className="underlineLink"></div>
    </div>
  );
}
export default AppLink;
