import { useState } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

export const useUploadUserPhoto = () => {
  const [isUploadingPhoto, setIsUploading] = useState(false);

  const handleUploadPhoto = async (file: any) => {
    setIsUploading(true);
    await api
      .post(
        "/v1/user/upload-photo",
        { file },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        localStorage.setItem("user_photo", response.data);
        window.location.reload();
      })
      .catch((error) => {
        toastAlert({
          type: "error",
          message: `Verifique sua conexão com a internet e tente novamente.`,
          title: "Não foi possível fazer o upload",
        });
      });

    setIsUploading(false);
  };

  return { handleUploadPhoto, isUploadingPhoto };
};
