import { useCallback } from "react";
import api from "../../config/axios";
import dayjs from "dayjs";

type PropsExportEvidence = {
  bulletin_id: any;
};

export const useExportReportEvidence = ({ bulletin_id }: PropsExportEvidence) => {
  const exportReportEvidence = useCallback(async () => {
    try {
      const response = await api.get(`/v1/measurement-bulletins/export-bm/${bulletin_id}`, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/zip",
        },
      });

      const blob = new Blob([response.data], {
        type: "application/zip",
      });

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `Relatorio-de-evidencias-bm-${bulletin_id}-download-em-${dayjs().format("DD-MM-YYYY-HH-mm")}.zip`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting evidence report:", error);
    }
  }, [bulletin_id]);

  return exportReportEvidence;
};
