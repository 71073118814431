import { useCallback } from "react";
import Report from "../../../models/Report";

export function CalculateTotalApprovedReport(reports: any[]) {
  const total = useCallback(() => {
    let totalCost = 0;
    reports.forEach((report: Report) => {
      if (!!report.measure_approved_by) {
        if (report.cost) totalCost += +report.cost;
      }
    });
    return totalCost;
  }, [reports]);

  return total();
}
