/* eslint-disable react-hooks/exhaustive-deps */
import { PlusOutlined } from "@ant-design/icons";
import { Upload, UploadFile, UploadProps } from "antd";
import FormItem from "antd/es/form/FormItem";
import { UploadChangeParam } from "antd/es/upload";
import { useEffect, useState } from "react";
import { FieldErrors, FieldValues } from "react-hook-form";
import { useUploadModelWp } from "../../../hooks/wps/useUploadModelWp";

type Props = {
  type: string;
  index?: number;
  formData: {
    values: any;
    errors: FieldErrors<FieldValues> | undefined;
    setValue: any;
    name?: string;
    help?: (value: any) => string | undefined;
  };
  wpData: any;
};

type FileListItem = UploadFile & { id?: number };

export function Models({ type, index, formData, wpData }: Props) {
  const { values, setValue, name } = formData;
  const isEdit = type === "edit" && index !== undefined;

  const [fileList, setFileList] = useState<FileListItem[]>([]);

  const { handleUploadModel, model } = useUploadModelWp({
    afterSubmit: (fileList) => setFileList(fileList),
  });

  useEffect(() => {
    if (isEdit && !!wpData) {
      const models: any[] = wpData.models_files;
      setValue(
        "model",
        models?.map((model) => model.upload_id)
      );
      setValue("description", wpData?.description);
      setFileList(
        models?.map((model) => ({
          uid: model.upload_id,
          name: model.show_name,
          status: "done",
          url: model.url,
          id: model.upload_id,
        }))
      );
    }
  }, [JSON.stringify(wpData)]);

  useEffect(() => {
    if (!!model && !values?.model?.includes(model)) {
      if (isEdit) {
        setValue("modelsToCreate", [...values.modelsToCreate, model]);
      } else {
        const models = [...values.model, model];
        setValue("model", models);
      }
    }
  }, [model]);

  const handleChangeUpload = async (e: UploadChangeParam<UploadFile<any>>) => {
    const fileIsRemoved = e?.file?.status;
    if (!fileIsRemoved) {
      await handleUploadModel({ e });
    }
  };

  const handleRemoveFile = (e: any) => {
    const isLocallySaved = !e.url;
    if (!isLocallySaved) {
      setValue("modelsToDelete", [...values.modelsToDelete, e.uid]);
    } else {
      isEdit &&
        setValue(
          "modelsToCreate",
          values.modelsToCreate.filter((model: any) => model !== e.id)
        );
    }

    setValue(
      "model",
      values.model.filter((model: any) => model !== e.id)
    );
    setFileList(
      fileList.filter((file) => JSON.stringify(file) !== JSON.stringify(e))
    );
  };

  return (
    <FormItem label="Modelo" name={`${name || ""}models`}>
      <div className="upload-container">
        <Upload
          listType="picture-card"
          {...props}
          fileList={fileList}
          onChange={handleChangeUpload}
          onRemove={handleRemoveFile}
          className="no-preview"
        >
          {fileList.length >= 10 ? null : uploadButton}
        </Upload>
      </div>
    </FormItem>
  );
}

const props: UploadProps = {
  beforeUpload: (file) => {
    return false;
  },
};

const uploadButton = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);
