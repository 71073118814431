import { Button, Input } from "antd";
import React from "react";
import { toastAlert } from "../../utils/toastAlert";
import CollapseCard from "../Template/Card";
import "./comment.scss";

const { TextArea } = Input;

function CommentCard({
  commentList,
  inputComment,
  setInputComment,
  handleSendComment,
  uploading,
  canCollapse,
}: {
  commentList: any[] | undefined;
  inputComment: string | undefined;
  setInputComment: React.Dispatch<React.SetStateAction<string>>;
  handleSendComment: VoidFunction;
  uploading: boolean;
  canCollapse?: boolean;
}) {
  return (
    <CollapseCard canCollapse={canCollapse} title="Comentários">
      {commentList && commentList.length > 0 ? (
        <div className="comment-list">
          {commentList.map((comment: any) => (
            <div key={comment.message} className="comment">
              <div className="comment_data">
                <span>{comment.user?.name}</span>
                <span>&#xB7;</span>
                <span>
                  {`${new Date(comment.created_at).toLocaleDateString("pt-BR")} ${new Date(comment.created_at).toLocaleTimeString("pt-BR", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}`}
                </span>
              </div>
              <span className="comment_message">{comment.message}</span>
            </div>
          ))}
        </div>
      ) : (
        <span className="no-data-text">Atividade não tem comentários</span>
      )}
      <TextArea
        placeholder="Escreva seu comentário"
        rows={3}
        value={inputComment}
        onChange={(event) => {
          setInputComment(event.target.value);
        }}
        style={{ margin: "8px 0" }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <Button
          onClick={() =>
            inputComment === ""
              ? toastAlert({
                  type: "warning",
                  message: `Digite uma mensagem para fazer comentário`,
                  title: "Espere!",
                })
              : handleSendComment()
          }
          className="comment-submit"
          disabled={uploading}
          loading={uploading}
          type="primary"
        >
          Fazer comentário
        </Button>
      </div>
    </CollapseCard>
  );
}

export default CommentCard;
