import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Form } from "antd";
import { useForm } from "react-hook-form";
import { ActivityFormType, ActivitySchema, CreatedActivity } from "../../schemas/activity";
import { FormProvider } from "../form/FormProvider";
import { Input, InputNumber, Select, Switch, TextArea } from "../form/fields";
import "./WpCreateActivity.scss";
import { SelectCwp } from "./selectCwp";
import { SelectType } from "./selectType";

type Props = {
  handleCancel: () => void;
  handleSubmit: (formData: CreatedActivity) => void;
  wpFormData: {
    id: number | undefined;
    subDiscipline: number | undefined;
    projectInformationId: string;
  };
};

export function WpCreateActivity({ handleCancel, handleSubmit, wpFormData }: Props) {
  const [form] = Form.useForm();
  const methods = useForm<ActivityFormType>({
    resolver: zodResolver(ActivitySchema),
    defaultValues: {
      subDiscipline: wpFormData.subDiscipline,
      projectInformationId: Number(wpFormData.projectInformationId),
      createDefault: false,
    },
  });

  const {
    watch,
    trigger,
    setValue,
    handleSubmit: handleFormSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const values = watch();

  const handleCreateActivity = (values: ActivityFormType) => {
    handleSubmit({ ...values, uniqueName: JSON.stringify(values) });
  };

  return (
    <FormProvider
      methods={methods}
      form={form}
      labelCol={{ xs: 7 }}
      className="form-create-container-WP"
      onFinish={handleFormSubmit(handleCreateActivity)}
    >
      <Input name="name" label="Nome" placeholder="Digite o nome" />

      <Select name="type" label="Tipo">
        <SelectType
          value={values.type}
          setValue={(value: string) => {
            setValue("type", value);
            trigger("type");
          }}
        />
      </Select>

      <Input name="code" label="Código" placeholder="Digite o nome" />

      <InputNumber name="estimateHours" label="Horas estimadas" placeholder="Digite a quantidade de horas" style={{ width: "100%" }} />

      <TextArea name="description" label="Descrição" placeholder="Digite a descrição" />

      {values.type === "IWP" ? (
        <Select name="cwpId" label="CWP do IWP">
          <SelectCwp
            wpId={wpFormData.id || 0}
            value={values.type}
            setValue={(value: string) => {
              setValue("cwpId", value);
              trigger("cwpId");
            }}
          />
        </Select>
      ) : null}

      <Switch label="Criar atividade padrão" name="createDefault" />

      <div className="container-buttons-WP-add">
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button htmlType="submit" loading={isSubmitting} disabled={isSubmitting} type="primary">
          Criar Atividade
        </Button>
      </div>
    </FormProvider>
  );
}
