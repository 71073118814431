import { Form } from "antd";
import AntdTextArea, {
  TextAreaProps,
  TextAreaRef,
} from "antd/es/input/TextArea";
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  useFormContext,
} from "react-hook-form";

type Props = TextAreaProps &
  React.RefAttributes<TextAreaRef> & {
    label: string;
    name: string;
    placeholder: string;
    helpClassName?: string;
    error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
    help?: React.ReactNode;
  };

export default function TextArea({
  label,
  name,
  placeholder,
  value,
  error,
  help,
  ...others
}: Props) {
  const {
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext();

  const values = watch();

  const formValue = value || values[name];
  const formError = error || errors[name];
  const formHelp = help
    ? help
    : formError
    ? (formError?.message as string)
    : undefined;

  return (
    <Form.Item
      name={name}
      label={label}
      help={formHelp}
      validateStatus={!!formError ? "error" : undefined}
    >
      <AntdTextArea
        placeholder={placeholder}
        value={formValue}
        defaultValue={formValue}
        onChange={(event) => {
          setValue(name, event.target.value);
          formError && trigger(name);
        }}
        {...others}
      />
    </Form.Item>
  );
}
