import { Button, Input, Modal } from "antd";
import { useState } from "react";

const { TextArea } = Input;

export default function RefuseModal({
  isModalOpen,
  handleClose,

  handleRefuse,
}: {
  isModalOpen: boolean;
  handleClose: VoidFunction;

  handleRefuse: (message: string) => void;
}) {
  const [message, setMessage] = useState("");

  const handleCancel = () => {
    handleClose();
  };

  return (
    <Modal
      title="Motivo da reprovação?"
      open={isModalOpen}
      onOk={() => {
        handleRefuse(message);
      }}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={!message}
          danger
          onClick={() => {
            handleRefuse(message);
            handleClose();
          }}
        >
          Reprovar
        </Button>,
      ]}
    >
      <TextArea
        placeholder="Escreva o motivo"
        rows={3}
        onChange={(event) => {
          setMessage(event.target.value);
        }}
        style={{ marginTop: 8 }}
      />
    </Modal>
  );
}
