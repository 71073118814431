export const createContractData = (values: any, isPopup: boolean, isHired: boolean, hasWps: boolean, activity_id?: string) => {
  if (isHired && hasWps) {
    return {
      description: values.description,
      active: values.active === undefined ? true : values.active,
      hired_name: values.name,
      email: values.email,
      hired_description: values.hired_description,
      password: values.password,
      phone: values.phone,
      status: "Assinado",
      wp_type: values.wp_type,
      activity_id: isPopup ? values.activity_id : activity_id,
      wps_selected: values.wps_selected || [],
      ...(isPopup && { amount: values.amount }),
    };
  } else if (isHired) {
    return {
      description: values.description,
      active: values.active === undefined ? true : values.active,
      hired_name: values.name,
      email: values.email,
      hired_description: values.hired_description,
      password: values.password,
      phone: values.phone,
      status: "Assinado",
      activity_id: isPopup ? values.activity_id : activity_id,
      ...(isPopup && { amount: values.amount }),
    };
  } else if (hasWps) {
    return {
      description: values.description,
      active: values.active === undefined ? true : values.active,
      hired_id: values.hired_id,
      status: "Assinado",
      activity_id: isPopup ? values.activity_id : activity_id,
      wp_type: values.wp_type,
      wps_selected: values.wps_selected || [],
      ...(isPopup && { amount: values.amount }),
    };
  } else {
    return {
      description: values.description,
      active: values.active === undefined ? true : values.active,
      hired_id: values.hired_id,
      status: "Assinado",
      activity_id: isPopup ? values.activity_id : activity_id,
      ...(isPopup && { amount: values.amount }),
    };
  }
};
