import { Button, Col, Form, message } from "antd";
import api from "../../../services/Api";

import { useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { FormProvider } from "../../../components/form/FormProvider";
import { Input } from "../../../components/form/fields";
import { ForgotPasswordFormType, ForgotPasswordSchema } from "../../../schemas/forgotPassword";
import "../login.scss";

export function ForgotPasswordForm() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm<ForgotPasswordFormType>({
    resolver: zodResolver(ForgotPasswordSchema),
  });

  const { handleSubmit } = methods;

  const handleForgotPasswordSubmit = (values: ForgotPasswordFormType) => {
    setIsLoading(true);

    api
      .post(
        "/v1/forgot-password", //ver como ler do .env
        { email: values.email }
      )
      .then((response) => {
        if (response.status === 200) {
          message.success(response.data, 6);
        }
      })
      .catch((error) => {
        if (error?.response && error?.response?.status === 400) {
          message.error(error?.response?.data || "Erro ao enviar e-mail, tente novamente mais tarde", 5);
        } else {
          message.error(error?.response?.data?.message || "Erro ao enviar e-mail, tente novamente mais tarde", 5);
        }
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <FormProvider methods={methods} form={form} onFinish={handleSubmit(handleForgotPasswordSubmit)}>
      <Col span={24}>
        <Input id="forgot-password-email" name="email" type="email" placeholder="E-mail" />
      </Col>

      <Col span={24} className="mt-2">
        <Button id="forgot-password-submit" className="submit pt-1 pb-1" htmlType="submit" loading={isLoading}>
          Recuperar senha
        </Button>
      </Col>
    </FormProvider>
  );
}
