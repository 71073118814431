import { useCallback, useEffect, useState } from "react";
import api from "../../config/axios";
import { Activity } from "../../models/Activity";

type Props = {
  sub_discipline_id: number;
  refresh: null | Date;
};

export const useFetchWpsDefaultActivities = ({
  sub_discipline_id,
  refresh,
}: Props) => {
  const [defaultActivities, setDefaultActivities] = useState<Activity[]>([]);
  const [isFetchingDefaultActivity, setIsFetching] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const returnWpsActivities = useCallback(() => {
    if (sub_discipline_id > 0) {
      setIsFetching(true);
      api
        .get(`/v1/wps/activities/${sub_discipline_id}`)
        .then((response) => {
          setDefaultActivities(response.data);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setIsFetching(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sub_discipline_id, refresh]);

  useEffect(() => {
    returnWpsActivities();
  }, [returnWpsActivities]);

  return {
    defaultActivities,
    isFetchingDefaultActivity,
    error,
  };
};
