import { useCallback, useEffect, useState } from "react";
import api from "../../config/axios";

type Props = {
  type: string;
  current?: number;
};

export const useFetchWpsByType = ({ type, current }: Props) => {
  const [wps, setWps] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [total, setTotal] = useState(0);

  const returnWps = useCallback(() => {
    if (!!type) {
      setIsFetching(true);
      api
        .get(`/v1/wps/type/${type}?page=${current}`)
        .then((response) => {
          const table = response.data.data.map((obj: any) => ({
            ...obj,
            key: obj.id,
          }));
          setWps(table);
          setTotal(response.data.total);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setIsFetching(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, current]);

  useEffect(() => {
    returnWps();
  }, [returnWps]);

  return {
    wps,
    isFetching,
    error,
    total,
  };
};
