import { Row, Timeline } from "antd";
import CollapseCard from "../Template/Card";

type Props = {
  title: string;
  history?: any[] | undefined;
  isPopup?: boolean;
};
export function Historic({ history, isPopup, title }: Props) {
  return (
    <CollapseCard canCollapse title={title} style={{ marginBottom: isPopup ? 0 : 20 }}>
      <Row
        style={{
          display: "flex",
          gap: 8,
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Timeline
          mode="left"
          reverse
          items={history?.map((item) => ({
            label: `${new Date(item.created_at).toLocaleDateString("pt-BR")} ${new Date(item.created_at).toLocaleTimeString("pt-BR", {
              hour: "2-digit",
              minute: "2-digit",
            })}`,
            children: item.message,
          }))}
        />
      </Row>
    </CollapseCard>
  );
}
