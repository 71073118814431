import { TableColumnsType } from "antd";
import { TextCropToltip } from "../../../components/TextCropToltip";
interface DataType {
  id: number;
  key: React.Key;
  name: string;
  description: string;
}

export const columnsRefused: TableColumnsType<DataType> = [
  {
    title: "Descrição",
    dataIndex: "description",
    key: "description",
    render: (name, record) => <div id={`measures-description-${record.key}`}>{name}</div>,
  },
  {
    title: "Descrição da atividade",
    dataIndex: "joined_wp_description",
    key: "joined_wp_description",
    render: (description) => <TextCropToltip text={description} max_quantity={35} />,
  },
  {
    title: "Atividade",
    dataIndex: "activity_code",
    key: "description",
    render: (name, record) => <div id={`measures-description-${record.key}`}>{name}</div>,
  },
  { title: "Step de medição", dataIndex: "percentual_name" },
  { title: "Etapa", dataIndex: "etapa" },
  {
    title: "Razão da recusa",
    dataIndex: "reason_for_refusal",
    key: "reason_for_refusal",
  },
  { title: "", dataIndex: "actions", key: "actions" },
];

export const columnsDefault: TableColumnsType<DataType> = [
  {
    title: "Descrição",
    dataIndex: "description",
    key: "description",
    render: (name, record) => <div id={`measures-description-${record.key}`}>{name}</div>,
  },
  {
    title: "Descrição da atividade",
    dataIndex: "joined_wp_description",
    key: "joined_wp_description",
    render: (description) => <TextCropToltip text={description} max_quantity={35} />,
  },
  { title: "Step de medição", dataIndex: "percentual_name" },
  { title: "Etapa", dataIndex: "etapa" },
  {
    title: "Atividade",
    dataIndex: "activity_code",
    key: "description",
    render: (name, record) => <div id={`measures-description-${record.key}`}>{name}</div>,
  },

  { title: "", dataIndex: "actions", key: "actions" },
];
