import { Progress, TableColumnsType } from "antd";
import formatDate from "../../../utils/formatDate";
import { formatCurrency } from "../../../utils/formatCurrency";
import { TextCropToltip } from "../../../components/TextCropToltip";

interface MeasurementBulletin {
  id: number;
  name: string;
  observation: string;
  start_date: string;
  end_date: string;
  contract_description: string;
  consumption_percentage: number | any;
  total_activities_value: number | any;
  // Outros campos...
}

export const columns: TableColumnsType<MeasurementBulletin> = [
  {
    title: "Nome",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Observação",
    dataIndex: "observation",
    key: "observation",
  },
  {
    title: "Data inicial",
    dataIndex: "start_date",
    key: "start_date",
    render: (date) => (date ? <span>{formatDate(date)}</span> : ""),
  },
  {
    title: "Contrato",
    dataIndex: "contract_description",
    key: "contract_description",
    render: (name) => (
      <div className="mr-3">
        <TextCropToltip max_quantity={30} text={name} />
      </div>
    ),
  },
  {
    title: "Data final",
    dataIndex: "end_date",
    key: "end_date",
    render: (date) => (date ? <span>{formatDate(date)}</span> : ""),
  },
  {
    title: "Consumo",
    dataIndex: "consumption_percentage",
    key: "consumption_percentage",
    render: (percentage) => (
      <div className="pr-7">
        <Progress percent={percentage != null ? percentage : 0} />
      </div>
    ),
    width: 280,
  },
  {
    title: "Total consumido",
    dataIndex: "total_activity_cost",
    key: "total_activity_cost",
    render: (value) => <div>{value != null ? formatCurrency(value) : "-"}</div>,
  },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
    width: 150,
  },
];

export default columns;
