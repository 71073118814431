import MeasureCreation from "../../components/MeasureCreation";
import Layout from "../../components/Template/Layout";
import "./measurePage.scss";

function MeasureCreationPage() {
  return (
    <>
      <Layout>
        <MeasureCreation hasList />
      </Layout>
    </>
  );
}

export default MeasureCreationPage;
