export function redirectUser(role: string) {
  if (role.includes("Subowner_")) {
    if (role === "Subowner_cost") window.location.href = "/measures";
    if (role === "Subowner_quality") window.location.href = "/quality";
    if (role === "Subowner_control") window.location.href = "/control";
    else window.location.href = "/home";
  } else {
    if (role === "Hired") window.location.href = `/activities`;
    else window.location.href = "/home";
  }
}
