import { Tag } from "antd";

export default function returnStatusMeasure(obj: any) {
  if (!!obj.measure_approved_by || obj === 4) {
    return (
      <Tag color={"green"} key={obj.id}>
        Aprovado
      </Tag>
    );
  } else if (!!obj.measure_refused_date || obj === 3) {
    return (
      <Tag color={"volcano"} key={obj.id}>
        Recusado
      </Tag>
    );
  } else if (!!obj.measure_refused_date || obj === 5) {
    return (
      <Tag color={"green"} key={obj.id}>
       Aprovado com glosa
      </Tag>
    );
  }  else {
    return (
      <Tag color={"geekblue"} key={obj.id}>
        Pendente
      </Tag>
    );
  }
}
