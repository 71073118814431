/* eslint-disable react-hooks/exhaustive-deps */
import { Select as AntdSelect, FormInstance, Spin } from "antd";
import { useEffect, useState } from "react";
import { FieldErrors, FieldValues } from "react-hook-form";
import { useFetchSubDisciplines } from "../../hooks/wps/useFetchSubDisciplines";
import { useFetchWp } from "../../hooks/wps/useFetchWp";
import { useFetchWpsActivities } from "../../hooks/wps/useFetchWpsActivities";
import { useFetchWpsDefaultActivities } from "../../hooks/wps/useFetchWpsDefaultActivities";

import { Select } from "../form/fields";

import { Input, TextArea } from "../form/fields";
import "./addOrEdit-styles.scss";

import { Discipline } from "../../models/Discipline";
import { SubDiscipline } from "../../models/SubDicipline";
import { DataType } from "../../types/wp";
import { ActivityCheckbox } from "../wpForm/ActivityCheckbox";
import { Models } from "../wpForm/Models";

type Props = {
  type: string;
  editData?: DataType;
  form?: FormInstance<any>;
  statusList: Discipline[];
  disciplineList: Discipline[];
  index?: number;
  formData: {
    values: any;
    errors: FieldErrors<FieldValues> | undefined;
    setValue: any;
    name?: string;
    help?: (value: any) => string | undefined;
  };
  setHideMainModal: (newValue: boolean) => void;
};

export default function AddOrEditWp({
  type,
  editData,
  form,
  statusList,
  disciplineList,
  index,
  formData,
  setHideMainModal,
}: Props) {
  const { values, errors, setValue, name, help } = formData;
  const isEdit = type === "edit" && index !== undefined;

  const [refresh, setRefresh] = useState<any>();

  const [disciplineID, setDisciplineID] = useState<any>(values?.discipline);
  const [subDisciplineID, setSubDisciplineID] = useState<any>(
    values?.subDiscipline
  );

  const { subDisciplines, isFetchingSub } = useFetchSubDisciplines({
    discipline_id: disciplineID,
    refresh,
  });
  const { defaultActivities, isFetchingDefaultActivity } =
    useFetchWpsDefaultActivities({
      sub_discipline_id: subDisciplineID,
      refresh,
    });
  const { activities, isFetchingActivity } = useFetchWpsActivities({
    wp_id: editData?.id,
    refresh,
  });
  const { wp: wpData, isFetchingWp } = useFetchWp({
    wp_id: editData?.id,
    loadingInitialValue: isEdit,
    refresh,
  });

  useEffect(() => {
    if (!isEdit) {
      setSubDisciplineID(0);
      setRefresh(new Date());
      setValue("activities", []);
      setValue("model", []);
      !!values?.subDiscipline && setValue("subDiscipline", undefined);

      if (!!form) {
        form.setFieldValue("subDiscipline", undefined);
        form.setFieldValue("activities", []);
      }
    }
  }, [disciplineID]);

  useEffect(() => {
    setValue("activities", []);
    setRefresh(new Date());
  }, [subDisciplineID]);

  const handleChangeDiscipline = (e: any) => {
    setDisciplineID(e);
    setValue("discipline", e);
  };
  const handleChangeSubDiscipline = (e: any) => {
    setSubDisciplineID(e);
    setValue("subDiscipline", e);
  };
  const handleChangeStatus = (e: any) => {
    setValue("status", e);
  };

  return isEdit &&
    (isFetchingSub ||
      isFetchingDefaultActivity ||
      isFetchingActivity ||
      isFetchingWp) ? (
    <div className="wp-loading-container">
      <Spin />
    </div>
  ) : (
    <div className="form-container-WP">
      <div className="container-column-form">
        <Input
          name={`${name || ""}wpCode`}
          label="Código Wp"
          placeholder="Digite o código WP"
          helpClassName={"form-item-modal-wp"}
          value={values?.wpCode}
          onChange={(event) => setValue("wpCode", event.target.value)}
          error={errors?.wpCode}
          help={!!help ? help(editData?.name) : undefined}
        />

        <Input
          name={`${name || ""}alternativeName`}
          label="Nome Alternativo"
          placeholder="Digite o nome alternativo"
          helpClassName={"form-item-modal-wp"}
          value={values?.alternativeName}
          onChange={(event) => setValue("alternativeName", event.target.value)}
          error={errors?.alternativeName}
          help={!!help ? help(editData?.alternative_name) : undefined}
        />

        <Select
          label="Disciplina"
          name={`${name || ""}discipline`}
          error={errors?.discipline}
        >
          <AntdSelect
            value={values?.discipline}
            defaultValue={values?.discipline}
            onChange={handleChangeDiscipline}
            disabled={isEdit}
          >
            {disciplineList?.map((discipline: Discipline) => (
              <option value={discipline.id} key={discipline.id}>
                {discipline.name}
              </option>
            ))}
          </AntdSelect>
        </Select>

        <Select
          label="Subdisciplina"
          name={`${name || ""}subDiscipline`}
          error={errors?.subDiscipline}
        >
          <AntdSelect
            value={values?.subDiscipline}
            defaultValue={values?.subDiscipline}
            onChange={handleChangeSubDiscipline}
            loading={isFetchingSub}
            disabled={subDisciplines.length < 1 || isEdit}
          >
            {subDisciplines?.map((subDiscipline: SubDiscipline) => (
              <option
                value={subDiscipline.id}
                key={subDiscipline.id}
                className="subdiscipline"
              >
                {subDiscipline.name}
              </option>
            ))}
          </AntdSelect>
        </Select>

        <ActivityCheckbox
          type={type}
          index={index}
          formData={formData}
          defaultActivities={defaultActivities}
          activities={activities}
          setHideMainModal={setHideMainModal}
        />
      </div>
      <div className="container-column-form">
        <TextArea
          rows={3}
          autoSize
          name={`${name || ""}description`}
          label="Descrição"
          placeholder="Digite a descrição"
          value={values?.description}
          onChange={(event) => setValue("description", event.target.value)}
          error={errors?.description}
          help={!!help ? help(editData?.description) : undefined}
        />
        <Select
          label="Status"
          name={`${name || ""}status`}
          error={errors?.status}
          help={!!help ? help(editData?.status) : undefined}
        >
          <AntdSelect
            value={values?.status}
            defaultValue={values?.status}
            onChange={handleChangeStatus}
          >
            {statusList?.map((status: any) => (
              <option value={status.id} key={status.id}>
                {status.name}
              </option>
            ))}
          </AntdSelect>
        </Select>

        <Models type={type} index={index} formData={formData} wpData={wpData} />

        <TextArea
          name={`${name || ""}justification`}
          label="Justificativa"
          placeholder="Digite a justificativa"
          value={values?.justification}
          onChange={(event) => setValue("justification", event.target.value)}
          error={errors?.justification}
          help={!!help ? help(editData?.justification) : undefined}
        />
      </div>
    </div>
  );
}
