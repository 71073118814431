import { useState } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

type Props = {
  refetch: () => void;
};

export const useApproveAllActivities = ({ refetch }: Props) => {
  const [isApprovingAll, setIsApprovingAll] = useState(false);

  const approveAllActivities = async (bulletin_id: any) => {
    try {
      setIsApprovingAll(true);
      await api.post(`v1/measurement-bulletins-activities/approve-all/${bulletin_id}`);
      toastAlert({
        type: "success",
        message: "Todas as atividades foram aprovadas.",
        title: "Aprovação de Todas as Atividades",
      });
    } catch (error: any) {
      toastAlert({
        type: "error",
        message: "Erro ao aprovar todas as atividades. Tente novamente.",
        title: "Erro na Aprovação em Massa",
      });
    } finally {
      setIsApprovingAll(false);
      refetch();
    }
  };

  return { approveAllActivities, isApprovingAll };
};
