import dayjs from "dayjs";
import { useState } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

type Props = {
  formData: {
    date: Date[];
    observation?: string;
  };
  handleClose: VoidFunction; 
};
export const useCreateBm = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [refresh, setRefresh] = useState<Date>();

  const createBm = async ({ formData, handleClose }: Props) => {
    const startDate = formData.date?.length > 0 ? dayjs(formData.date[0])?.format("YYYY-MM-DD") : "";
    const endDate = formData.date?.length > 0 ? dayjs(formData.date[1])?.format("YYYY-MM-DD") : "";
    try {
      setIsFetching(true);

      await api.post(`/v1/measurement-bulletins/create`, {
        ...formData,
        startDate: startDate,
        endDate: endDate,
      });

      toastAlert({
        type: "success",
        message: "Boletim de medição criado com sucesso.",
        title: "Sucesso",
      });

      setRefresh(new Date());
      handleClose();
    } catch (error: any) {
      toastAlert({
        type: "error",
        message: "Não foi possível gerar o BM. Tente novamente",
        title: "Erro ao gerar BM",
      });
    } finally {
      setIsFetching(false);
    }
  };

  return { createBm, isFetching, refresh };
};
