import { Badge, Button, Col, Row, Table, Tabs, Typography } from "antd";
import { useEffect, useState } from "react";
import SearchInput from "../../components/SearchInput";
import Layout from "../../components/Template/Layout";
import api from "../../services/Api";
import "./controlPage.scss";
import dayjs from "dayjs";
import { FaCheck } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { MeasureFileListActions } from "../../components/MeasureFileListActions";
import CollapseCard from "../../components/Template/Card";
import ReportSelect from "../ReportPage/components/ReportSelect";
import formatDate from "../../utils/formatDate";
import { TextCropToltip } from "../../components/TextCropToltip";
import DatePickerComponent from "../../components/DatePicker/DatePicker";
import { tabs, tabs2 } from "./utils/const_tabs";
import { partialsColumns } from "./utils/columns";
import MeasurementPartialsActions from "../../components/MeasurementPartialsActions/MeasurementPartialsActions";
const { Title } = Typography;

const unChangebleColumns = [
  { title: "Nome", dataIndex: "show_name" },
  { title: "Código", dataIndex: "code" },
  { title: "Step de medição", dataIndex: "percentual_name" },
  { title: "Etapa", dataIndex: "etapa" },
  { title: "Data de realização", dataIndex: "activity_date", render: (name: any) => <>{name ? formatDate(name) : "-"}</> },
  {
    title: "Descrição da atividade",
    dataIndex: "joined_wp_description",
    key: "joined_wp_description",
    render: (description: any) => <TextCropToltip text={description} max_quantity={35} />,
  },
  {
    title: "Data de criação",
    dataIndex: "created_at",
    render: (name: any) => <>{new Date(name).toLocaleDateString("pt-BR")}</>,
  },
];

const controlDateColumn = {
  dataIndex: "control_date",
  width: 200,
  render: (name: any, record: any) => (
    <div className={"file-table-list-date " + (!!record.uploadData?.control_approved_by ? "positive-text-color" : "negative-text-color")}>
      {!!name ? (
        <>
          {!!record.uploadData?.control_approved_by ? <FaCheck size={18} /> : <IoClose size={20} />}
          {new Date(name + "T00:00:00").toLocaleDateString("pt-BR")}
        </>
      ) : (
        ""
      )}
    </div>
  ),
};

function ControlPage() {
  const [isLoading, setIsLoading] = useState<boolean | undefined>(true);
  const [dataTable, setDataTable] = useState([]);
  const [current, setCurrent] = useState(1);
  const [currentStatus, setCurrentStatus] = useState("pending");
  const location = useLocation();
  const initialType = location.state?.defaultType || "evidences";
  const [type, setType] = useState(initialType);;
  const [total, setTotal] = useState(0);
  const [filteredTotal, setFilteredTotal] = useState(0);
  const [roles, setRoles] = useState<string[]>([]);
  const [projectId, setProjectId] = useState("0");
  const [projects, setProjects] = useState([]);
  const [date, setDate] = useState<Date[]>([]);
  const [columns, setColumns] = useState<any[]>([...unChangebleColumns, { dataIndex: "control_date" }, { dataIndex: "actions", width: 150 }]);
  const [refreshFileList, setRefreshFileList] = useState(new Date());
  const handleChangeProject = (e: any) => {
    setProjectId(e);
  };
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const handleSearch = (search: string) => {
    setIsLoading(true);
    if (search !== "") {
      api
        .post(`/v1/activity-upload/control/` + currentStatus + "/search", {
          search: search,
        })
        .then((r) => {
          const data = r.data;
          setTotal(data.length);
          const table = data.map((obj: any) => ({
            ...obj,
            actions: (
              <div
                style={{
                  display: "flex",
                  gap: 8,
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <MeasureFileListActions
                  file={obj}
                  hideActions={false}
                  refreshList={() => setRefreshFileList(new Date())}
                  roles={roles}
                  setTableIsLoading={setIsLoading}
                  disableApprove={currentStatus === "approved"}
                  disableRefuse={currentStatus === "refused"}
                  alwaysShowRefuseAfterApprove={false}
                />
                <Button type="primary" onClick={() => navigate("/activity/" + obj.schedule_activity_id)}>
                  Ir para atividade
                </Button>
              </div>
            ),
          }));

          setFilteredTotal(r.data.length);
          setDataTable(table);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      fetchItemsByStatus();
    }
  };

  useEffect(() => {
    api.get(`/v1/user/roles`).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        setRoles(data.roles);
      }
    });
  }, []);

  useEffect(() => {
    fetchItemsByStatus();
  }, [current, currentStatus, refreshFileList, roles, projectId, date, type]);

  useEffect(() => {
    setColumns([
      ...unChangebleColumns,
      {
        ...controlDateColumn,
        title: currentStatus === "approved" ? "Aprovada em" : currentStatus === "refused" ? "Recusada em" : "",
      },
      { dataIndex: "actions", width: 150 },
    ]);
  }, [currentStatus]);

  const fetchItemsByStatus = async () => {
    const startDate = date?.length > 0 ? dayjs(date[0])?.format("YYYY-MM-DD") : "";
    const endDate = date?.length > 0 ? dayjs(date[1])?.format("YYYY-MM-DD") : "";

    const url = type === "evidences" ? "activity-upload/control/" : "measurement-partials/control/";
    setIsLoading(true);
    await api
      .get(`/v1/${url}${currentStatus}?page=${current}&project=${projectId}&startDate=${startDate}&endDate=${endDate}`)
      .then((response) => {
        let data = response.data.data.data;
        let table: any = [];
        if (type === "evidences") {
          setTotal(response.data.data.total);
          setProjects(response.data.projects);
          table = data.map((obj: any) => ({
            ...obj,
            actions: (
              <div
                style={{
                  display: "flex",
                  gap: 8,
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <MeasureFileListActions
                  file={obj}
                  hideActions={false}
                  refreshList={() => setRefreshFileList(new Date())}
                  roles={roles}
                  setTableIsLoading={setIsLoading}
                  disableApprove={currentStatus === "approved"}
                  disableRefuse={currentStatus === "refused"}
                  alwaysShowRefuseAfterApprove={false}
                />
                <Button type="primary" onClick={() => navigate("/activity/" + obj.schedule_activity_id)}>
                  Ir para atividade
                </Button>
              </div>
            ),
          }));
        } else {
          setTotal(response.data.total);
          data = response.data.data;
          table = data.map((obj: any) => ({
            ...obj,
            actions: (
              <div
                style={{
                  display: "flex",
                  gap: 8,
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <MeasurementPartialsActions
                  measurement_partial={obj}
                  refreshList={() => setRefreshFileList(new Date())}
                  actual_percentage={0}
                  partials_measurements={data}
                />
                <Button type="primary" onClick={() => navigate("/activity/" + obj.activity_id)}>
                  Ir para atividade
                </Button>
              </div>
            ),
          }));
        }
        setDataTable(table);
        setFilteredTotal(response.data.data.total);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const onChange = (key: string) => {
    setCurrentStatus(key);
    setCurrent(1);
    setSearch("");
  };
  const onChangeType = (key: string) => {
    setType(key);
    setCurrent(1);
    setSearch("");
  };

  return (
    <>
      <Layout pageTitle="Lista de Controle" breadcrumb="Home ⟶ Controle">
        <Row gutter={16} className="page-row">
          <Col xs={24} md={6} className="mb-2">
            <CollapseCard title="Total">
              <Title id="activities-total" level={2} className="total-card">
                {total}
              </Title>
            </CollapseCard>
          </Col>
          <Col xs={24} md={18} className="mb-2">
            <CollapseCard title="Filtros">
              <Col span={24} className="mb-2 activity-filters">
                <div className="filter-quality-container">
                  <div className="filter-quality-drops">
                    <ReportSelect
                      label="Selecione o projeto"
                      handleChange={handleChangeProject}
                      values={projects?.map((project: any) => {
                        return { value: project.id, label: project.name };
                      })}
                    />
                    <DatePickerComponent setValue={setDate} label={"Selecione o período"} />
                  </div>
                  <div className="container-search">
                    <SearchInput
                      type="text"
                      handleChange={handleChangeSearch}
                      value={search}
                      handleSearch={handleSearch}
                      loading={isLoading}
                      placeholder="Busque por um nome"
                    />
                  </div>
                </div>
              </Col>
            </CollapseCard>
          </Col>

          <Col xs={24} md={24} className="mb-2">
            <CollapseCard title="Tabela de Controle">
              <Row>
                <Col span={24}>
                  <Tabs
                    defaultActiveKey="evidences"
                    type="card"
                    size="large"
                    items={tabs2}
                    activeKey={type}
                    onChange={onChangeType}
                    className="fluit-tabs"
                  />
                  <div className="mt-1 mb-1">
                    <span className="title-table ">{type === "evidences" ? "Evidências" : "Medições"}</span>
                  </div>

                  <Tabs
                    defaultActiveKey="1"
                    type="card"
                    size="small"
                    items={tabs}
                    activeKey={currentStatus}
                    onChange={onChange}
                    className="fluit-tabs"
                  />

                  <Table
                    size="small"
                    dataSource={dataTable}
                    columns={type === "evidences" ? columns : partialsColumns}
                    loading={isLoading}
                    pagination={{
                      position: ["bottomCenter"],
                      current: current,
                      pageSize: 30,
                      onChange(page, pageSize) {
                        setCurrent(page);
                      },
                      total: total,
                      showSizeChanger: false,
                    }}
                  />
                </Col>
              </Row>
            </CollapseCard>
          </Col>
        </Row>
      </Layout>
    </>
  );
}

export default ControlPage;
