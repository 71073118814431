// hooks/files/useUploadExcelFile.ts
import { useState } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

type UploadProps = {
  e: any;
  activity_id?: string;
  onUploadSuccess: (data: any) => void;
};

export const useUploadExcelFile = () => {
  const [isUploading, setIsUploading] = useState(false);

  const handleUpload = async ({ e, activity_id, onUploadSuccess }: UploadProps) => {
    const file = e.file;

    const isXlsx = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    if (!isXlsx) {
      toastAlert({
        type: "error",
        message: "Apenas arquivos Excel (.xlsx) são permitidos.",
        title: "Tipo de arquivo inválido",
      });
      return;
    }

    setIsUploading(true);

    try {
      const formData = new FormData();
      formData.append("file", file);
      if (activity_id) formData.append("activity_id", activity_id);

      const response = await api.post("/v1/files/upload/excel", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      onUploadSuccess(response.data); 

      toastAlert({
        type: "success",
        message: `Arquivo carregado com sucesso.`,
        title: "Upload concluído",
      });
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message || "Erro desconhecido";
      toastAlert({
        type: "error",
        message: errorMessage,
        title: "Erro ao fazer upload",
      });
    } finally {
      setIsUploading(false);
    }
  };

  return { handleUpload, isUploading };
};
