import { Col, Pagination, Row, Select, Table } from "antd";
import Layout from "../../components/Template/Layout";
import CollapseCard from "../../components/Template/Card";
import Title from "antd/es/typography/Title";
import { useFetchFlow } from "../../hooks/activity/useFetchFlow";
import { useState } from "react";
import { columns } from "./utils/columns";
import { PaginationProps } from "antd/lib/pagination";
import SearchInput from "../../components/SearchInput";

export default function ActivityFlowPage() {
  const [current, setCurrent] = useState(1);
  const [filters, setFilters] = useState({
    project: undefined,
  });
  const [search, setSearch] = useState("");
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const onChange: PaginationProps["onChange"] = (page) => {
    setCurrent(page);
  };
  const { activities, total, projects, isFetching, searchFlow } = useFetchFlow({
    page: current,
    project: filters.project,
  });

  return (
    <>
      <>
        <Layout pageTitle="Lista de Fluxo" breadcrumb="Home ⟶ Fluxo">
          <Row gutter={16} className="page-row">
            <Col xs={24} md={6} className="mb-2">
              <CollapseCard title="Total">
                <Title id="activities-total" level={2} className="total-card">
                  {total}
                </Title>
              </CollapseCard>
            </Col>
            <Col xs={24} md={18} className="mb-2">
              <CollapseCard title="Filtros">
                <Col span={24} className="mb-2 activity-filters">
                  <Select
                    value={filters.project}
                    style={{ width: 170 }}
                    onChange={(e) => {
                      setFilters((prevState) => ({
                        ...prevState,
                        project: e,
                        reloadActivities: true,
                      }));
                    }}
                    allowClear
                    placeholder="Projetos"
                    options={projects}
                  />
                  <SearchInput
                    type="text"
                    handleChange={handleChangeSearch}
                    value={search}
                    handleSearch={searchFlow}
                    placeholder="Busque por código"
                    divClasse="activity-search"
                    loading={isFetching}
                  />
                </Col>
              </CollapseCard>
            </Col>
            <Col xs={24} md={24} className="mb-2">
              <CollapseCard title={`Tabela de fluxo das atividades`} className="card-table-no-padding">
                <div className="mb-3">
                  <span>
                    A tabela a seguir exibe atividades com evidências aprovadas, por qualidade e controle, e que já podem ser submetidas para a
                    medição.
                  </span>
                </div>
                <Row>
                  <div className="table-report-container">
                    {activities.length > 0 ? (
                      <Table columns={columns} dataSource={activities} pagination={false} loading={isFetching} size="small" />
                    ) : (
                      <Table className="table-no-data-rows" columns={columns} dataSource={[]} pagination={false} loading={isFetching} size="small" />
                    )}
                  </div>

                  <Col
                    span={24}
                    className="text-center mt-4"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "5px",
                    }}
                  >
                    <Pagination current={current} onChange={onChange} total={total} pageSize={30} showSizeChanger={false} />
                  </Col>
                </Row>
              </CollapseCard>
            </Col>
          </Row>
        </Layout>
      </>
    </>
  );
}
