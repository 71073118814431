import { Button, Col, Row, Table, Tabs, Typography } from "antd";
import { useEffect, useState } from "react";
import SearchInput from "../../components/SearchInput";
import Layout from "../../components/Template/Layout";
import api from "../../services/Api";
import "./measurePage.scss";

import { useNavigate } from "react-router-dom";
import CollapseCard from "../../components/Template/Card";
import { columnsDefault, columnsRefused } from "./utils/columns";
import { tabs } from "./utils/tabs";
import { useFetchMeasureByStatus } from "../../hooks/measure/useFetchMeasureByStatus";
import ReportSelect from "../ReportPage/components/ReportSelect";
const { Title } = Typography;

function MeasurePage() {
  const [dataTable, setDataTable] = useState([]);
  const [current, setCurrent] = useState(1);
  const [projectId, setProjectId] = useState("0");
  const [currentStatus, setCurrentStatus] = useState("1");
  const [refresh, setRefresh] = useState<Date>();

  const [columns, setColumns] = useState(columnsDefault);
  const handleChangeProject = (e: any) => {
    setProjectId(e);
  };
  const [search, setSearch] = useState("");
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    setRefresh(new Date());
    if (currentStatus === "3") setColumns(columnsRefused);
    else setColumns(columnsDefault);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, currentStatus]);
  const { total, projects, isFetching, searchMeasure } = useFetchMeasureByStatus({
    current,
    currentStatus,

    project_id: projectId,
    setDataTable,
    refresh,
  });

  const onChange = (key: string) => {
    setCurrentStatus(key);
    setCurrent(1);
    setSearch("");
  };

  return (
    <>
      <Layout pageTitle="Lista de Medições" breadcrumb="Home ⟶ Medições">
        <Row gutter={16} className="page-row">
          <Col xs={24} md={6} className="mb-2">
            <CollapseCard title="Total">
              <Title id="measures-total" level={2} className="total-card">
                {total}
              </Title>
            </CollapseCard>
          </Col>
          <Col xs={24} md={18} className="mb-2">
            <CollapseCard title="Tabela de Medições">
              <Row>
                <Col span={24} className="mb-2 text-right">
                  <div className="filter-measure-container">
                    <ReportSelect
                      label="Selecione o projeto"
                      handleChange={handleChangeProject}
                      values={projects?.map((project: any) => {
                        return { value: project.id, label: project.name };
                      })}
                    />
                    <div className="container-search">
                      <SearchInput
                        type="text"
                        handleChange={handleChangeSearch}
                        value={search}
                        handleSearch={searchMeasure}
                        loading={isFetching}
                        placeholder="Busque por uma descrição"
                      />
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <Tabs
                    defaultActiveKey="1"
                    type="card"
                    size="small"
                    items={tabs}
                    activeKey={currentStatus}
                    onChange={onChange}
                    className="fluit-tabs"
                  />

                  <Table
                    size="small"
                    dataSource={dataTable}
                    columns={columns}
                    loading={isFetching}
                    pagination={{
                      position: ["bottomCenter"],
                      current: current,
                      pageSize: 30,
                      onChange(page, pageSize) {
                        setCurrent(page);
                      },
                      total: total,
                      showSizeChanger: false,
                    }}
                  />
                </Col>
              </Row>
            </CollapseCard>
          </Col>
        </Row>
      </Layout>
    </>
  );
}

export default MeasurePage;
