import { number, z } from "zod";
import { requiredNumber, requiredString } from "../utils/validations";
import { ActivitySchema } from "./activity";

export const WpSchema = z.object({
  id: z.number().or(z.string()).optional(),
  wpCode: requiredString,
  alternativeName: z.string().optional(),
  discipline: requiredNumber,
  subDiscipline: requiredNumber,
  description: requiredString,
  status: z.number().optional(),
  model: z.array(number()).optional(),
  modelsToCreate: z.array(number()).optional(),
  modelsToDelete: z.array(number()).optional(),
  activities: z.unknown(),
  activitiesToCreate: z.unknown().optional(),
  activitiesToDelete: z.unknown().optional(),
  justification: z.string().nullable().optional(),
  newActivitiesToCreate: z.array(ActivitySchema),
});

export type WpFormType = z.infer<typeof WpSchema>;

export const WpListSchema = z.object({
  wps: z.array(WpSchema),
});

export type WpListFormType = z.infer<typeof WpListSchema>;

//

export const SubOwnerWpSchema = z.object({
  id: z.number().or(z.string()).optional(),
  wpCode: requiredString,
  alternativeName: z.string().optional(),
  discipline: requiredNumber,
  subDiscipline: requiredNumber,
  description: requiredString,
  status: z.number().optional(),
  model: z.array(number()).optional(),
  modelsToCreate: z.array(number()).optional(),
  modelsToDelete: z.array(number()).optional(),
  activities: z.unknown(),
  activitiesToCreate: z.unknown().optional(),
  activitiesToDelete: z.unknown().optional(),
  justification: requiredString,
  newActivitiesToCreate: z.array(ActivitySchema),
});

export const SubOwnerWpListSchema = z.object({
  wps: z.array(SubOwnerWpSchema),
});
