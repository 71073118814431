import { Button, Col, Pagination, Row, Table, Typography } from "antd";
import CollapseCard from "../../components/Template/Card";
import Layout from "../../components/Template/Layout";
import "./reportPage.scss";

import { PaginationProps } from "antd/lib/pagination";
import { useState } from "react";
import dayjs from "dayjs";
import { useFetchReport } from "../../hooks/report/useFetchReport";
import columns from "./constants/columns";
import ReportSelect from "./components/ReportSelect";
import { CalculateTotalReport } from "./utils/CalculateTotalReport";
import { formatCurrency } from "../../utils/formatCurrency";
import { statusItems } from "./constants/filters";
import { CalculateTotalApprovedReport } from "./utils/CalculateTotalApprovedReport";
import { CalculateTotalRefusedReport } from "./utils/CalculateTotalRefusedReport";
import DatePickerComponent from "../../components/DatePicker/DatePicker";
import { useExportEngineeringOrderXLSX } from "../../hooks/report/useExportReport";
import { BsFiletypeXlsx } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { MeasurementBulletinActivity } from "../../models/MeasurementBulletinActivity";
import BulletinActions from "../MeasurementBulletinsPage/components/BulletinActions/BulletinActions";
const { Title } = Typography;

export default function ReportPage() {
  const [current, setCurrent] = useState(1);
  const [date, setDate] = useState<Date[]>([]);
  const [cwa, setCwa] = useState("");
  const [project, setProject] = useState("");
  const [status, setStatus] = useState("");
  const [hired, setHired] = useState("");
  const [contract, setContract] = useState("");

  const handleChangeStatus = (e: string) => {
    setStatus(e);
  };
  const handleChangeHired = (e: string) => {
    setHired(e);
  };
  const handleChangeContract = (e: string) => {
    setContract(e);
  };
  const handleChangeCwa = (e: string) => {
    setCwa(e);
  };
  const handleChangeProject = (e: string) => {
    setProject(e);
  };
  const onChange: PaginationProps["onChange"] = (page) => {
    setCurrent(page);
  };
  const exportEngineeringOrderXLSX = useExportEngineeringOrderXLSX();
  const navigate = useNavigate();
  const startDate = date?.length > 0 ? dayjs(date[0])?.format("YYYY-MM-DD") : "";
  const endDate = date?.length > 1 ? dayjs(date[1])?.format("YYYY-MM-DD") : "";

  const { report, isFetching, total, cwas, projects, hireds, contracts, totals } = useFetchReport({
    page: current,
    startDate: startDate,
    endDate: endDate,
    project: project,
    cwa: cwa,
    status: status,
    hired: hired,
    contract: contract,
  });
  const handleExport = () => {
    exportEngineeringOrderXLSX({
      page: current,
      startDate: startDate,
      endDate: endDate,

      project: project,
      cwa: cwa,
      status: status,
      hired: hired,
      contract: contract,
    });
  };
  const totalCost = CalculateTotalReport(report);
  const totalCostApp = CalculateTotalApprovedReport(report);
  const totalCostRef = CalculateTotalRefusedReport(report);
  const zebraRowClassName = (record: any, index: any) => {
    return index % 2 !== 0 ? "zebra-stripe" : "";
  };

  return (
    <Layout pageTitle="Relatórios" breadcrumb="Home ⟶ Relatórios">
      <Row gutter={16} className="page-row">
        <div className="container-report-header">
          <Col xs={24} md={6} className="mb-2">
            <CollapseCard title="Total" height="225px">
              <div className="container-total-report">
                <Title level={2} className="total-card">
                  {total ? total : 0}
                </Title>
              </div>
            </CollapseCard>
          </Col>

          <Col xs={24} md={18} className="mb-2">
            <CollapseCard title="Filtros">
              <Title level={2} className="total-card">
                <div className="selects-container">
                  <div className="container-report-select">
                    <div>
                      <ReportSelect
                        label="Selecione o projeto"
                        handleChange={handleChangeProject}
                        values={projects?.map((project: any) => {
                          return { value: project.id, label: project.name };
                        })}
                      />
                    </div>
                    {/* <div>
                      <ReportSelect
                        label="Selecione o CWA"
                        handleChange={handleChangeCwa}
                        values={cwas?.map((cwa: any) => {
                          return { value: cwa.id, label: cwa.description };
                        })}
                      />
                    </div> */}
                    <div>
                      <ReportSelect label="Selecione o status" handleChange={handleChangeStatus} values={statusItems} />
                    </div>
                    <div>
                      <ReportSelect
                        label="Selecione o contrato"
                        handleChange={handleChangeContract}
                        values={contracts?.map((contract: any) => {
                          return {
                            value: contract.id,
                            label: contract.description,
                          };
                        })}
                      />
                    </div>
                    <div>
                      <ReportSelect
                        label="Selecione o contratada"
                        handleChange={handleChangeHired}
                        values={hireds?.map((hired: any) => {
                          return {
                            value: hired.id,
                            label: hired.name,
                          };
                        })}
                      />
                    </div>
                    <div className="date-container">
                      <DatePickerComponent setValue={setDate} label={"Filtre por Data"} />
                    </div>

                    <div className="container-buttons-report">
                      <Button onClick={handleExport} className="button-export">
                        <BsFiletypeXlsx size={24} />
                        Exportar
                      </Button>
                    </div>
                  </div>
                </div>
              </Title>
            </CollapseCard>
          </Col>
        </div>
        <Col xs={24} md={24}>
          <CollapseCard title="Relatórios" className="card-table-no-padding">
            <Row>
              <div className="table-report-container">
                <Table
                  className="table-report scoped-table-report"    
                  columns={columns}
                  dataSource={report}
                  pagination={false}
                  loading={isFetching}
                  size="large"
                  scroll={{ x: 'max-content', y: 55 * 7 }}
                  rowClassName={zebraRowClassName}
                  locale={{ emptyText: "Sem dados" }}
                />
              </div>
              <div className="total-container">
                <div className="total-values">
                  <span>Aprovado:</span>
                  <span  style={{ color: "#60a902", fontWeight: "bold" }}>{formatCurrency(totals.total_approved)}</span>
                </div>

                <div className="total-values">
                  <span>Recusado:</span>
                  <span style={{ color: "#a90202", fontWeight: "bold" }}> {formatCurrency(totals.total_reproved)}</span>
                </div>

                <div className="total-values">
                  <span>Total apresentado:</span>
                  <span> {formatCurrency(totals.total_consumed)}</span>
                </div>
              </div>

              <Col
                span={24}
                className="text-center mt-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                }}
              >
                <Pagination current={current} onChange={onChange} total={total} pageSize={100} showSizeChanger={false} />
              </Col>
            </Row>
          </CollapseCard>
        </Col>
      </Row>
    </Layout>
  );
}
