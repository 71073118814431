import { useState } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

type Props = {
  percentage?: string | number;
  activity_id?: number | string;
};
export const usePostEditPercentage = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [refresh, setRefresh] = useState<Date>();

  const postPercentage = async ({ percentage, activity_id }: Props) => {
    try {
      setIsFetching(true);

      await api.patch(`/v1/activity/percentage/${activity_id}`, {
        percentage: percentage,
      });

      setRefresh(new Date());
    } catch (error: any) {
      toastAlert({
        type: "error",
        message: "Confira sua conexão para tentar novamente.",
        title: "Erro ao editar porcentagem",
      });
    } finally {
      setIsFetching(false);
    }
  };

  return { postPercentage, isFetching, refresh };
};
