export function formatDateISO(input?: string): string | null {
  if (input) {
    const date = new Date(input);
    const day = String(date.getDate()).padStart(2, "0"); // Dia com 2 dígitos
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Mês com 2 dígitos
    const year = date.getFullYear(); // Ano com 4 dígitos

    return `${day}/${month}/${year}`;
  }
  return null;
}
