import Report from "../models/Report";
import formatDate from "./formatDate";

export function returnDatereport(obj: Report) {
  if (!!obj.measure_approved_date && obj.measurement_date) {
    return <span style={{ color: "green", fontWeight: "bold" }}>{formatDate(obj.measurement_date)}</span>;
  }
  if (!!obj.measure_refused_date && !!obj.measurement_date) {
    return <span style={{ color: "red", fontWeight: "bold" }}>{formatDate(obj.measurement_date)}</span>;
  }
  if (!!obj.measure_refused_date && !obj.measurement_date) {
    return <span style={{ color: "red", fontWeight: "bold" }}>{formatDate(obj.measure_refused_date)}</span>;
  }
}
