import { Button, Input, Modal } from "antd";
import { useState } from "react";

import { useAproveRefuseFile } from "../../../hooks/files/useAproveRefuseFile";

const { TextArea } = Input;

export default function RefuseAfterApproveModal({
  isModalOpen,
  handleClose,
  id,
  refreshList,
}: {
  isModalOpen: boolean;
  handleClose: VoidFunction;
  refreshList: VoidFunction;
  id: number;
}) {
  const [message, setMessage] = useState("");

  const handleCancel = () => {
    handleClose();
  };

  const { handleRefuseAfterApprove } = useAproveRefuseFile();

  return (
    <Modal
      title="Porque deseja reprovar a evidência?"
      open={isModalOpen}
      onOk={() => handleRefuseAfterApprove({ id, refreshList, message })}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={!message}
          danger
          onClick={() => {
            handleRefuseAfterApprove({ id, refreshList, message });
            handleClose();
          }}
        >
          Reprovar
        </Button>,
      ]}
    >
      <TextArea
        placeholder="Escreva o motivo"
        rows={3}
        onChange={(event) => {
          setMessage(event.target.value);
        }}
        style={{ marginTop: 8 }}
      />
    </Modal>
  );
}
