import { useCallback, useEffect, useState } from "react";
import api from "../../config/axios";
import { Activity } from "../../models/Activity";

type Props = {
  wp_id?: string | number;
  type?: string;
  refresh: null | Date;
};

export const useFetchWpsActivities = ({ wp_id, type, refresh }: Props) => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [isFetchingActivity, setIsFetching] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const url = type
    ? `/v1/wps/${wp_id}/activities/${type}`
    : `v1/wps/${wp_id}/activities`;

  const returnWpsActivities = useCallback(() => {
    if (wp_id) {
      setIsFetching(true);
      api
        .get(url)
        .then((response) => {
          const activities = response.data.activities;
          const iwps = response.data.iwps;
          const concattedData = activities.data.concat(iwps);
          setActivities(concattedData || []);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setIsFetching(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wp_id, refresh]);

  useEffect(() => {
    returnWpsActivities();
  }, [returnWpsActivities]);

  return {
    activities,
    isFetchingActivity,
    error,
  };
};
