import { Input as AntdInput, Form, InputProps, InputRef } from "antd";
import { useFormContext } from "react-hook-form";

type Props = InputProps &
  React.RefAttributes<InputRef> & {
    label?: string;
    name: string;
    placeholder: string;
    helpClassName?: string;
    error?: any | undefined;
    help?: React.ReactNode;
  };

export default function Input({
  label,
  name,
  placeholder,
  helpClassName,
  onChange,
  value,
  error,
  help,
  ...others
}: Props) {
  const {
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext();
  const values = watch();

  const formValue = value || values[name];
  const formError = error || errors[name];
  const formHelp = help
    ? help
    : formError
    ? (formError?.message as string)
    : undefined;

  return (
    <Form.Item
      name={name}
      label={label}
      className={helpClassName}
      help={formHelp}
      validateStatus={!!formError ? "error" : undefined}
    >
      <AntdInput
        placeholder={placeholder}
        value={formValue}
        defaultValue={formValue}
        {...others}
        onChange={(event) => {
          onChange ? onChange(event) : setValue(name, event.target.value);
          formError && trigger(name);
        }}
      />
    </Form.Item>
  );
}
