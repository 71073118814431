import React, { useState } from "react";
import api from "../../services/Api";
import SelectInputSearch from "../SelectInputSearch";

let getActivitiesSearch: AbortController;

export function SelectActivity({
  newActivityId,
  setNewActivityId,
  allActivities,
  setAllActivities,
}: {
  newActivityId: any;
  setNewActivityId: React.Dispatch<any>;
  allActivities: any;
  setAllActivities: React.Dispatch<React.SetStateAction<any[]>>;
}) {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSearch = () => {
    setLoading(true);

    if (getActivitiesSearch) getActivitiesSearch.abort();
    getActivitiesSearch = new AbortController();

    if (search !== "") {
      api
        .post(
          "/v1/activity/all/search-without-contract",
          { search },
          { signal: getActivitiesSearch?.signal }
        )
        .then((r) => {
          if (r.status === 200) {
            setAllActivities(
              r.data.map((hired: any) => ({
                value: hired.id,
                label: hired.name,
              }))
            );
          }
        })
        .finally(() => {
          setLoading(false);
        })
        .catch(() => {});
    } else {
      getActivitiesData();
    }
  };

  async function getActivitiesData() {
    setLoading(true);

    await api
      .get("/v1/activity/activities-without-contract")
      .then((response) => {
        if (response.status === 200) {
          setAllActivities(
            response.data.data.map((hired: any) => ({
              value: hired.id,
              label: hired.name,
            }))
          );
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  }

  const onChangeHired = (value: string) => {
    setNewActivityId(value);
  };

  return (
    <SelectInputSearch
      placeholder="Selecione uma atividade"
      onChange={onChangeHired}
      value={newActivityId}
      onSearch={(value: string) => setSearch(value)}
      search={search}
      options={allActivities || []}
      getData={handleSearch}
      loading={loading}
      style={{ width: "100%", overflow: "auto" }}
    />
  );
}
