import { useState } from "react";
import SelectInputSearch from "../SelectInputSearch";

export function SelectType({
  value,
  setValue,
  id = "select",
}: {
  value: any;
  setValue: (value: string) => void;
  id?: string;
}) {
  const [search, setSearch] = useState("");
  const list = ["CWP", "EWP", "PWP"];
  // const list = ["CWP", "EWP", "PWP", "IWP"];

  return (
    <SelectInputSearch
      id={id}
      placeholder="Selecione um tipo"
      onChange={setValue}
      value={value}
      onSearch={(value: string) => setSearch(value)}
      search={search}
      options={list.map((item) => ({ value: item, label: item }))}
      getData={() => {}}
      filterOption={false}
    />
  );
}
