import { TableColumnsType } from "antd";
import returnStatusEvidence from "../../../../utils/returnStatusEvidence";
import { formatCurrency } from "../../../../utils/formatCurrency";
import returnStatusActivity from "../../../../utils/returnStatusActivity";
import { TextCropToltip } from "../../../../components/TextCropToltip";

export const evidenceColumns: TableColumnsType<any> = [
  { title: "Código", dataIndex: "code", key: "code" },
  { title: "Arquivo", dataIndex: "show_name", key: "show_name" },
  { title: "Etapa", dataIndex: "etapa", key: "etapa" },
  { title: "Step de medição", dataIndex: "description", key: "description" },

  {
    title: "Descrição da atividade",
    dataIndex: "joined_wp_description",
    key: "joined_wp_description",
    render: (description: any) => <TextCropToltip text={description} max_quantity={35} />,
  },
  {
    title: "Status da medição",
    dataIndex: "status_evidence",
    render: (status) => returnStatusEvidence(status),
    key: "status_evidence",
  },

  {
    title: "Valor",
    dataIndex: "cost",
    render: (name) => <div>{name ? formatCurrency(name, 2) : "-"}</div>,
    key: "cost",
  },
  { title: "Status da atividade", dataIndex: "status_activity", key: "status", render: (status) => returnStatusActivity(status) },

  { title: "Justificativa", dataIndex: "justification", key: "justification" },
  { title: "", dataIndex: "actions", key: "actions" },
];
