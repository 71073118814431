import { useCallback, useEffect, useState } from "react";
import api from "../../config/axios";

type ContractProps = {
  current: number;
  refresh?: Date;
};

export const useFetchContracts = ({ current, refresh }: ContractProps) => {
  const [total, setTotal] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [contracts, setContracts] = useState([]);

  const fetchContracts = useCallback(() => {
    setIsFetching(true);
    let url = `v1/contracts/all?page=${current}`;

    api
      .get(url)
      .then((response) => {
        const table = response.data.contracts.data;

        setContracts(table);
        setTotal(response.data.total);
      })
      .finally(() => setIsFetching(false));
  }, [current, refresh]);

  useEffect(() => {
    fetchContracts();
  }, [fetchContracts]);

  return {
    isFetching,
    total,
    contracts,
  };
};
