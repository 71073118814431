import { Select, SelectProps, Spin } from "antd";
import { useEffect } from "react";
import "./searchInput.scss";

type Props = SelectProps & {
  onChange?:
    | ((
        value: any,
        option:
          | {
              label: string;
              value: string;
            }
          | {
              label: string;
              value: string;
            }[]
      ) => void)
    | undefined;
  onSearch: (search: string) => void;
  placeholder?: string;
  options: any[];
  value?: any;
  search?: string;
  disabled?: boolean;
  loading?: boolean;
  getData: VoidFunction;
  status?: "" | "warning" | "error" | undefined;
  style?: React.CSSProperties | undefined;
  id?: string;
};

export default function SelectInputSearch({
  onChange,
  placeholder,
  options,
  value,
  disabled = false,
  onSearch,
  getData,
  loading,
  search,
  status,
  style,
  id,
  ...other
}: Props) {
  // Filter `option.label` match the user type `input`
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Select
      showSearch
      allowClear
      optionFilterProp="children"
      value={value}
      id={id}
      placeholder={placeholder}
      filterOption={filterOption}
      options={options || []}
      onChange={onChange}
      onSearch={(value) => onSearch(value)}
      disabled={disabled}
      notFoundContent={
        <div className="popover-no-options">
          {loading ? <Spin /> : "Sem dados"}
        </div>
      }
      status={status}
      style={{ width: "100%", ...style }}
      {...other}
    />
  );
}
