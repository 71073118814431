import { Button, DatePicker, Modal, RadioChangeEvent } from "antd";
import "./modalPartialMesurement.scss";
import RadioButtons from "../../../RadioButtons/RadioButtons";
import { useState } from "react";
import { radioOptions } from "../../utils/radioOptions";
import dayjs from "dayjs";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  submit: ({ value, date }: { value: number; date: string }) => void;
  activity: any;
};

export default function ModalPartialMesurement({ isOpen, handleClose, submit, activity }: Props) {
  const [value, setValue] = useState(0);
  const [date, setDate] = useState("");
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  return (
    <Modal
      title={`Solicitar medição`}
      footer={[
        <Button key="back" onClick={handleClose}>
          Cancelar
        </Button>,
        <Button key="submit" disabled={value === 0 || date === ""} onClick={() => submit({ value: value, date: date })} type="primary" danger>
          Solicitar
        </Button>,
      ]}
      width={"50%"}
      open={isOpen}
      onCancel={handleClose}
    >
      <div className="container-radio mt-2">
        <span>Selecione a opção desejada:</span>
        <RadioButtons
          onChange={onChange}
          value={value}
          options={radioOptions}
          executedValue={0}
          partials_measurements={activity.partials_measurements}
        />
      </div>
      <div className="container-date-radio">
        <span>Defina a data de solicitação:</span>
        <DatePicker allowClear format={"DD/MM/YYYY"} onChange={(e: any) => setDate(dayjs(e)?.format("YYYY-MM-DD"))} />
      </div>
    </Modal>
  );
}
