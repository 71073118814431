import { useState } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

type Props = {
  formData: {
    message?: string;
    activity_id?: number | string;
  };
};
export const usePostComment = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [refresh, setRefresh] = useState<Date>();

  const postComment = async ({ formData }: Props) => {
    try {
      setIsFetching(true);

      await api.post(
        `/v1/activity/${formData.activity_id}/comment/create`,
        {
          ...formData,
        }
      );

      setRefresh(new Date());
    } catch (error: any) {
      toastAlert({
        type: "error",
        message:
          "Seu comentário não foi postado. Confira sua conexão para tentar novamente.",
        title: "Erro ao fazer comentário",
      });
    } finally {
      setIsFetching(false);
    }
  };

  return { postComment, isFetching, refresh };
};
