import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import { ReactNode, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import TodoList from "../TodoList";
import "./layout.scss";

function Layout({
  pageTitle,
  breadcrumb,
  children,
  actions,
  cantGoBack,
}: {
  pageTitle?: string;
  breadcrumb?: ReactNode;
  children?: ReactNode;
  actions?: ReactNode;
  cantGoBack?: boolean;
}) {
  const [todoList, setTodoList] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>{`${pageTitle ? pageTitle + " |" : ""} Fluit`}</title>
      </Helmet>
      <div className="background-rectangle" />
      <Header showTodoList={setTodoList} />
      <Row>
        <Col className={`${todoList ? "hide" : "show"} layout-container`}>
          <div className="layout-subheader">
            <div className="layout-subheader_content">
              <span className="layout-subheader_content_title">
                {pageTitle}
              </span>
              <span className="layout-subheader_content_breadcrumb">
                {breadcrumb}
              </span>
            </div>
            <div className="layout-subheader_actions">
              {!cantGoBack ? (
                <Button
                  icon={<ArrowLeftOutlined />}
                  className="button-secondary back-button"
                  shape="round"
                  onClick={() => navigate(-1)}
                />
              ) : null}
              {!!actions ? actions : null}
            </div>
          </div>
          {children}
        </Col>
        {todoList && <TodoList />}
      </Row>
    </>
  );
}

export default Layout;
