import { z } from "zod";
import { requiredString } from "../utils/validations";

export const ResetPasswordSchema = z
  .object({
    email: requiredString.email("Email inválido"),
    password: requiredString,
    confirmPassword: requiredString,
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Senhas tem que ser iguais",
    path: ["confirmPassword"],
  });

export type ResetPasswordFormType = z.infer<typeof ResetPasswordSchema>;
