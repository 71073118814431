import { useCallback, useEffect, useState } from "react";
import api from "../../config/axios";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { MeasurementBulletin } from "../../models/MeasurementBulletin";
import { Project } from "../../models/Project";
import { Contract } from "../../models/Contract";

type Props = {
  refresh?: Date;
  contract?: number;
  project?: number;
};

export const useFetchBms = ({ refresh, contract, project }: Props) => {
  const [bms, setBms] = useState<any[]>([]);
  const [total, setTotal] = useState<any[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [contracts, setContracts] = useState<Contract[]>([]);

  const [totalBms, setTotalBms] = useState<any>();
  const [isFetching, setIsFetching] = useState(false);
  const [totalTable, setTotalTable] = useState<any>(0);
  const navigate = useNavigate();
  const contractFilter = contract ? contract : "";
  const projectFilter = project ? project : "";
  const returnBms = useCallback(
    () => {
      setIsFetching(true);
      api
        .get(`/v1/measurement-bulletins?contract=${contractFilter}&project=${projectFilter}`)
        .then((response) => {
          const data = response.data.bulletins.data;
          setTotal(response.data.bulletins.total);
          setTotalBms(response.data.totals);
          setProjects(response.data.projects);
          setContracts(response.data.contracts);
          
          let letTotalTable: number = 0.0;
          data.forEach( (e: any) => {
            if(!!e.total_activities_value ){
              letTotalTable = letTotalTable + parseFloat(e.total_activities_value);
            }
          });
          setTotalTable(letTotalTable)
          const table = data.map((obj: MeasurementBulletin) => ({          
            ...obj,
            actions: (
              <>
                <Button type="primary" onClick={() => navigate("/measurement-bulletin/" + obj.id)}>
                  Ir para boletim
                </Button>
              </>
            ),
          }));      
          setBms(table);
        })
        .catch((err) => {
          //  setError(err);
        })
        .finally(() => {
          setIsFetching(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refresh, contract, project]
  );

  useEffect(() => {
    returnBms();
  }, [returnBms]); 

  return {
    bms,
    isFetching,
    total,
    totalBms,
    projects,
    contracts,
    totalTable
  };
};
