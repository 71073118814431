import {
  Button,
  Col,
  Pagination,
  Row,
  Select,
  Table,
  TableColumnsType,
  Tag,
  Typography,
} from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React, { useEffect, useState } from "react";
import ModalAddOrEditWp from "../../components/ModalAddOrEditWp";
import SearchInput from "../../components/SearchInput";
import CollapseCard from "../../components/Template/Card";
import Layout from "../../components/Template/Layout";
import { useApproveRefuseRequisition } from "../../hooks/requisitions/useApproveRefuseRequisition";
import api from "../../services/Api";
import { DataType } from "../../types/wp";
import "./requisitionsPage.scss";

const { Title } = Typography;

function RequisitionsPage() {
  const [showViewModal, setShowViewModal] = useState(false);

  const [current, setCurrent] = useState(1);
  const [select, setSelect] = useState("all");
  const [dataTable, setDataTable] = useState<any>([]);
  const [fetchingData, setFetchingData] = useState(true);
  const [total, setTotal] = useState(0);
  const [refresh, setRefresh] = useState(new Date());
  const [selectedItems, setSelectedItems] = useState<DataType[]>([]);
  const [selectedModalItem, setSelectedModalItem] = useState<
    { id: number; type: string } | undefined
  >();
  const role = localStorage.getItem("user_role");
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedItems(selectedRows);
    },

    getCheckboxProps: (record: DataType) => {
      const isApproved = !!record.approved_by;
      const isRefused = !!record.refused_by;

      return {
        name: record.name,
        disabled: isApproved || isRefused,
      };
    },
  };

  const handleAprove = () => {
    approveOrRefuse({
      type: "approve-many",
      select_requisitions: selectedItems,
    });
  };
  const handleRefuse = () => {
    approveOrRefuse({
      type: "refuse-many",
      select_requisitions: selectedItems,
    });
  };

  const handleChangeSelect = (e: any) => {
    setSelect(e);
  };

  function returnStatus(obj: any) {
    if (!!obj.approved_by) {
      return (
        <Tag color={"green"} key={obj.id}>
          Aprovado
        </Tag>
      );
    } else if (!!obj.refused_by) {
      return (
        <Tag color={"volcano"} key={obj.id}>
          Recusado
        </Tag>
      );
    } else {
      return (
        <Tag color={"geekblue"} key={obj.id}>
          Pendente
        </Tag>
      );
    }
  }
  const { approveOrRefuse, refresh: refreshRequisition } =
    useApproveRefuseRequisition();
  async function getData() {
    setFetchingData(true);

    await api
      .get(`/v1/requisitions/all/${select}?page=` + current)
      // .get("/v1/cwas/" + cwa_id + "/wps?page=" + current)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data;

          const table = data.map((obj: any) => ({
            ...obj,
            key: obj.id,
            name: obj.justification,
            status: returnStatus(obj),

            actions: (
              <Button
                type="primary"
                onClick={() => {
                  setSelectedModalItem({ id: obj.id, type: obj.type });
                  setShowViewModal(true);
                }}
              >
                Abrir
              </Button>
            ),
          }));

          setDataTable(table);
          setTotal(response.data.total);
          setSelectedItems([]);
        }
      })
      .finally(() => setFetchingData(false));
  }
  // search states and functions

  const [search, setSearch] = useState("");
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSearch = (search: string) => {
    setFetchingData(true);
    if (search !== "") {
      api
        .post("/v1/requisitions/search", { search: search })
        .then((response) => {
          const data = response?.data.data;

          const table = data?.map((obj: any) => ({
            ...obj,
            key: obj.id,
            name: obj.justification,
            status: returnStatus(obj),
            actions: (
              <Button
                type="primary"
                onClick={() => {
                  setSelectedModalItem({ id: obj.id, type: obj.type });
                  setShowViewModal(true);
                }}
              >
                Abrir
              </Button>
            ),
          }));

          setDataTable(table);
          setTotal(data.length);
          setSelectedItems([]);
        })
        .finally(() => {
          setFetchingData(false);
        });
    } else {
      getData();
    }
  };
  // end of search

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, refresh, refreshRequisition, select]);

  const columns: TableColumnsType<DataType> = [
    { title: "Nome", dataIndex: "name", key: "name" },
    { title: "Status", dataIndex: "status", key: "status" },

    { title: "", dataIndex: "actions", width: 110, key: "actions" },
  ];

  const onChange: PaginationProps["onChange"] = (page) => {
    setCurrent(page);
  };

  return (
    <>
      <Layout pageTitle="Requisições" breadcrumb="Home ⟶ Requisições">
        <Row gutter={16} className="page-row">
          <Col xs={24} md={6} className="mb-2">
            <CollapseCard title="Total">
              {!!total ? (
                <Title level={2} className="total-card">
                  {total}
                </Title>
              ) : null}
            </CollapseCard>
          </Col>
          <Col xs={24} md={18} className="mb-2">
            <CollapseCard
              title="Tabela de Requisições"
              className="card-table-no-padding"
            >
              <Row>
                <Col span={24} style={{ overflow: "auto" }}>
                  <Col span={24} className="wp-actions">
                    {selectedItems.length > 0 &&
                    !(role || "").includes("Subowner") ? (
                      <>
                        <Button
                          type="primary"
                          className="wp-actions-btn"
                          disabled={false}
                          onClick={() => handleRefuse()}
                        >
                          Reprovar {selectedItems.length} selecionadas
                        </Button>
                        <Button
                          type="primary"
                          className="wp-actions-btn"
                          disabled={false}
                          onClick={() => handleAprove()}
                        >
                          Aprovar {selectedItems.length} selecionadas
                        </Button>
                      </>
                    ) : null}
                    <div className="select-requisition-container">
                      <span>Filtre por status:</span>
                      <Select
                        defaultValue="all"
                        style={{ width: 120 }}
                        onChange={handleChangeSelect}
                        options={[
                          { value: "all", label: "Todas" },
                          { value: "approved", label: "Aprovadas" },
                          { value: "refused", label: "Recusadas" },
                          { value: "pending", label: "Pendentes" },
                        ]}
                      />
                    </div>
                    <SearchInput
                      type="text"
                      handleChange={handleChangeSearch}
                      value={search}
                      handleSearch={handleSearch}
                      loading={fetchingData}
                      placeholder="Busque por nome"
                      divClasse="wp-actions_search"
                    />
                  </Col>

                  <Col span={24} style={{ overflow: "auto" }}>
                    <Table
                      className="table-default-style"
                      columns={columns}
                      rowSelection={{
                        type: "checkbox",
                        ...rowSelection,
                        selectedRowKeys: selectedItems.map((item) => item.key),
                      }}
                      dataSource={dataTable}
                      loading={fetchingData}
                      pagination={false}
                      // scroll={{ y: 350 }}
                      locale={{ emptyText: "Sem dados" }}
                      size="small"
                      style={{ minWidth: "600px" }}
                    />
                  </Col>
                </Col>

                <Col
                  span={24}
                  className="text-center mt-4"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "5px",
                  }}
                >
                  <Pagination
                    current={current}
                    onChange={onChange}
                    total={total}
                    pageSize={30}
                    showSizeChanger={false}
                  />
                </Col>
              </Row>
            </CollapseCard>
          </Col>
        </Row>
      </Layout>

      {showViewModal ? (
        <ModalAddOrEditWp
          selectedItem={selectedModalItem}
          isModalOpen={showViewModal}
          handleCancel={() => {
            setSelectedModalItem(undefined);
            setShowViewModal(false);
          }}
          type="view"
          afterSubmit={() => {
            setRefresh(new Date());
            setSelectedItems([]);
            setSelectedModalItem(undefined);
            setSearch("");
          }}
        />
      ) : null}
    </>
  );
}

export default RequisitionsPage;
