import { useCallback, useEffect, useState } from "react";
import api from "../../config/axios";

type ValidationEvidenceProps = {
  current: number;
  refresh?: Date;
  setTables: any;
  search?: string;
};

export const useFetchValidationEvidences = ({ current, refresh, setTables, search }: ValidationEvidenceProps) => {
  const [total, setTotal] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  const fetchValidationEvidences = useCallback(() => {
    setIsFetching(true);
    api
      .get(`v1/validation-evidence?page=${current}`)
      .then((response) => {
        const table = response.data.data;

        setTables(table);
        setTotal(response.data.total);
      })
      .finally(() => setIsFetching(false));
  }, [current, refresh]);

  useEffect(() => {
    fetchValidationEvidences();
  }, [fetchValidationEvidences]);

  return {
    isFetching,
    total,
  };
};
