import { useState } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

type ValidationEvidenceProps = {
  title: string;
  description?: string;
  requires_control: boolean;
  requires_quality: boolean;
  dynamic_validation?: string;
  contract_id: number;
  is_required: boolean;
};

export const usePostValidationEvidence = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [refresh, setRefresh] = useState<Date>();

  const postValidationEvidence = async (data: ValidationEvidenceProps) => {
    try {
      setIsFetching(true);

      await api.post("/v1/validation-evidence/create", {
        ...data,
      });

      setRefresh(new Date());
      toastAlert({
        type: "success",
        message: "A evidência de validação foi criada com sucesso.",
        title: "Evidência de Validação Criada",
      });
    } catch (error: any) {
      toastAlert({
        type: "error",
        message: "Erro ao criar a evidência de validação. Verifique sua conexão e tente novamente.",
        title: "Erro na criação",
      });
    } finally {
      setIsFetching(false);
    }
  };

  return { postValidationEvidence, isFetching, refresh };
};
