import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Form } from "antd";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useAddContractActivities } from "../../hooks/contracts/useAddContractActivities";
import {
  AssignContractFormType,
  AssignContractSchema,
} from "../../schemas/assignContract";
import { FormProvider } from "../form/FormProvider";
import { Select } from "../form/fields";
import "./contract.scss";
import { SelectContract } from "./selectContract";

type Hired = {
  name: string;
  email: string;
};

function AssignContractCard({
  afterSubmit,
}: {
  afterSubmit?: (name?: string, email?: string) => void;
}) {
  const { activity_id } = useParams();
  const [form] = Form.useForm();
  const [selectedHired, setSelectedHired] = useState<Hired>({
    name: "",
    email: "",
  });

  const methods = useForm<AssignContractFormType>({
    resolver: zodResolver(AssignContractSchema),
  });

  const { handleSubmit, trigger, setValue } = methods;

  const { handleAddActivities, isSubmitting } = useAddContractActivities();

  const handleAssignContract = (values: AssignContractFormType) => {
    handleAddActivities({
      contract: values.contract_id,
      activities: [activity_id || ""],
      handleConfirm: () =>
        !!afterSubmit && afterSubmit(selectedHired.name, selectedHired.email),
    });
  };

  return (
    <FormProvider
      methods={methods}
      form={form}
      labelCol={{ xs: 3, lg: 4, xl: 3, xxl: 2 }}
      onFinish={handleSubmit(handleAssignContract)}
      className="activity_creation-contract_assign"
      style={{
        display: "flex",
        gap: 8,
        flexDirection: "column",
      }}
    >
      <Select name="contract_id" label="Contrato">
        <SelectContract
          value={form.getFieldValue("contract_id")}
          setValue={(value: string, data: Hired) => {
            setSelectedHired(data);
            setValue("contract_id", value);
            trigger("contract_id");
          }}
        />
      </Select>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 8,
            marginTop: 0,
          }}
        >
          <Button
            htmlType="submit"
            loading={isSubmitting}
            className="activity_creation-submit"
            type="primary"
          >
            Associar contrato
          </Button>
        </div>
      </div>
    </FormProvider>
  );
}

export default AssignContractCard;
