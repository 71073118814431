import { notification } from "antd";

type NotificationType = "success" | "info" | "warning" | "error";

type Props = {
  type: NotificationType;
  title: string;
  message: string;
};

export const toastAlert = ({ type, title, message }: Props) => {
  notification[type]({
    message: title,
    description: message,
  });
};
