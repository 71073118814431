import { message } from "antd";
import { useState } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

type MeasurementPartialProps = {
  activity_id?: number | string;

  percentage?: number;
  date?: string;
};

export const usePostMeasurementPartial = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [refresh, setRefresh] = useState<Date>();

  const postMeasurementPartial = async (data: MeasurementPartialProps) => {
    try {
      setIsFetching(true);

      await api.post("/v1/measurement-partials/create", {
        ...data,
      });

      setRefresh(new Date());
      toastAlert({
        type: "success",
        message: "A solicitação ja pode ser vista na listagem.",
        title: "Medição parcial solicitada",
      });
    } catch (error: any) {
      console.log(error);
      toastAlert({
        type: "error",
        message: error.response.data.message || "Confira sua conexão para tentar novamente.",
        title: "Erro ao criar o registro de medição parcial",
      });
    } finally {
      setIsFetching(false);
    }
  };

  return { postMeasurementPartial, isFetching, refresh };
};
