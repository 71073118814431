import { Button, Col, Row, Tabs } from "antd";
import { useState } from "react";
import SearchInput from "../../components/SearchInput";
import Layout from "../../components/Template/Layout";
import api from "../../services/Api";
import "./measurePage.scss";

import { useNavigate, useParams } from "react-router-dom";
import CollapseCard from "../../components/Template/Card";
import { useFetchHiredActivitiesByStatus } from "../../hooks/hiredOrEmployee/useFetchHiredActivitiesByStatus";
import ActivityTabs from "./components/ActivityTabs";
import EvidenceTabs from "./components/EvidenceTabs";
import MeasureTabs from "./components/MeasureTabs";
import { statusTabs } from "./utils/tabs";

function HiredActivitiesPage() {
  const { hired_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [dataTotal, setDataTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [currentStatus, setCurrentStatus] = useState("1");
  const [currentStatusType, setCurrentStatusType] = useState("activities");

  const [refresh, setRefresh] = useState<Date>();
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSearch = (search: string) => {
    if (search !== "") {
      setIsLoading(true);
      api
        .post(`/v1/hireds/search-activities/${hired_id}?current_status_type=${currentStatusType}&current_status=${currentStatus}`, {
          search: search,
        })
        .then((r) => {
          const data = r.data;

          const table = data.map((obj: any) => ({
            ...obj,
            key: obj.id,
            actions: (
              <>
                <Button type="primary" onClick={() => navigate("/activity/" + obj.id)}>
                  Abrir
                </Button>
              </>
            ),
          }));

          setDataTable(table);
          setDataTotal(table.length);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setRefresh(new Date());
    }
  };

  const { isFetching, statusCounts, validacaoConts , measurementCont } = useFetchHiredActivitiesByStatus({
    currentPage: current,
    currentStatus,
    currentStatusType,
    setDataTable,
    setDataTotal,
    hired_id,
    refresh,
  });

  const onChangeStatusType = (key: string) => {
    setCurrentStatusType(key);
    setCurrent(1);
    setSearch("");
  };

  const onChangeStatus = (key: string) => {
    setCurrentStatus(key);
    setCurrent(1);
    setSearch("");
  };

  return (
    <>
      <Layout pageTitle="Lista de Atividades da Contratada" breadcrumb={`Home ⟶ ${"Atividades da Contratada #" + hired_id}`}>
        <CollapseCard title="Tabela de atividades filtradas por">
          <Row>
            <Col span={24} className="mb-2 text-right">
              <SearchInput
                type="text"
                handleChange={handleChangeSearch}
                value={search}
                handleSearch={handleSearch}
                loading={isLoading}
                placeholder="Busque por uma descrição ou código"
              />
            </Col>
            <Col span={24}>
              <Tabs
                defaultActiveKey="activities"
                type="card"
                size="small"
                items={statusTabs}
                activeKey={currentStatusType}
                onChange={onChangeStatusType}
                className="fluit-tabs"
              />

              {currentStatusType === "activities" ? (
                <ActivityTabs
                  activeKey={currentStatus}
                  statusCounts={statusCounts}                
                  onChange={onChangeStatus}
                  dataSource={dataTable}
                  loading={isFetching || isLoading}
                  current={current}
                  setCurrent={setCurrent}
                  total={dataTotal}
                />
              ) : currentStatusType === "evidence" ? (
                <EvidenceTabs
                  activeKey={currentStatus}
                  validacaoConts={validacaoConts}
                  onChange={onChangeStatus}
                  dataSource={dataTable}
                  loading={isFetching || isLoading}
                  current={current}
                  setCurrent={setCurrent}
                  total={dataTotal}
                />
              ) : (
                <MeasureTabs
                  activeKey={currentStatus}
                  measurementCont={measurementCont}
                  onChange={onChangeStatus}
                  dataSource={dataTable}
                  loading={isFetching || isLoading}
                  current={current}
                  setCurrent={setCurrent}
                  total={dataTotal}
                />
              )}
            </Col>
          </Row>
        </CollapseCard>
      </Layout>
    </>
  );
}

export default HiredActivitiesPage;
