import { useCallback, useEffect, useState } from "react";
import api from "../../../config/axios";

// Tipagem para o retorno de `calculateTotals`
type ReportTotals = {
  total_presenter: number;
  total_approved: number;
  total_reproved: number;
  total_activities_value: number;
  consumption_percentage: number;
};

// Definindo o hook para buscar os totais do relatório
export const useFetchReportTotals = (bulletin_id: string | null) => {
  const [reportTotals, setReportTotals] = useState<ReportTotals | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const fetchReportTotals = useCallback(() => {
    setIsFetching(true);
    api
      .get(`/v1/measurement-bulletins/totals/${bulletin_id}`) // Ajuste a rota conforme a necessidade
      .then((response) => {
        setReportTotals(response.data);
      })
      .catch((err) => {
        console.error("Erro ao buscar totais do relatório:", err);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [bulletin_id]);

  useEffect(() => {
    if (bulletin_id) {
      fetchReportTotals();
    }
  }, [fetchReportTotals, bulletin_id]);

  return { reportTotals, isFetching };
};
