import { PlusOutlined } from "@ant-design/icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Form, Upload, UploadProps } from "antd";
import ImgCrop from "antd-img-crop";
import { useForm } from "react-hook-form";
import CollapseCard from "../../../components/Template/Card";
import { FormProvider } from "../../../components/form/FormProvider";
import { Input } from "../../../components/form/fields";
import { useUploadUserPhoto } from "../../../hooks/user/useUploadUserPhoto";
import { UserDataFormType, UserDataSchema } from "../../../schemas/userData";

import { useState } from "react";
import { useFetchUserInfo } from "../../../hooks/user/useFetchUserInfo";
import { useUpdateProfile } from "../../../hooks/user/useUpdateProfile";
import "../userProfile.scss";

export function UserDataCard() {
  const [form] = Form.useForm();
  const [photoIsHovered, setPhotoIsHovered] = useState(false);
  const methods = useForm<UserDataFormType>({
    resolver: zodResolver(UserDataSchema),
    defaultValues: {
      name: localStorage.getItem("user_name") || "",
      email: localStorage.getItem("user_email") || "",
    },
  });

  const props: UploadProps = {
    beforeUpload: (file) => {
      return false;
    },
  };
  const { handleSubmit } = methods;

  const { handleSubmitProfile, isFetching } = useUpdateProfile();

  const { userPhoto } = useFetchUserInfo();

  const { handleUploadPhoto } = useUploadUserPhoto();

  return (
    <CollapseCard title="Alterar dados" className="profile-card">
      <FormProvider
        methods={methods}
        form={form}
        labelCol={{ xs: 4 }}
        className="profile-card_container"
        onFinish={handleSubmit(handleSubmitProfile)}
      >
        <div className="profile-card_container_avatar">
          <ImgCrop rotationSlider onModalOk={handleUploadPhoto} modalTitle="Editar imagem" modalCancel="Cancelar" modalOk="Enviar">
            <Upload name="avatar" listType="picture-circle" showUploadList={false} {...props} capture className="profile-card_container_avatar_text">
              {!!userPhoto ? (
                <div
                  className="profile-card_container_avatar_photo"
                  onMouseEnter={() => setPhotoIsHovered(true)}
                  onMouseLeave={() => setPhotoIsHovered(false)}
                >
                  <img src={userPhoto} className="profile-card_container_avatar_photo_img" alt="avatar" />
                  {photoIsHovered ? (
                    <div className="profile-card_container_avatar_photo_img_hovered">
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </ImgCrop>
        </div>

        <div className="profile-card_container_input-list">
          <Input name="name" label="Nome" placeholder="Digite o nome" />
          <Input name="email" label="E-mail" placeholder="Digite o e-mail" type="email" />
          <div className="profile-card_container_input-list_actions">
            <Button type="primary" htmlType="submit" loading={isFetching} disabled={isFetching} id="submitUserData">
              Salvar alterações
            </Button>
          </div>
        </div>
      </FormProvider>
    </CollapseCard>
  );
}
