import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../config/axios";

type Props = {
  cwpId?: string | number;
  current: number;
  refresh?: Date;
  setTables: any;
  search?: string;
};

export const useFetchIwps = ({
  cwpId,
  current,
  refresh,
  setTables,
  search,
}: Props) => {
  const [total, setTotal] = useState(0);

  const [isFetching, setIsFetching] = useState(false);

  const returnIwps = useCallback(() => {
    setIsFetching(true);
    api
      .get(`v1/iwps/cwp/${cwpId}?page=${current}&search=${search}`)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data;
          const table = data.map((obj: any) => ({
            ...obj,
            key: obj.id,
          }));

          setTables((prevState: any) => ({
            ...prevState,
            ipws: {
              ...prevState.ipws,
              data: table,
              total: response.data.total,
            },
          }));
          setTotal(response.data.total);
        }
      })
      .finally(() => setIsFetching(false));
  }, [cwpId, current, refresh, search]);

  useEffect(() => {
    returnIwps();
  }, [returnIwps]);

  return {
    isFetching,
    total,
  };
};
