import { useState } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

type Props = {
  contract: number | string;
  activities: number[] | string[];
  handleConfirm: VoidFunction;
  errorMessage?: string;
};

export const useAddContractActivities = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refresh, setRefresh] = useState<Date>();

  const handleAddActivities = async ({
    contract,
    activities,
    handleConfirm,
    errorMessage,
  }: Props) => {
    if (activities.length === 0) {
      toastAlert({
        type: "warning",
        message: errorMessage || `Selecione uma atividade para atribuir.`,
        title: "Espere!",
      });
      return;
    }
    setIsSubmitting(true);
    await api
      .post(`/v1/contracts/${contract}/associate-activities`, {
        activity_ids: activities,
      })
      .then((response) => {
        handleConfirm();
        setRefresh(new Date());
        toastAlert({
          type: "success",
          message:
            activities.length === 1
              ? "A atividade foi atribuída e já pode ser vista na lista de atividades do contrato."
              : "As atividades foram atribuídas e já podem ser vistas na lista de atividades do contrato.",
          title:
            activities.length === 1
              ? "Nova atividade atribuída"
              : "Novas atividades atribuídas",
        });
      })
      .catch((error) => {
        toastAlert({
          type: "error",
          message:
            activities.length === 1
              ? "A atividade não foi atribuída. Confira sua conexão e tente novamente em alguns minutos."
              : "As atividades não foram atribuídas. Confira sua conexão e tente novamente em alguns minutos.",
          title: "Erro ao atribuir",
        });
      })
      .finally(() => setIsSubmitting(false));
  };

  return { handleAddActivities, refresh, isSubmitting };
};
