import { SearchOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import "./searchInput.scss";

type Props = {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearch: (search: string) => void;
  placeholder?: string;
  type: string;
  value: string;
  classe?: string;
  disabled?: boolean;
  loading?: boolean;
  divClasse?: string;
};
export default function SearchInput({
  handleChange,
  type,
  placeholder,
  value,
  classe,
  disabled = false,
  handleSearch,
  loading = false,
  divClasse,
}: Props) {
  return (
    <div className={`searchContainer${divClasse ? " " + divClasse : ""}`}>
      <input
        id="search-input"
        type={type}
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        className={`inputSearch${classe ? " " + classe : ""}`}
        disabled={disabled}
        onKeyDown={(event) => event.code === "Enter" && handleSearch(value)}
      />
      <Button
        id="searchButton"
        onClick={() => {
          handleSearch(value);
        }}
        className="searchButton ant-btn-primary"
        loading={loading}
        disabled={loading}
      >
        {!loading && <SearchOutlined />}
      </Button>
    </div>
  );
}
