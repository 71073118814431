import React, { useState, useEffect } from "react";
import { Typography, Spin, Row, Button, Upload } from "antd";
import { FileCard } from "../../../components/FileCard";
import { CloudUploadOutlined } from "@ant-design/icons";
import { useFetchActivityFiles } from "../../../hooks/files/useFetchActivityFiles";
import ModalEvidence from "../../../components/MeasureFileListCard/components/ModalEvidence/ModalEvidence";

const { Text } = Typography;

type FileUploadModalContentProps = {
  activityId: any;
  onFileListUpdate: (files: any[]) => void;
};

const FileUploadModalContent: React.FC<FileUploadModalContentProps> = ({ activityId, onFileListUpdate }) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [refreshFileList, setRefreshFileList] = useState<Date>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Buscar arquivos relacionados à atividade
  const { isLoadingFiles } = useFetchActivityFiles({
    activity_id: activityId,
    setFileList,
    refreshFileList,
  });

  useEffect(() => {
    onFileListUpdate(fileList);
  }, [fileList]);

  return (
    <div style={{ padding: "20px", display: "flex", gap: "20px" }}>
      {/* Coluna dos arquivos */}
      <div style={{ flex: 1 }}>
        {isLoadingFiles ? (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Spin />
          </div>
        ) : (
          <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
            {fileList && fileList.length > 0 ? (
              fileList.map((file: any) => <FileCard key={file.id} file={file} refreshList={() => setRefreshFileList(new Date())} isModel={false} />)
            ) : (
              <Text style={{ marginTop: "10px" }}>Nenhum arquivo anexado para esta atividade.</Text>
            )}
          </Row>
        )}
      </div>

      {/* Coluna do botão e modal */}
      <div>
        <Button icon={<CloudUploadOutlined />} onClick={() => setIsModalOpen(true)}>
          Anexar arquivos
        </Button>

        {isModalOpen && (
          <ModalEvidence
            activity_id={activityId}
            refreshList={() => setRefreshFileList(new Date())}
            isModalOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        )}
      </div>
    </div>
  );
};

export default FileUploadModalContent;
