import React from "react";
import { Button, Upload } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import type { UploadProps as AntUploadProps } from "antd";
import { useUploadExcelFile } from "../../../hooks/files/useUploadExcelFile";
import modeloExcelValidacao from "../../../assets/images/modeloExcelValidacao.png";
import ModeloExcel from "../../../assets/excel/modelo.xlsx";

type UploadFileProps = {
  activity_id?: string;
  onUploadSuccess: (data: any) => void;
};

const UploadFile: React.FC<UploadFileProps> = ({ activity_id, onUploadSuccess }) => {
  const { handleUpload, isUploading } = useUploadExcelFile();

  const uploadProps: AntUploadProps = {
    beforeUpload: (file) => false,
    showUploadList: false,
    onChange: (e) => handleUpload({ e, onUploadSuccess }),
  };
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = ModeloExcel;
    link.download = "Modelo_Excel_Validacao.xlsx";
    link.click();
  };
  return (
    <div className="upload-file-container">
      <div className="image-container">
        <img
          src={modeloExcelValidacao}
          alt="Modelo Excel de Validação"
          className="validation-image"
          onClick={handleDownload}
        />
      </div>
      <div className="button-container">
        <Upload {...uploadProps}>
          <Button icon={<CloudUploadOutlined />} loading={isUploading}>
            {isUploading ? "Carregando arquivo" : "Selecionar arquivos..."}
          </Button>
        </Upload>
        {/* <Button
          type="primary"
          className="download-button"
          onClick={handleDownload}
        >
          Baixar Modelo
        </Button> */}
      </div>
    </div>
  );
};

export default UploadFile;
