import { useCallback, useEffect, useState } from "react";
import api from "../../config/axios";
import { Activity } from "../../models/Activity";
import { Hired } from "../../models/Hired";

type Props = {
  hired?: Hired;
  refresh?: Date;
  refreshMany?: Date;
};

export const useFetchHiredActivities = ({
  hired,
  refresh,
  refreshMany,
}: Props) => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const returnHiredActivities = useCallback(() => {
    setIsFetching(true);
    api
      .get(`/v1/hireds/${hired?.id}/activities`)
      .then((response) => {
        const data = response.data.data;

        const table = data.map((obj: any) => ({
          ...obj,
          key: obj.id,
        }));
        setActivities(table);
      })
      .catch((err) => {
        //console.log(err);
      })
      .finally(() => {
        setIsFetching(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hired, refresh, refreshMany]);

  useEffect(() => {
    returnHiredActivities();
  }, [returnHiredActivities]);

  return {
    activities,
    isFetching,
  };
};
