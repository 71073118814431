import { Button, Modal } from "antd";
import "../login.scss";

function PolicyModal({
  isModalOpen,
  handleClose,
}: {
  isModalOpen: boolean;
  handleClose: VoidFunction;
}) {
  const handleAccept = () => {
    handleClose();
  };

  return (
    <Modal
      title="Aviso sobre o Uso de Cookies"
      open={isModalOpen}
      onOk={handleAccept}
      centered
      closable={false}
      footer={[
        <Button id="accept-cookies" key="submit" type="primary" onClick={handleAccept}>
          Aceitar
        </Button>,
      ]}
    >
      <span>
        Nós usamos cookies para melhorar sua experiência geral em nosso site.
        Clicando no botão "Aceitar" abaixo, você concorda que podemos usá-los
        para que tenha uma melhor experiência navegando na nossa aplicação.
      </span>
    </Modal>
  );
}

export default PolicyModal;
