import { Badge, Button, Carousel, Col, Image, Progress, Row, Spin, Table, Tabs, Typography, UploadFile } from "antd";

import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import CwpView from "../../components/CwpView";
import CollapseCard from "../../components/Template/Card";
import Layout from "../../components/Template/Layout";
import { useFetchWp } from "../../hooks/wps/useFetchWp";
import api from "../../services/Api";
import { columns } from "./utils/columns";
import "./wpViewPage.scss";

const { Title, Text } = Typography;

type Model = UploadFile & { show_name: string };

function WpViewPage() {
  const navigate = useNavigate();
  const { wp_id, project_id } = useParams();
  const [items, setItems] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [projectName, setProjectName] = useState(undefined);

  const [loadingActivities, setLoadingActivities] = useState(true);
  const [models, setModels] = useState<Model[]>([]);
  const [totalIwps, setTotalIwps] = useState(0);

  const groupByKey = (list: any, key: any) =>
    (list || []).reduce(
      (hash: any, obj: any) => ({
        ...hash,
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      }),
      {}
    );

  const fetchItems = async () => {
    if (wp) {
      setLoadingActivities(true);

      await api
        .get(`v1/wps/${wp_id}/activities`)
        .then((response) => {
          const activities = response.data.activities;
          const iwps = response.data.iwps;
          const concattedData = activities.data.concat(iwps);
          setTotalIwps(response.data.iwps.total);
          setTotal(activities.total || 0);
          const allData = groupByKey(concattedData, "type");
          const types = Object.keys(groupByKey(concattedData, "type"));
          var items = [];

          if (types.includes("EWP")) {
            const source = allData["EWP"].map((obj: any) => ({
              key: obj.id,
              atividades: obj.name,
              responsavel: obj.responsible_name || "-",
              status: obj.status_id,
              progresso: <Progress percent={100} status="active" />,
              actions: (
                <Button
                  type="primary"
                  onClick={() =>
                    navigate("/activity/" + obj.id, {
                      state: {
                        breadcrumb: ` > ${wp?.cwa_description} > ${wp?.name} > Atividade ${obj.name}`,
                      },
                    })
                  }
                >
                  Abrir
                </Button>
              ),
            }));
            items.push({
              label: <Badge status={allStatus(allData["EWP"])} text="EWP - Engenharia" />,
              key: "ewp",
              children: (
                <div style={{ overflow: "auto" }}>
                  <Table className="table-default-style" dataSource={source} columns={columns} style={{ minWidth: "600px" }} />
                </div>
              ),
            });
          }
          if (types.includes("PWP")) {
            const source = allData["PWP"].map((obj: any) => ({
              key: obj.id,
              atividades: obj.name,
              responsavel: obj.responsible_name || "-",
              status: obj.status_id,
              progresso: <Progress percent={100} status="active" />,
              actions: (
                <Button type="primary" onClick={() => navigate("/activity/" + obj.id)}>
                  Abrir
                </Button>
              ),
            }));
            items.push({
              label: <Badge status={allStatus(allData["PWP"])} text="PWP - Fornecimento" />,
              key: "pwp",
              children: (
                <div style={{ overflow: "auto" }}>
                  <Table className="table-default-style" dataSource={source} columns={columns} style={{ minWidth: "600px" }} />
                </div>
              ),
            });
          }
          if (types.includes("CWP")) {
            const source = allData["CWP"].map((obj: any) => ({
              ...obj,
              key: obj.id,
              atividades: obj.name,
              responsavel: obj.responsible_name || "-",
              status: obj.status_id,
              progresso: <Progress percent={100} status="active" />,
              actions: (
                <Button type="primary" onClick={() => navigate("/activity/" + obj.id)}>
                  Abrir
                </Button>
              ),
            }));
            items.push({
              label: <Badge status={allStatus(allData["CWP"])} text="CWP - Construção" />,
              key: "cwp",
              children: <CwpView setTotalIwps={setTotalIwps} cwpId={source[0].cwp_id} initialActivities={source} />,
            });
          }

          setItems(items);

          if (!allData["CWP"]) {
            fetchCwps();
          }
        })
        .finally(() => setLoadingActivities(false));
    }
  };

  const { wp, isFetchingWp } = useFetchWp({ wp_id: wp_id ? wp_id : "" });

  const fetchCwps = async () => {
    await api
      .get(`/v1/cwps/wp/${wp_id}`)
      .then((response) => {
        const items = [
          {
            label: <Badge status="error" text="CWP - Construção" />,
            key: "cwp",
            children: <CwpView setTotalIwps={setTotalIwps} cwpId={response.data?.data[0].id} initialActivities={[]} />,
          },
        ];
        const currentItems = items.filter((item) => item.key !== "cwp");

        setItems([...currentItems, ...items]);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchItems();
  }, [wp]);

  useEffect(() => {
    if (project_id)
      api.get("/v1/projects/" + project_id).then((response) => {
        if (response.status === 200) {
          const data = response.data;
          setProjectName(data.name);
        }
      });
  }, [project_id]);

  const allStatus = (array: any[]) => {
    if (array.every((obj: any) => obj.status_id === 3)) {
      return "success";
    } else if (array.some((obj: any) => obj.status_id === 2 || obj.status_id === 3)) {
      return "warning";
    } else {
      return "error";
    }
  };

  return (
    <>
      <Layout
        pageTitle="Atividades do WP"
        breadcrumb={
          projectName ? (
            `${projectName} ⟶ ${wp?.cwa_description} ⟶ ${wp?.name}`
          ) : (
            <>
              <Spin size="small" className="breadcrumb-loading" /> {` ⟶ CWA ⟶ ${wp?.name}`}
            </>
          )
        }
      >
        <Row gutter={16} className="page-row">
          <Col xs={24} md={8} xxl={6} className="mb-2">
            <div className="wp-view-data">
              {models && models.length > 0 ? (
                <CollapseCard title="Modelos">
                  <Carousel autoplay>
                    {models.map((model) => (
                      <Image src={model.url} alt={model.show_name} />
                    ))}
                  </Carousel>
                </CollapseCard>
              ) : null}
              <CollapseCard title="Total">
                {loadingActivities ? (
                  <Spin />
                ) : (
                  <Row>
                    <Col sm={12} className="text-center">
                      <Col>
                        <Title level={2} className="total-card">
                          {total}
                        </Title>
                      </Col>
                      <Col>
                        <Text>Atividades</Text>
                      </Col>
                    </Col>

                    <Col sm={12} className="text-center">
                      <Col>
                        <Title level={2} className="total-card">
                          {totalIwps}
                        </Title>
                      </Col>
                      <Col>
                        <Text>IWPs</Text>
                      </Col>
                    </Col>
                  </Row>
                )}
              </CollapseCard>
            </div>
          </Col>
          <Col xs={24} md={16} xxl={18} className="mb-2">
            <div className="wp-view-data">
              <CollapseCard title="Descrição" className="h-100-import">
                <Text>{isFetchingWp ? <Spin /> : wp?.description}</Text>
              </CollapseCard>

              <CollapseCard title="Tabela de Atividades">
                {loadingActivities ? (
                  <Spin />
                ) : (
                  <Row>
                    <Col span={24}>
                      {items.length > 0 ? (
                        <Tabs defaultActiveKey="1" type="card" size={"small"} items={items} className="mt-3 fluit-tabs" />
                      ) : (
                        <>Sem atividades</>
                      )}
                    </Col>
                  </Row>
                )}
              </CollapseCard>
            </div>
          </Col>
        </Row>
      </Layout>
    </>
  );
}

export default WpViewPage;
