import { useState } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

type Props = {
  e: any;
  activity_id?: string;
  relation?: "activityFiles" | "activityModels";
  execution_date?: string;
  setRefreshFileList: React.Dispatch<React.SetStateAction<Date>>;
  validation?: any;
  dynamic_validation?: any;
};

export const useUploadActivityFile = () => {
  const [isUploading, setIsUploading] = useState(false);

  const handleUpload = async ({ e, activity_id, relation, execution_date, setRefreshFileList, validation, dynamic_validation }: Props) => {
    setIsUploading(true);
    await api
      .post(
        "/v1/files/upload",
        {
          file: e.file,
          relation: relation || "activityFiles",
          id: activity_id,
          validation: validation,
          execution_date: execution_date,
          dynamic_validation: dynamic_validation,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        toastAlert({
          type: "success",
          message: `Arquivo já pode ser visto na aba arquivos`,
          title: "Upload concluído",
        });

        setRefreshFileList(new Date());
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message;
        if (errorMessage) {
          toastAlert({
            type: "error",
            message: `${errorMessage}`,
            title: "Não foi possível fazer upload",
          });
        } else {
          toastAlert({
            type: "error",
            message: "Confira o tamanho do arquivo e sua conexão com a internet.",
            title: "Não foi possível fazer upload",
          });
        }
      });

    setIsUploading(false);
  };

  return { handleUpload, isUploading };
};
