import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Form } from "antd";
import { useForm } from "react-hook-form";
import { useFetchActivityStatus } from "../../hooks/activity/useFetchActivityStatus";

import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { useCreateWp } from "../../hooks/wps/useCreateWp";
import { useFetchDisciplines } from "../../hooks/wps/useFetchDisciplines";
import { SubOwnerWpSchema, WpSchema } from "../../schemas/wp";
import AddOrEditWp from "../AddOrEditWP";
import { FormProvider } from "../form/FormProvider";
import "./WpAdd.scss";

type Props = {
  handleCancel: () => void;
  afterSubmit?: () => void;
  setHideMainModal: (newValue: boolean) => void;
  isSubOwner: boolean;
};

export function WpAdd({
  handleCancel,
  afterSubmit,
  setHideMainModal,
  isSubOwner,
}: Props) {
  const schema = isSubOwner ? SubOwnerWpSchema : WpSchema;
  type FormType = z.infer<typeof schema>;

  const [form] = Form.useForm();
  const { cwa_id } = useParams();
  const methods = useForm<FormType>({
    resolver: zodResolver(schema),
    defaultValues: {
      activities: [] as string[],
      newActivitiesToCreate: [],
    },
  });

  const {
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;
  const values = watch();

  const { disciplines } = useFetchDisciplines();
  const { status } = useFetchActivityStatus();
  const { createWp, status: httpstatus } = useCreateWp();

  const handleCreateWp = (values: any) => {
    createWp({ formData: values, cwa_id });
  };

  useEffect(() => {
    if (httpstatus === 200) {
      handleCancel();
      afterSubmit && afterSubmit();
    }
  }, [httpstatus]);

  return (
    <FormProvider
      methods={methods}
      form={form}
      labelCol={{ xs: 5, md: 8 }}
      className="form-create-container-WP"
      onFinish={handleSubmit(handleCreateWp)}
    >
      <AddOrEditWp
        type="create"
        form={form}
        disciplineList={disciplines}
        statusList={status}
        formData={{
          values,
          errors,
          setValue,
        }}
        setHideMainModal={setHideMainModal}
      />
      <div className="container-buttons-WP-add">
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button
          htmlType="submit"
          loading={isSubmitting}
          disabled={isSubmitting}
          type="primary"
          id="create-wp"
        >
          Criar WP
        </Button>
      </div>
    </FormProvider>
  );
}
