import { useCallback, useEffect, useState } from "react";
import api from "../../config/axios";

import { Button } from "antd";
import { useNavigate } from "react-router-dom";

type Props = {
  currentStatus: string | number;
  current: string | number;
  project_id?: string | number;
  setDataTable: any;
  refresh?: Date;
};

export const useFetchMeasureByStatus = ({ currentStatus, current, project_id, setDataTable, refresh }: Props) => {
  const [projects, setProjects] = useState([]);
  const [total, setTotal] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();

  const fetchItemsByStatus = useCallback(() => {
    setIsFetching(true);

    api
      .get(`/v1/measure/status/${currentStatus}?page=${current}&project=${project_id}`)
      .then((response) => {
        const data = response.data.data.data;

        setProjects(response.data.projects);

        const table = data.map((obj: any) => ({
          ...obj,
          key: obj.id,
          reason_for_refusal: obj.reason_for_refusal,
          activity_code: `${obj.activity.code}`,
          status: obj.status,
          actions: (
            <>
              <Button type="primary" onClick={() => navigate("/measure/" + obj.id)}>
                Abrir
              </Button>
            </>
          ),
        }));

        setDataTable(table);
        setTotal(response.data.data.total);
      })
      .finally(() => {
        setIsFetching(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStatus, current, project_id, refresh]);

  useEffect(() => {
    fetchItemsByStatus();
  }, [fetchItemsByStatus]);

  const searchMeasure = (search: string) => {
    if (!!search) {
      setIsFetching(true);
      api
        .post(`/v1/measure/${currentStatus}/search`, {
          search,
        })
        .then((response) => {
          const data = response.data;
          const table = data.map((obj: any) => ({
            ...obj,
            key: obj.id,
            actions: (
              <>
                <Button type="primary" onClick={() => navigate("/measure/" + obj.id)}>
                  Abrir
                </Button>
              </>
            ),
          }));
          setDataTable(table);
          setTotal(response.data.length);
        })
        .catch((err) => {})
        .finally(() => {
          setIsFetching(false);
        });
    } else {
      fetchItemsByStatus();
    }
  };

  return {
    total,
    projects,
    isFetching,
    searchMeasure,
  };
};
