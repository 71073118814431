import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Form } from "antd";
import { useState } from "react";
import { useForm } from "react-hook-form";
import CollapseCard from "../../../components/Template/Card";
import { FormProvider } from "../../../components/form/FormProvider";
import { Input } from "../../../components/form/fields";
import { PasswordFormType, PasswordSchema } from "../../../schemas/password";
import api from "../../../services/Api";
import { toastAlert } from "../../../utils/toastAlert";
import "../userProfile.scss";

export function PasswordCard() {
  const [form] = Form.useForm();
  const methods = useForm<PasswordFormType>({
    resolver: zodResolver(PasswordSchema),
  });
  const { handleSubmit } = methods;
  const [showPassword, setShowPassword] = useState(false);

  const [loadingPass, setLoadingPass] = useState(false);
  const [loadingForgot, setLoadingForgot] = useState(false);

  const handleSubmitNewPassword = async (values: PasswordFormType) => {
    setLoadingPass(true);
    await api
      .post("/v1/user/change-password", {
        current_password: values.currentPassword,
        new_password: values.newPassword,
      })
      .then((r) => {
        toastAlert({
          type: "success",
          message: `Sua senha foi atualizada com sucesso`,
          title: "Senha atualizada",
        });
      })
      .catch((err) => {
        toastAlert({
          type: "error",
          message:
            err.response.data.message || "Não foi possível alterar sua senha",
          title: "Erro ao mudar senha",
        });
      })
      .finally(() => setLoadingPass(false));
  };

  const handleForgotPasswordSubmit = () => {
    setLoadingForgot(true);

    api
      .post("/v1/forgot-password", {
        email: localStorage.getItem("user_email"),
      })
      .then((response) => {
        if (response.status === 200) {
          toastAlert({
            type: "success",
            message: `Você receberá um e-mail com as instruções para recuperar sua senha.`,
            title: "E-mail enviado!",
          });
        }
      })
      .catch((error) => {
        toastAlert({
          type: "error",
          message: `Verifique seu endereço de email e sua conexão com a internet e tente novamente.`,
          title: "Erro ao enviar e-mail",
        });
        setLoadingForgot(false);
      })
      .finally(() => {
        setLoadingForgot(false);
      });
  };

  return (
    <CollapseCard title="Alterar senha" className="profile-card">
      <FormProvider
        methods={methods}
        form={form}
        labelCol={{ span: 5 }}
        className="profile-card_container_input-list"
        onFinish={handleSubmit(handleSubmitNewPassword)}
      >
        <Input
          name="currentPassword"
          label="Senha atual"
          placeholder="Digite sua senha atual"
          type={showPassword ? "text" : "password"}
          suffix={
            <PasswordSuffix
              showPassword={showPassword}
              onClick={() => setShowPassword(!showPassword)}
            />
          }
        />
        <Input
          name="newPassword"
          label="Nova senha"
          placeholder="Digite sua nova senha"
          type={showPassword ? "text" : "password"}
          suffix={
            <PasswordSuffix
              showPassword={showPassword}
              onClick={() => setShowPassword(!showPassword)}
            />
          }
        />
        <div className="profile-card_container_input-list_actions">
          <Button
            className="submit pb-1"
            loading={loadingForgot}
            onClick={handleForgotPasswordSubmit}
          >
            Esqueci minha senha
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            loading={loadingPass}
            disabled={loadingPass}
            id="submitChangePassword"
          >
            Alterar senha
          </Button>
        </div>
      </FormProvider>
    </CollapseCard>
  );
}

function PasswordSuffix({
  showPassword,
  onClick,
}: {
  showPassword: boolean;
  onClick: VoidFunction;
}) {
  return (
    <div onClick={onClick} style={{ cursor: "pointer" }}>
      {showPassword ? (
        <EyeInvisibleOutlined style={{ paddingRight: 8 }} />
      ) : (
        <EyeOutlined style={{ paddingRight: 8 }} />
      )}
    </div>
  );
}
