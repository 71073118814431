import { Modal } from "antd";
import { Hired } from "../../../../models/Hired";
import { Input, Button, Form as AntForm } from "antd";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { schema_edit_hired } from "../../validation/schema_edit_hired";
import { useUpdateHired } from "../../../../hooks/hiredOrEmployee/useUpdateHired";

type Props = {
  hired: Hired;
  isOpen: boolean;
  handleClose: () => void;
};

export default function ModalEditHired({ hired, isOpen, handleClose }: Props) {
  const { updateHired, isUpdating } = useUpdateHired();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema_edit_hired),
    defaultValues: {
      name: "Construtor 1",
      description: "Este é o Construtor 1",
      email: "c1@teste.com",
      phone: "+554199999999",
      tenant_id: 1,
      user_id: "b387dfc8-6306-43bd-9f3b-e943352a2d26",
    },
  });

  // Função para lidar com o submit
  const onSubmit = (formData: any) => {
    updateHired({ formData, id: hired.id });
    handleClose();
  };

  return (
    <Modal title={`Mudar porcentagem de medição`} footer={[<></>]} width={"50%"} open={isOpen} onCancel={handleClose}>
      <div className="container-radio mt-2">
        <AntForm onFinish={handleSubmit(onSubmit)} layout="vertical">
          {/* Nome */}
          <AntForm.Item label="Nome" validateStatus={errors.name ? "error" : ""} help={errors.name?.message}>
            <Controller name="name" control={control} render={({ field }) => <Input {...field} />} />
          </AntForm.Item>

          {/* Descrição */}
          <AntForm.Item label="Descrição" validateStatus={errors.description ? "error" : ""} help={errors.description?.message}>
            <Controller name="description" control={control} render={({ field }) => <Input {...field} />} />
          </AntForm.Item>

          {/* Email */}
          <AntForm.Item label="E-mail" validateStatus={errors.email ? "error" : ""} help={errors.email?.message}>
            <Controller name="email" control={control} render={({ field }) => <Input {...field} />} />
          </AntForm.Item>

          {/* Telefone */}
          <AntForm.Item label="Telefone" validateStatus={errors.phone ? "error" : ""} help={errors.phone?.message}>
            <Controller name="phone" control={control} render={({ field }) => <Input {...field} />} />
          </AntForm.Item>

          {/* Botão de envio */}
          <AntForm.Item>
            <Button type="primary" htmlType="submit" style={{ top: "15px", right: "0", position: "absolute" }}>
              Enviar
            </Button>
          </AntForm.Item>
        </AntForm>
      </div>
    </Modal>
  );
}
