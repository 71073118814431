import { FaEdit } from "react-icons/fa";
import { Hired } from "../../../../models/Hired";
import ModalEditHired from "../ModalEditHired/ModalEditHired";
import { useState } from "react";

type Props = {
  hired: Hired;
  setRefresh: () => void;
};

export default function EditHired({ hired, setRefresh }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleClose = () => {
    setIsModalOpen(false);
    setRefresh();
  };
  return (
    <div
      style={{
        border: "1px solid  #929191",
        padding: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "5px",
        cursor: "pointer",
      }}
    >
      <ModalEditHired hired={hired} handleClose={handleClose} isOpen={isModalOpen} />
      <FaEdit size={20} style={{ marginLeft: "4px" }} onClick={() => setIsModalOpen(true)} />
    </div>
  );
}
