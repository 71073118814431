import { useCallback, useEffect, useState } from "react";
import api from "../../config/axios";
import { Discipline } from "../../models/Discipline";

export const useFetchDisciplines = () => {
  const [disciplines, setDisciplines] = useState<Discipline[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const returnDisciplines = useCallback(() => {
    setIsFetching(true);
    api
      .get(`/v1/wps/disciplines`)
      .then((response) => {
        setDisciplines(response.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsFetching(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    returnDisciplines();
  }, [returnDisciplines]);

  return {
    disciplines,
    isFetching,
    error,
  };
};
