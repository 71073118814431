import { useState } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

type Props = {
  refetch: () => void;
};

export const useApproveActivity = ({ refetch }: Props) => {
  const [isApproving, setIsApproving] = useState(false);

  const approveActivity = async (id: number | string) => {
    try {
      setIsApproving(true);
      await api.post(`v1/measurement-bulletins-activities/approve/${id}`);
      toastAlert({
        type: "success",
        message: "Atividade aprovada com sucesso.",
        title: "Aprovação de Atividade",
      });
    } catch (error: any) {
      toastAlert({
        type: "error",
        message: "Erro ao aprovar a atividade. Tente novamente.",
        title: "Erro na Aprovação",
      });
    } finally {
      setIsApproving(false);
      refetch();
    }
  };

  return { approveActivity, isApproving };
};
