import { Card, Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MeasureCreation from "../../components/MeasureCreation";
import Layout from "../../components/Template/Layout";
import api from "../../services/Api";
import "./measurePage.scss";

function MeasureViewPage() {
  const { measure_id } = useParams();
  const [roles, setRoles] = useState<string[]>([]);
  const [breadcrumb, setBreadcrumb] = useState("");
  useEffect(() => {
    api.get(`/v1/user/roles`).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        setRoles(data.roles);
      }
    });
  }, []);
  useEffect(() => {
    if (measure_id) {
      api.get("/v1/measure/" + measure_id).then((response) => {
        const data = response.data;
        const info = data.activity.information;
        const iwp = info.type === "IWP" ? ` ⟶ ${info.joined_wp_name}` : "";

        const menu = `${info.project} ⟶ CWA-${info.cwa_code} ⟶ ${info.wp_name} ${iwp} ${
          info.percentual_group_category ? ` ⟶ ${info.percentual_group_category}` : ""
        } ⟶ ${info.name} `;
        setBreadcrumb(menu);

        //  setDefaultDate(dayjs(data?.measurement_date));
      });
    }
  }, [measure_id]);
  return (
    <>
      <Layout pageTitle="Detalhes da Medição" breadcrumb={`Medições ⟶ ${breadcrumb} ⟶ Item de Medição #${measure_id}`}>
        {roles.length === 0 ? (
          <Row className="page-row">
            <Col xs={24} lg={12}>
              <Card>
                <Spin />
              </Card>
            </Col>
          </Row>
        ) : (
          <MeasureCreation hasList userRoles={roles} />
        )}
      </Layout>
    </>
  );
}

export default MeasureViewPage;
