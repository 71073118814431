import { useState } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

export const useDownloadFile = () => {
  const [isFetching, setIsFetching] = useState(false);

  const handleDownload = (id: number) => {
    setIsFetching(true);
    api
      .get(`/v1/files/get-url/id/${id}`)
      .then((response) => {
        window.open(response.data, "_blank");
      })
      .catch((error) => {
        toastAlert({
          type: "error",
          message: `Verifique sua conexão com a internet para tentar novamente.`,
          title: "Erro ao fazer download",
        });
      })
      .finally(() => setIsFetching(false));
  };
  return { handleDownload, isFetching };
};
