import { Popconfirm, Tooltip } from "antd";
import { ReactNode, useState } from "react";

export default function PopConfirmIcon({
  title,
  description,
  onConfirm,
  icon,
  disabled,
  className,
}: {
  title: string;
  description: string;
  onConfirm: (setConfirmIsOpen: React.Dispatch<React.SetStateAction<boolean>>) => void;
  icon: ReactNode;
  disabled: boolean;
  className?: string;
}) {
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);

  return (
    <Popconfirm
      disabled={disabled}
      title={title}
      description={description}
      onConfirm={() => onConfirm(setConfirmIsOpen)}
      onCancel={() => setConfirmIsOpen(false)}
      popupVisible={confirmIsOpen}
      onOpenChange={(open) => setConfirmIsOpen(open)}
      okText="Sim"
      cancelText="Não"
      className={className}
    >
      <Tooltip title={title}>{icon}</Tooltip>
    </Popconfirm>
  );
}
