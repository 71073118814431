import { useState } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

type Props = {
  e: any;
};
export const useUploadModelWp = ({
  afterSubmit,
}: {
  afterSubmit: (e: any) => void;
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [model, setModel] = useState();
  const [refreshFileList, setRefreshFileList] = useState<Date>();

  const handleUploadModel = async ({ e }: Props) => {
    setIsUploading(true);
    await api
      .post(
        "/v1/wps/upload-model",
        {
          file: e.file,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(async (response) => {
        setModel(response.data);
        let currentFileList = e.fileList;
        const lastIndex = currentFileList.length - 1;
        currentFileList[lastIndex] = {
          ...currentFileList[lastIndex],
          id: response.data,
        };
        afterSubmit(currentFileList);

        setRefreshFileList(new Date());
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message;
        if (errorMessage) {
          toastAlert({
            type: "error",
            message: `${errorMessage}`,
            title: "Não foi possível fazer upload",
          });
        } else {
          toastAlert({
            type: "error",
            message:
              "Confira o tamanho do arquivo e sua conexão com a internet.",
            title: "Não foi possível fazer upload",
          });
        }
      });

    setIsUploading(false);
  };

  return { handleUploadModel, isUploading, refreshFileList, model, setModel };
};
