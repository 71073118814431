import { Button, Grid, Modal, Table, TableColumnsType, Typography } from "antd";
import { useEffect, useState } from "react";
import api from "../../services/Api";
import { DataType } from "../../types/wp";
import { toastAlert } from "../../utils/toastAlert";
import "./wpDelete.scss";

const { useBreakpoint } = Grid;
const { Text } = Typography;

type Props = {
  isModalOpen: boolean;
  handleCancel: VoidFunction;
  afterSubmit: VoidFunction;
  selectedItems: DataType[];
};

const columns: TableColumnsType<DataType> = [
  { title: "Nome", dataIndex: "name", key: "name" },
  { title: "Disciplina", dataIndex: "description", key: "description" },
  { title: "Subdisciplina", dataIndex: "subdisciplina", key: "subdisciplina" },
  { title: "Status", dataIndex: "state", key: "state" },
];

export function WPDelete({
  isModalOpen,
  handleCancel,
  afterSubmit,
  selectedItems,
}: Props) {
  const [submitting, setSubmitting] = useState(false);
  const { xxl, xl } = useBreakpoint();
  const [scroll, setScroll] = useState<number | undefined>(570);

  const handleSubmit = async () => {
    setSubmitting(true);

    await api
      .delete("/v1/wps/many", {
        data: { wps: selectedItems.map((item) => item.id) },
      })
      .then((response) => {
        toastAlert({
          type: "success",
          title: "Deletados com sucesso",
          message: "WPs selecionados foram deletados com sucesso",
        });
        afterSubmit();
        handleCancel();
      })
      .catch((e) =>
        toastAlert({
          type: "error",
          title: "Erro ao deletar",
          message: e.response.data.message,
        })
      )
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    handleResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xl, xxl]);

  const handleResize = () => {
    if (xxl) {
      setScroll(570);
    } else if (xl) {
      setScroll(260);
    } else {
      setScroll(undefined);
    }
  };

  return (
    <Modal
      title={`Deseja remover todos estes WPs?`}
      closeIcon={true}
      footer={null}
      open={isModalOpen}
      onCancel={handleCancel}
      rootClassName="wp-delete-modal-root"
      className="wp-delete-modal"
    >
      <div className="wp-delete-modal_list">
        <Table
          className="table-default-style mt-1"
          columns={columns}
          dataSource={selectedItems}
          pagination={false}
          locale={{ emptyText: "Sem dados" }}
          size="small"
          scroll={{ y: scroll }}
          style={{ minWidth: "600px" }}
        />

        <Text>
          OBS: Só serão removidos os WPs cujas atividades não têm medição.
        </Text>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 8,
            marginTop: 0,
          }}
        >
          <Button onClick={handleCancel}>Cancelar</Button>
          <Button
            onClick={handleSubmit}
            loading={submitting}
            disabled={submitting}
            type="primary"
            id="delete-all-wps"
          >
            Remover WPs
          </Button>
        </div>
      </div>
    </Modal>
  );
}
