import { Button, Input, Modal } from "antd";
import "./editPercentage.scss";
import { useEffect, useState } from "react";
import { usePostEditPercentage } from "../../../../hooks/activity/usePostEditPercentage";

type Props = {
  activity_id: string;
  isModalOpen: boolean;
  handleCancel: () => void;
  setRefreshActivity: (date: Date) => void;
};

export default function EditPercentage({ activity_id, isModalOpen, handleCancel, setRefreshActivity }: Props) {
  const [percentage, setPercentage] = useState<any>();
  const { isFetching, postPercentage, refresh } = usePostEditPercentage();
  useEffect(() => {
    setRefreshActivity(new Date());
  }, [refresh]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9,]/g, "");
    if (+value > 100) {
      setPercentage(100);
    } else {
      setPercentage(value);
    }
  };
  return (
    <Modal
      title={`Editar porcentagem completa`}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          onClick={() => {
            postPercentage({ activity_id, percentage });
            handleCancel();
          }}
          type="primary"
          danger
        >
          Editar
        </Button>,
      ]}
      width={"50%"}
      open={isModalOpen}
      onCancel={handleCancel}
    >
      <div>
        <ul>
          <li>50,01% a 74,99%: aplica-se avanço de 50% para medição.</li>
          <li>75% a 89,99%: aplica-se avanço de 75% para medição.</li>
          <li>90 a 99,99%: aplica-se avanço de 90% para medição.</li>
          <li>100%: aplica-se o avanço final para medição</li>
        </ul>
        <Input onChange={(e) => handleChange(e)} value={percentage} maxLength={6} />
      </div>
    </Modal>
  );
}
