import { Form, Select } from "antd";

type Value = {
  label: number | string;
  value: number | string;
};
type Props = {
  handleChange: (e: string) => void;
  label?: string;
  values: Value[];
  showAll?: boolean;
};
export default function ReportSelect({ handleChange, label, values, showAll = true }: Props) {
  const defaultValues = [{ value: 0, label: "Todos" }];
  const all = [...defaultValues, ...values];
  return (
    <>
      <div className="container-month-select">
        <Form layout="vertical">
          <Form.Item label={label}>
            <Select defaultValue={showAll ? "Todos" : ""} style={{ width: 250 }} onChange={handleChange} options={showAll ? all : values} />
          </Form.Item>
        </Form>
      </div>
    </>
  );
}
