import {
  Button,
  Col,
  Pagination,
  PaginationProps,
  Row,
  Table,
  TableColumnsType,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import SearchInput from "../../components/SearchInput";
import CollapseCard from "../../components/Template/Card";
import Layout from "../../components/Template/Layout";
import { useReadNotifications } from "../../hooks/notification/useReadNotifications";
import { Notification } from "../../models/Notification";
import api from "../../services/Api";
import "./notificationPage.scss";
const { Title } = Typography;

interface DataType {
  key: React.Key;
  content: string;
  children?: DataType[];
}

function NotificationPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [dataTable, setDataTable] = useState<any>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [refreshNotifications, setRefreshNotifications] = useState(new Date());

  const {
    isLoading: isSubmitting,
    refresh,
    markAsRead,
    markAsReadMany,
    id,
  } = useReadNotifications();

  const columns: TableColumnsType<DataType> = [
    { title: "Descrição", dataIndex: "content", key: "content" },
    { title: "", dataIndex: "actions", key: "actions" },
  ];

  const [search, setSearch] = useState("");
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    getData();
    const interval = setInterval(() => getData(), 60000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, refreshNotifications, refresh]);

  const handleSearch = (search: string) => {
    setIsLoading(true);
    if (search !== "") {
      api
        .post("/v1/notification/search", {
          search: search,
        })
        .then((r) => {
          const data = r.data;
          const table = data.map((obj: Notification) => ({
            ...obj,
            key: obj.id,
            ...(!obj.read && {
              actions: (
                <>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => markAsRead({ notification: obj })}
                    disabled={isSubmitting && obj.id === id}
                  >
                    obj
                  </Button>
                </>
              ),
            }),
          }));

          setDataTable(table);
          setTotal(table.length);
          setPageSize(table.length);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      getData();
    }
  };

  const onChange: PaginationProps["onChange"] = (page) => {
    setCurrent(page);
  };

  async function getData() {
    setIsLoading(true);

    await api
      .get(`/v1/notification/list?page=${current}`)
      .then((response) => {
        if (response.status === 200) {
          const data = response?.data?.notifications?.data || [];

          const table = data.map((obj: any) => ({
            ...obj,
            key: obj.id,

            ...(!obj.read && {
              actions: (
                <>
                  {!isSubmitting && (
                    <Button
                      type="primary"
                      onClick={() => markAsRead({ notification: obj })}
                      disabled={isSubmitting && obj.id === id}
                    >
                      Marcar como lida
                    </Button>
                  )}
                </>
              ),
            }),
          }));

          setDataTable(table);
          setUnreadCount(response?.data?.not_read);
          setTotal(response?.data?.notifications?.total);
          setPageSize(response?.data?.notifications?.per_page);
        }
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <>
      <Layout
        pageTitle="Lista de Notificações"
        breadcrumb="Notificações"
        actions={
          <Button
            className="button-secondary"
            disabled={isSubmitting}
            onClick={() => markAsReadMany({ notifications: dataTable })}
          >
            Marcar todas como lidas
          </Button>
        }
      >
        <Row gutter={16} className="page-row">
          <Col xs={24} md={6} className="mb-2">
            <CollapseCard title="Total">
              {!!total ? (
                <Title level={2} className="total-card">
                  {total}
                </Title>
              ) : null}
            </CollapseCard>
          </Col>
          <Col xs={24} md={18} className="mb-2">
            <CollapseCard
              title={`Tabela de Notificações`}
              className="card-table-no-padding"
            >
              <Row>
                <Col span={24}>
                  <Col span={24} className="mb-2 text-right">
                    <SearchInput
                      type="text"
                      handleChange={handleChangeSearch}
                      value={search}
                      handleSearch={handleSearch}
                      loading={isLoading}
                      placeholder="Busque por uma descrição"
                    />
                  </Col>
                  <Table
                    columns={columns}
                    dataSource={dataTable}
                    pagination={false}
                    loading={isLoading}
                    size="small"
                    locale={{ emptyText: "Sem dados" }}
                  />
                </Col>

                <Col
                  span={24}
                  className="text-center mt-2"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "5px",
                  }}
                >
                  <span>{`${unreadCount || 0} ${
                    unreadCount && unreadCount === 1
                      ? "notificação não lida"
                      : "notificações não lidas"
                  }`}</span>
                  <Pagination
                    current={current}
                    onChange={onChange}
                    total={total}
                    pageSize={pageSize}
                    showSizeChanger={false}
                  />
                </Col>
              </Row>
            </CollapseCard>
          </Col>
        </Row>
      </Layout>
    </>
  );
}

export default NotificationPage;
