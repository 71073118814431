import { Button, InputNumber, Pagination, Select, Row, Spin, Switch, Table } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import api from "../../services/Api";
import { toastAlert } from "../../utils/toastAlert";
import "./contract.scss";
import { columns } from "../CreateContract/utils/columns";
import { useFetchWpsByType } from "../../hooks/wps/useFetchWpsByType";
import { PaginationProps } from "antd/lib/pagination";

import { wpsOptions } from "../CreateContract/utils/wpsOptions";

type Props = {
  // start_date: string | undefined;
  // end_date: string | undefined;
  description: string | undefined;
  amount: string | undefined;
  active: boolean;
  hired_id: string | undefined;
  activity_id: string | undefined;
  wps_delete: any;
  wps_associate: any;
};

function ContractCard({ contractId, afterSubmit, onClosePopup }: { contractId: number; afterSubmit?: VoidFunction; onClosePopup?: VoidFunction }) {
  const isPopup = !!onClosePopup;
  const initialValues = {
    description: undefined,
    amount: undefined,
    active: true,
    hired_id: undefined,
    activity_id: undefined,
    wps_delete: [],
    wps_associate: [],
  };
  const [typeWp, setTypeWp] = useState("");
  const [toggle, setToggle] = useState(false);
  const [current, setCurrent] = useState(1);
  const onChange: PaginationProps["onChange"] = (page) => {
    setCurrent(page);
  };
  const [loading, setLoading] = useState(false);
  const [contractWps, setWps] = useState();
  const [formData, setFormData] = useState<Props>(initialValues);

  useEffect(() => {
    api
      .get(`/v1/contracts/${contractId}`)
      .then((response) => {
        const data = response.data.contract;
        setWps(response.data.wps.map((item: any) => ({ ...item, key: item.id })));

        setFormData({
          description: data.description,
          amount: data.amount,
          active: data.active,
          hired_id: data.hired_id,
          activity_id: data.schedule_activity_id,
          wps_delete: [],
          wps_associate: [],
        });
      })
      .catch((error) => {});
  }, [contractId]);
  const { wps, total, isFetching } = useFetchWpsByType({ type: typeWp, current });

  const handleEditContract = () => {
    setLoading(true);
    api
      .put(`/v1/contracts/${contractId}`, {
        // start_date: new Date(formData.start_date as string),
        // end_date: new Date(formData.end_date as string),
        description: formData.description,
        amount: formData.amount,
        active: formData.active,
        status: "Assinado",
        wps_delete: formData.wps_delete,
        wps_associate: formData.wps_associate,
        wp_type: typeWp,
      })
      .then((response) => {
        toastAlert({
          type: "success",
          message: `O contrato teve suas informações atualizadas.`,
          title: `Contrato atualizado com sucesso `,
        });

        !!afterSubmit && afterSubmit();
        !!onClosePopup && onClosePopup();
      })
      .catch((error) => {
        toastAlert({
          type: "error",
          message: `Verifique sua conexão e tente novamente.`,
          title: `Não foi possível atualizar o contrato `,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setFormData((prevState) => ({
        ...prevState,
        wps_delete: selectedRowKeys,
      }));
    },
  };
  const rowSelectionAssociate = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setFormData((prevState) => ({
        ...prevState,
        wps_associate: selectedRowKeys,
      }));
    },
  };
  return JSON.stringify(initialValues) === JSON.stringify(formData) ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "160px",
        width: "100%",
      }}
    >
      <Spin />
    </div>
  ) : (
    <div className="container-edit-contract">
      <Row
        style={{
          display: "flex",
          gap: 8,
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <TextArea
          placeholder="Descrição"
          value={formData.description}
          onChange={(e) =>
            setFormData((prevState) => ({
              ...prevState,
              description: e.target.value,
            }))
          }
        />

        <InputNumber
          placeholder="Valor"
          prefix="R$"
          value={formData.amount}
          onChange={(e) =>
            setFormData((prevState) => ({
              ...prevState,
              amount: e?.toString(),
            }))
          }
          style={{ width: "100%" }}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: 8,
              width: "100%",
            }}
          >
            <span>Ativo?</span>
            <Switch
              checked={formData.active}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  active: !prevState.active,
                }))
              }
            />
          </div>
        </div>
      </Row>
      <div>
        <Button type="primary" onClick={() => setToggle(!toggle)}>
          {toggle ? "Desassociar WPs" : "Associar WPs"}
        </Button>
      </div>
      {toggle && (
        <div className="container-associate-wps">
          <span>Adicionar WPs do contrato:</span>
          <Select
            options={wpsOptions}
            onChange={(e) => {
              setTypeWp(e);
            }}
            placeholder="Tipo de IWP"
          />
          {!!wps && (
            <div className="container-table-edit">
              <div className="table-container">
                <Table
                  columns={columns}
                  dataSource={wps}
                  pagination={false}
                  loading={isFetching}
                  size="small"
                  rowSelection={{
                    type: "checkbox",
                    ...rowSelectionAssociate,
                  }}
                />
              </div>
              <div className="container-pagination">
                <Pagination current={current} onChange={onChange} total={total} pageSize={30} showSizeChanger={false} />
              </div>
            </div>
          )}
        </div>
      )}

      {!!contractWps && !toggle && (
        <div className="container-table-edit">
          <span>Remover WPs do contrato:</span>
          <div className="table-container">
            <Table
              columns={columns}
              dataSource={contractWps}
              pagination={false}
              loading={loading}
              size="small"
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
            />
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          gap: 8,
          marginTop: isPopup ? 8 : 0,
          justifyContent: "end",
        }}
      >
        {!!onClosePopup ? <Button onClick={onClosePopup}>Cancelar</Button> : null}
        <Button loading={loading} onClick={handleEditContract} className="activity_creation-submit" type="primary">
          Editar Contrato
        </Button>
      </div>
    </div>
  );
}

export default ContractCard;
