import { NavigateFunction } from "react-router-dom";
import api from "../../../../services/Api";
import { toastAlert } from "../../../../utils/toastAlert";

export const navigateToWP = async ({
  cwaId,
  navigate,
  project_id,
  afterLoading,
}: {
  cwaId: number | string;
  navigate: NavigateFunction;
  project_id: string | undefined;
  afterLoading?: VoidFunction;
}) => {
  let canNavigate = true;

  await api
    .get("/v1/cwas/" + cwaId + "/wps?page=1")
    .then((response) => {
      if (response.status === 200) {
        if (!response.data.data || response.data.data.length === 0) {
          toastAlert({
            type: "error",
            message: `CWA clicado não tem WPs a serem exibidos.`,
            title: "WPs não encontrados",
          });
          canNavigate = false;
          return;
        }
      }
    })
    .finally(() => afterLoading && afterLoading());

  if (canNavigate) navigate("/project/" + project_id + "/wps/" + cwaId);
};

export const navigateToActivities = async ({
  wpId,
  navigate,
  project_id,
  afterLoading,
}: {
  wpId: any;
  navigate: NavigateFunction;
  project_id: string | undefined;
  afterLoading?: VoidFunction;
}) => {
  // let canNavigateStatus = true;

  navigate("/project/" + project_id + "/wp/view/" + wpId);
  // await api
  //   .get(`v1/wps/${wpId}/activities`)
  //   .then((response) => {
  //     if (response?.status === 200) {
  //       if (!response.data.data || response.data.data.length === 0) {
  //         toastAlert({
  //           type: "error",
  //           message: `WP clicado não tem atividades a serem exibidas.`,
  //           title: "Atividades não encontradas",
  //         });
  //         canNavigateStatus = false;
  //         return;
  //       }
  //     } else {
  //       toastAlert({
  //         type: "error",
  //         message: `WP clicado não tem atividades a serem exibidas.`,
  //         title: "Atividades não encontradas",
  //       });
  //       canNavigateStatus = false;
  //       return;
  //     }
  //   })
  //   .finally(() => afterLoading && afterLoading());

  // if (canNavigateStatus)
  //   navigate("/project/" + project_id + "/wp/view/" + wpId);
};
