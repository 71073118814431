/* eslint-disable react-hooks/exhaustive-deps */
import { PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox } from "antd";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { FieldErrors, FieldValues } from "react-hook-form";

import { useParams } from "react-router-dom";
import { Activity } from "../../../models/Activity";
import { CreatedActivity } from "../../../schemas/activity";
import { NewActivityDataType } from "../../../types/newActivity";
import ModalCreateWpActivity from "../../ModalCreateWpActivity";
import { Select } from "../../form/fields";

type Props = {
  type: string;
  index?: number;
  formData: {
    values: any;
    errors: FieldErrors<FieldValues> | undefined;
    setValue: any;
    name?: string;
    help?: (value: any) => string | undefined;
  };
  defaultActivities: Activity[];
  activities: Activity[];
  setHideMainModal: (newValue: boolean) => void;
};

type SelectActivities = Activity & { isDefault?: boolean };
type CheckboxChangeProps = {
  selectedItem: string | number;
  checked: boolean;
  isDefaultActivity?: boolean;
  isNewActivity?: boolean;
};

export function ActivityCheckbox({ type, index, formData, defaultActivities, activities, setHideMainModal }: Props) {
  const { project_id } = useParams();
  const { values, setValue, name } = formData;
  const isEdit = type === "edit" && index !== undefined;

  const [addedActivities, setAddedActivities] = useState<any[]>([]);
  const [newActivityData, setNewActivityData] = useState<NewActivityDataType>({
    showModal: false,
    wpData: {
      id: undefined,
      subDiscipline: undefined,
      projectInformationId: project_id || "",
    },
    currentSavedData: undefined,
  });

  const isChecked = (id: string) => values?.activities?.findIndex((item: string) => item === id) !== -1;

  const addedActivityIsChecked = (uniqueName: string) =>
    values?.newActivitiesToCreate.findIndex((item: CreatedActivity) => item.uniqueName === uniqueName) !== -1;

  const selectActivities: SelectActivities[] = isEdit
    ? (activities || []).concat(
        (defaultActivities || []).map((defaultActivity) => ({
          ...defaultActivity,
          isDefault: true,
        }))
      )
    : defaultActivities;

  useEffect(() => {
    if (isEdit) {
      activities.forEach((activity) => {
        const isInArray = isChecked(activity.id);
        if (!isInArray) handleCheckboxChange({ selectedItem: activity.id, checked: true });
      });
    }
  }, [activities]);

  useEffect(() => {
    if (!!newActivityData.currentSavedData && newActivityData.wpData.id === (formData.values.id || "")) {
      const currentData = values.newActivitiesToCreate || [];
      setValue("newActivitiesToCreate", [...currentData, newActivityData.currentSavedData]);
      setAddedActivities((prevState) => [...prevState, newActivityData.currentSavedData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(newActivityData.currentSavedData)]);

  const handleCheckboxChange = ({ selectedItem, checked, isDefaultActivity }: CheckboxChangeProps) => {
    let prevIds = values?.activities as any;
    if (checked) {
      prevIds = [...prevIds, selectedItem];
    } else {
      prevIds = prevIds.filter((id: string) => id !== selectedItem);
    }
    setValue("activities", prevIds);

    if (isDefaultActivity === undefined) {
      return;
    }

    if (isEdit) {
      if (isDefaultActivity) {
        let prevArray = values?.activitiesToCreate as any;

        if (checked) {
          prevArray = [...prevArray, selectedItem];
        } else {
          prevArray = prevArray.filter((id: string) => id !== selectedItem);
        }
        setValue("activitiesToCreate", prevArray);
      } else {
        let prevArray = values?.activitiesToDelete as any;

        if (checked) {
          prevArray = prevArray.filter((id: string) => id !== selectedItem);
        } else {
          prevArray = [...prevArray, selectedItem];
        }
        setValue("activitiesToDelete", prevArray);
      }
    }
  };

  const handleCheckboxChangeAddedActivity = ({ selectedItem, checked }: CheckboxChangeProps) => {
    let prevArray = values?.newActivitiesToCreate as CreatedActivity[];

    if (checked) {
      const newItemIndex = addedActivities.findIndex((item) => item.uniqueName === selectedItem);
      prevArray = [...prevArray, addedActivities[newItemIndex]];
    } else {
      prevArray = prevArray.filter((item) => item.uniqueName !== selectedItem);
    }
    setValue("newActivitiesToCreate", prevArray);
  };

  return (
    <>
      <Select label="Atividades" name={`${name || ""}activities`}>
        {addedActivities.length > 0 || selectActivities.length > 0 ? (
          <div className="activities-WP-container">
            <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}>
              {addedActivities?.map((activity) => (
                <Checkbox
                  key={activity.uniqueName}
                  className="checkbox-wp"
                  checked={addedActivityIsChecked(activity.uniqueName)}
                  onChange={(e) =>
                    handleCheckboxChangeAddedActivity({
                      selectedItem: activity.uniqueName,
                      checked: e.target.checked,
                    })
                  }
                >
                  {activity.name}
                </Checkbox>
              ))}
              {selectActivities.length > 0
                ? selectActivities?.map((activity) => (
                    <Checkbox
                      key={activity.id}
                      className="checkbox-wp"
                      checked={isChecked(activity.id)}
                      onChange={(e) =>
                        handleCheckboxChange({
                          selectedItem: activity.id,
                          checked: e.target.checked,
                          isDefaultActivity: !!activity.isDefault,
                        })
                      }
                    >
                      {activity.name}
                    </Checkbox>
                  ))
                : null}
            </motion.div>
          </div>
        ) : null}

        {!!formData.values.subDiscipline ? (
          <Button
            onClick={() => {
              setNewActivityData((prevState) => ({
                ...prevState,
                showModal: true,
                wpData: {
                  ...prevState.wpData,
                  id: formData.values.id || "",
                  subDiscipline: formData.values.subDiscipline,
                },
              }));
              setTimeout(() => {
                setHideMainModal(true);
              }, 50);
            }}
          >
            <PlusOutlined />
            Adicionar manualmente
          </Button>
        ) : null}
      </Select>

      {newActivityData.showModal ? (
        <ModalCreateWpActivity
          isModalOpen={newActivityData.showModal}
          handleCancel={() => {
            setHideMainModal(false);
            setNewActivityData((prevState) => ({
              ...prevState,
              showModal: false,
            }));
          }}
          handleSubmit={(formData) => {
            setHideMainModal(false);
            setNewActivityData((prevState) => ({
              ...prevState,
              showModal: false,
              currentSavedData: formData,
            }));
          }}
          wpFormData={newActivityData.wpData}
        />
      ) : null}
    </>
  );
}
