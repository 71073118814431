import { DatePicker, Form } from "antd";

type Props = {
  setValue: (e: any) => void;
  label?: string;
};

export default function DatePickerComponent({ setValue, label }: Props) {
  const { RangePicker } = DatePicker;
  return (
    <Form layout="vertical">
      <Form.Item label={label}>
        <RangePicker
          format="DD/MM/YYYY"
          allowEmpty={[true, true]}
          allowClear
          onChange={(date) => {
            setValue(date);
          }}
          style={{ width: "100%" }}
        />
      </Form.Item>
    </Form>
  );
}
