import { useEffect, useState } from "react";
import api from "../../config/axios";

type Props = {
  activity_ids: (string | number)[];
};

export const useFetchFilesForActivities = ({ activity_ids }: Props) => {
  const [fileCounts, setFileCounts] = useState<Record<string, number>>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchFileCounts = async () => {
      if (activity_ids.length === 0) return;
      setIsLoading(true);
      try {
        const response = await api.post(`/v1/files/fetchFilesByActivityIds`, {
          activity_ids,
        });
        setFileCounts(response.data || {});
      } catch (error) {
        console.error("Erro ao buscar contagens de arquivos:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFileCounts();
  }, [activity_ids]);

  return { fileCounts, isLoading };
};
