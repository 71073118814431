import {
  Button,
  Col,
  Pagination,
  Row,
  Spin,
  Table,
  TableColumnsType,
  Typography,
} from "antd";
import { PaginationProps } from "antd/lib/pagination";
import { useEffect, useState } from "react";
import { FaEdit, FaPlus, FaTrashAlt } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import ModalAddOrEditWp from "../../components/ModalAddOrEditWp";
import SearchInput from "../../components/SearchInput";
import CollapseCard from "../../components/Template/Card";
import Layout from "../../components/Template/Layout";
import { WPDelete } from "../../components/WPDelete";
import api from "../../services/Api";
import { DataType } from "../../types/wp";
import { toastAlert } from "../../utils/toastAlert";
import { NavigateButton } from "./components/NavigateButton";
import "./wpPage.scss";

const { Title } = Typography;

const columns: TableColumnsType<DataType> = [
  { title: "Código", dataIndex: "name", key: "name" },
  {
    title: "Nome Alternativo",
    dataIndex: "alternative_name",
    key: "alternative_name",
  },
  { title: "Disciplina", dataIndex: "disciplina", key: "disciplina" },
  { title: "Subdisciplina", dataIndex: "subdisciplina", key: "subdisciplina" },
  { title: "Status", dataIndex: "state", key: "state" },
  { title: "", dataIndex: "actions", width: 200, key: "actions" },
];

function WpPage() {
  const { cwa_id } = useParams();
  const [projectName, setProjectName] = useState("");

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [current, setCurrent] = useState(1);
  const [cwas, setCwa] = useState("");
  const [dataTable, setDataTable] = useState<any>([]);
  const [fetchingData, setFetchingData] = useState(true);
  const [total, setTotal] = useState(0);
  const [refresh, setRefresh] = useState(new Date());
  const [selectedItems, setSelectedItems] = useState<DataType[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedItems(selectedRows);
    },
    getCheckboxProps: (record: DataType) => ({
      name: record.name,
    }),
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, refresh]);

  async function getData() {
    setFetchingData(true);

    await api
      .get("/v1/cwas/" + cwa_id + "/wps?page=" + current)
      .then((response) => {
        if (response.status === 200) {
          api
            .get("/v1/projects/" + response?.data?.data[0]?.project_id)
            .then((response) => {
              if (response.status === 200) {
                setProjectName(response.data.name);
              }
            });

          const data = response.data.data;

          setCwa(`${response.data.data[0].cwa_description}`);

          const table = data.map((obj: any) => ({
            ...formattedObj(obj),
            actions: (
              <Actions
                id={obj.id}
                handleEdit={() => handleEditOrDeleteOne("edit", obj)}
                handleDelete={() => handleEditOrDeleteOne("delete", obj)}
                cwa_id={cwa_id}
              />
            ),
          }));

          setDataTable(table);
          setTotal(response.data.total);
          setSelectedItems([]);
        }
      })
      .finally(() => setFetchingData(false));
  }
  // search states and functions

  const [search, setSearch] = useState("");
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSearch = (search: string) => {
    setFetchingData(true);
    if (search !== "") {
      api
        .post("/v1/cwas/" + cwa_id + "/wps/search", { search: search })
        .then((response) => {
          const data = response.data;

          const table = data?.map((obj: any) => ({
            ...formattedObj(obj),
            actions: (
              <Actions
                id={obj.id}
                handleEdit={() => handleEditOrDeleteOne("edit", obj)}
                handleDelete={() => handleEditOrDeleteOne("delete", obj)}
                cwa_id={cwa_id}
              />
            ),
          }));

          setDataTable(table);
          setTotal(data.length);
          setSelectedItems([]);
        })
        .finally(() => {
          setFetchingData(false);
        });
    } else {
      getData();
    }
  };

  // end of search

  const onChange: PaginationProps["onChange"] = (page) => {
    setCurrent(page);
  };

  const handleEditOrDelete = (
    type: "edit" | "delete",
    showAnyway?: boolean
  ) => {
    if (selectedItems.length === 0 && !showAnyway) {
      toastAlert({
        type: "warning",
        title: "Espere!",
        message: `Selecione algum WP para poder ${
          type === "edit" ? "editar" : "deletar"
        }`,
      });
    } else {
      type === "edit" ? setShowEditModal(true) : setDeleteModalOpen(true);
    }
  };

  const handleEditOrDeleteOne = (type: "edit" | "delete", obj: any) => {
    setSelectedItems([formattedObj(obj)]);
    handleEditOrDelete(type, true);
  };

  return (
    <>
      <Layout
        pageTitle="WP - Work Package"
        breadcrumb={
          projectName && cwas ? (
            `${projectName} ⟶ ${cwas} ⟶ WP`
          ) : (
            <>
              <Spin size="small" className="breadcrumb-loading" />{" "}
              {` ⟶ CWA #${cwas} ⟶ WP`}
            </>
          )
        }
      >
        <Row gutter={16} className="page-row">
          <Col xs={24} md={6} className="mb-2">
            <CollapseCard title="Total">
              {!!total ? (
                <Title level={2} className="total-card">
                  {total}
                </Title>
              ) : null}
            </CollapseCard>
          </Col>
          <Col xs={24} md={18} className="mb-2">
            <CollapseCard
              title="Tabela de WP"
              className="card-table-no-padding"
            >
              <Row>
                <Col span={24} style={{ overflow: "auto" }}>
                  <Col span={24} className="wp-actions">
                    <Button
                      type="primary"
                      className="wp-actions-btn"
                      onClick={() => handleEditOrDelete("delete")}
                      id="delete-wp"
                    >
                      <FaTrashAlt size={12} color="white" />
                      Excluir
                    </Button>
                    <Button
                      type="primary"
                      className="wp-actions-btn"
                      onClick={() => handleEditOrDelete("edit")}
                      id="edit-wp"
                    >
                      <FaEdit />
                      Editar
                    </Button>
                    <Button
                      type="primary"
                      className="wp-actions-btn"
                      onClick={() => setShowCreateModal(true)}
                      id="create_WP"
                    >
                      <FaPlus color="white" />
                      Criar
                    </Button>
                    <SearchInput
                      type="text"
                      handleChange={handleChangeSearch}
                      value={search}
                      handleSearch={handleSearch}
                      loading={fetchingData}
                      placeholder="Busque por código, nome, ou descrição"
                      classe="wp-search"
                      divClasse="wp-actions_search"
                    />
                  </Col>

                  <Col span={24} style={{ overflow: "auto" }}>
                    <Table
                      className="table-default-style"
                      columns={columns}
                      rowSelection={{
                        type: "checkbox",
                        ...rowSelection,
                        selectedRowKeys: selectedItems.map((item) => item.key),
                      }}
                      dataSource={dataTable}
                      loading={fetchingData}
                      pagination={false}
                      // scroll={{ y: 350 }}
                      locale={{ emptyText: "Sem dados" }}
                      size="small"
                      style={{ minWidth: "700px" }}
                    />
                  </Col>
                </Col>

                <Col
                  span={24}
                  className="text-center mt-4"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "5px",
                  }}
                >
                  <Pagination
                    current={current}
                    onChange={onChange}
                    total={total}
                    pageSize={30}
                    showSizeChanger={false}
                  />
                </Col>
              </Row>
            </CollapseCard>
          </Col>
        </Row>
      </Layout>

      {deleteModalOpen ? (
        <WPDelete
          selectedItems={selectedItems}
          afterSubmit={() => {
            setRefresh(new Date());
            setSelectedItems([]);
            setCurrent(1);
            setSearch("");
          }}
          isModalOpen={deleteModalOpen}
          handleCancel={() => {
            setDeleteModalOpen(false);
            setSelectedItems([]);
          }}
        />
      ) : null}

      {showCreateModal ? (
        <ModalAddOrEditWp
          isModalOpen={showCreateModal}
          handleCancel={() => setShowCreateModal(false)}
          type="create"
          afterSubmit={() => {
            setRefresh(new Date());
            setSelectedItems([]);
            setSearch("");
          }}
        />
      ) : null}

      {showEditModal ? (
        <ModalAddOrEditWp
          selectedItems={selectedItems}
          isModalOpen={showEditModal}
          handleCancel={() => {
            setShowEditModal(false);
            setSelectedItems([]);
          }}
          type="edit"
          afterSubmit={() => {
            setRefresh(new Date());
            setSelectedItems([]);
            setSearch("");
          }}
        />
      ) : null}
    </>
  );
}

const Actions = ({
  id,
  handleEdit,
  handleDelete,
  cwa_id,
}: {
  id: number;
  handleEdit: VoidFunction;
  handleDelete: VoidFunction;
  cwa_id?: string;
}) => {
  const navigate = useNavigate();
  const { project_id } = useParams();

  const navigateToActivities = async ({
    wpId,
    afterLoading,
  }: {
    wpId: any;
    afterLoading?: VoidFunction;
  }) => {
    navigate("/project/" + project_id + "/wp/view/" + wpId);
    // await api
    //   .get(`v1/wps/${wpId}/activities`)
    //   .then((response) => {
    //     if (!response.data.data || response.data.data.length === 0) {
    //       toastAlert({
    //         type: "error",
    //         message: `WP clicado não tem atividades a serem exibidas.`,
    //         title: "Atividades não encontradas",
    //       });

    //       return;
    //     } else {
    //       navigate("/project/" + project_id + "/wp/view/" + wpId);
    //     }
    //   })
    //   .catch(() => {
    //     toastAlert({
    //       type: "error",
    //       message: `WP clicado não tem atividades a serem exibidas.`,
    //       title: "Atividades não encontradas",
    //     });

    //     return;
    //   })
    //   .finally(() => afterLoading && afterLoading());
  };

  return (
    <div className="wp-row-actions">
      <NavigateButton
        navFunction={navigateToActivities}
        funcProps={{ wpId: id, navigate, project_id }}
      />

      <Button
        icon={<FaEdit />}
        onClick={handleEdit}
        className="wp-row-actions-edit gray-btn"
      />

      <Button
        type="default"
        icon={<FaTrashAlt />}
        onClick={handleDelete}
        className="wp-row-actions-delete gray-btn"
      />
    </div>
  );
};

const formattedObj = (obj: any) => ({
  ...obj,
  key: obj.id,
  name: obj.name,
  alternative_name: obj.alternative_name,
  disciplina: obj.discipline_name,
  subdisciplina: obj.sub_discipline_name,
  state: obj.status,
});

export default WpPage;
