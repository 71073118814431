import { useState } from "react";
import api from "../../config/axios";
import { DataType } from "../../types/wp";
import { toastAlert } from "../../utils/toastAlert";

type Props = {
  type: string;
  requisition_id?: number | string;
  select_requisitions?: DataType[];
};
export const useApproveRefuseRequisition = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [refresh, setRefresh] = useState<Date>();

  const approveOrRefuse = async ({
    type,
    requisition_id,
    select_requisitions,
  }: Props) => {
    const typeName = type === "refuse" ? "recusar" : "aprovar";
    try {
      setIsFetching(true);

      if (select_requisitions) {
        await api.post(`/v1/requisitions/${type}`, {
          select_requisitions: select_requisitions,
        });
      } else {
        await api.post(`/v1/requisitions/${type}`, {
          requisition_id: requisition_id,
        });
      }

      setRefresh(new Date());
    } catch (error: any) {
      toastAlert({
        type: "error",
        message: `Não foi possível ${typeName}`,
        title: `Erro ao ${typeName} requisição`,
      });
    } finally {
      setIsFetching(false);
    }
  };

  return { approveOrRefuse, isFetching, refresh };
};
