import { useEffect, useState } from "react";
import api from "../../services/Api";
import SelectInputSearch from "../SelectInputSearch";
import "./contract.scss";

let getActivitiesSearch: AbortController;

export function SelectActivity({
  value,
  setValue,
  id = "selectActivity",
}: {
  value: any;
  setValue: (value: string) => void;
  id?: string;
}) {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [activityList, setActivityList] = useState<any[] | undefined>();

  useEffect(() => {
    getActivitiesData();
  }, []);

  const handleSearch = () => {
    setLoading(true);

    if (getActivitiesSearch) getActivitiesSearch.abort();
    getActivitiesSearch = new AbortController();

    if (search !== "") {
      api
        .post(
          "/v1/activity/all/search-without-contract",
          { search },
          { signal: getActivitiesSearch?.signal }
        )
        .then((r) => {
          if (r.status === 200) {
            setActivityList(
              r.data.map((hired: any) => ({
                value: hired.id,
                label: hired.name,
              }))
            );
          }
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      getActivitiesData();
    }
  };

  async function getActivitiesData() {
    setLoading(true);

    await api
      .get(`/v1/activity/activities-without-contract`)
      .then((response) => {
        if (response.status === 200) {
          setActivityList(
            response.data.data.map((hired: any) => ({
              value: hired.id,
              label: hired.name,
            }))
          );
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <SelectInputSearch
      id={id}
      placeholder="Selecione uma atividade"
      onChange={setValue}
      value={value}
      onSearch={(value: string) => setSearch(value)}
      search={search}
      options={activityList || []}
      getData={handleSearch}
      loading={loading}
    />
  );
}
