import { Button, Modal } from "antd";
import "./modalEvidence.scss";
import UploadEvidence from "./components/UploadEvidence/UploadEvidence";
import { useFetchActivityValidations } from "../../../../hooks/validations/useFetchActivityValidations";
import ValidationSelect from "./components/ValidationSelect/ValidationSelect";
import { useState } from "react";
import { ValidationEvidence } from "../../../../models/ValidationEvidence";

type Props = {
  refreshList: any;
  activity_id?: string;
  isModalOpen: boolean;
  onClose: () => void;
};
export default function ModalEvidence({ refreshList, activity_id, isModalOpen, onClose }: Props) {
  const { activityValidations, isFetchingActivityValidations } = useFetchActivityValidations({
    activityId: activity_id,
    refresh: null,
    loadingInitialValue: true,
  });
  const [validation, setValidation] = useState<ValidationEvidence | null>(null);

  return (
    <>
      <Modal
        title="Anexe uma evidência"
        open={isModalOpen}
        onOk={onClose}
        onCancel={onClose}
        footer={[
          <Button key="back" onClick={onClose}>
            Cancelar
          </Button>,
        ]}
      >
        {activityValidations && !validation ? (
          <ValidationSelect validations={activityValidations.validation_evidences} setValidation={setValidation} />
        ) : (
          <UploadEvidence onClose={onClose} refreshList={refreshList} activity_id={activity_id} validation={validation} />
        )}
      </Modal>
    </>
  );
}
