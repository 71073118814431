import { useCallback, useEffect, useState } from "react";
import api from "../../config/axios";

type Props = {
  contract_id?: number | string;
};

export const useFetchBmByContract = ({ contract_id }: Props) => {
  const [bms, setBms] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const returnBmsByContract = useCallback(
    () => {
      setIsFetching(true);
      api
        .get(`/v1/measurement-bulletins/contract/${contract_id}`)
        .then((response) => {
          const data = response.data;
          setBms(data);
        })
        .catch((err) => {
          //  setError(err);
        })
        .finally(() => {
          setIsFetching(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contract_id]
  );

  useEffect(() => {
    returnBmsByContract();
  }, [returnBmsByContract]);

  return {
    bms,
    isFetching,
  };
};
