import { useState } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

type HiredFormData = {
  name?: string;
  email?: string;
  phone?: string;
  description?: string;
  user_id?: string;
  tenant_id?: number;
};

type Props = {
  formData: HiredFormData;
  id: number;
};

export const useUpdateHired = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [refresh, setRefresh] = useState<Date>();

  const updateHired = async ({ formData, id }: Props) => {
    try {
      setIsUpdating(true);

      await api.put(`v1/hireds/${id}`, {
        ...formData,
      });

      toastAlert({
        type: "success",
        message: "Dados atualizados com sucesso.",
        title: "O registro do contratado foi atualizado.",
      });

      setRefresh(new Date());
    } catch (error: any) {
      toastAlert({
        type: "error",
        message: "Não foi possível atualizar os dados, tente novamente.",
        title: "Erro ao atualizar o contratado",
      });
    } finally {
      setIsUpdating(false);
    }
  };

  return { updateHired, isUpdating, refresh };
};
