import { useState } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

type Props = {
  wps: {
    id: number | string;
    wpCode: string;
    description: string;
    status: number;
    modelsToCreate: number[];
    modelsToDelete: number[];
    activitiesToCreate: string[];
    activitiesToDelete: string[];
    justification: string;
  }[];
};

export const useEditWp = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [refresh, setRefresh] = useState<Date>();
  const [status, setStatus] = useState(0);

  const editWp = async ({ wps }: Props) => {
    setIsFetching(true);
    await api
      .patch(`/v1/wps/update-many`, {
        wps,
      })
      .then((r) => {
        setStatus(200);
        toastAlert({
          type: "success",
          message: r.data || "Alterações no WP foram realizadas com sucesso.",
          title: "Sucesso",
        });
        setRefresh(new Date());
      })
      .catch((error: any) => {
        toastAlert({
          type: "error",
          message:
            "Não foi possível editar o WP, verifique sua conexão e tente novamente.",
          title: "Erro ao atualizar",
        });
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  return { editWp, isFetching, refresh, status };
};
