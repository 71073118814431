import { Progress, TableColumnsType } from "antd";
import { statusList } from "../../../constants/status";

export type TableType = {
  ipws: { data: any; isLoading: boolean; isLoadingId: any; total: number };
  activities: {
    data: any;
    isLoading: boolean;
    isLoadingId: any;
    total: number;
  };
};

export const iwpColumns: TableColumnsType<any> = [
  { title: "IWP", dataIndex: "description", key: "description" },
  { title: "", dataIndex: "actions", width: 110, key: "actions" },
];

export const iwpColumnsXxl: TableColumnsType<any> = [
  { title: "IWP", dataIndex: "name", key: "name" },
  { title: "Descrição", dataIndex: "description", key: "description" },
  {
    title: "Status",
    dataIndex: "percentual_complete",
    width: 220,
    key: "actions",
    render: (name, record) => (
      <div className="percentual_complete">{<Progress percent={name} />}</div>
    ),
  },
];

export const activityColumns: TableColumnsType<any> = [
  { title: "Atividades do CWP", dataIndex: "atividades", key: "atividades" },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (name, record) => (
      <div>
        {statusList[statusList.findIndex((item) => item.value === name)]?.label}
      </div>
    ),
  },
  { title: "Responsável", dataIndex: "responsavel", key: "responsavel" },

  { title: "", dataIndex: "actions", width: 110, key: "actions" },
];
