import { useCallback } from "react";
import api from "../../config/axios";
import dayjs from "dayjs";
type Props = {
  page: number;
  refresh?: Date;
  startDate?: string;
  endDate?: string;
  cwa: number | string;
  project: number | string;
  status: number | string;
  hired: string;
  contract: string;
};

export const useExportEngineeringOrderXLSX = () => {
  const exportEngineeringOrderXLSX = useCallback(
    async ({
      page,
      status,
      startDate,
      endDate,
      cwa,
      project,
      hired,
      contract,
    }: Props) => {
      try {
        const response = await api.get(
          `/v1/report/export?startDate=${startDate}&endDate=${endDate}&page=${page}&cwa=${cwa}&project=${project}&status=${status}&hired=${hired}&contract=${contract}`,
          {
            responseType: "blob",
            headers: {
              "Content-Type":
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            },
          }
        );
        
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        
        const url = window.URL.createObjectURL(blob);
        
        const a = document.createElement("a");
        a.href = url;
        a.download = `[FLUIT]-Relatório-${dayjs().format("DD-MM-YYYY-HH-mm")}.xlsx`; 
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error exporting Report XLSX:", error);
      }
    },
    []
  );

  return exportEngineeringOrderXLSX;
};
