import { Form } from "antd";
import { ReactNode } from "react";
import { useFormContext } from "react-hook-form";

type Props = {
  label: string;
  name: string;
  children: ReactNode;
  helpClassName?: string;
  error?: any | undefined;
  help?: React.ReactNode;
};

export default function Select({
  label,
  name,
  children,
  helpClassName,
  error,
  help,
}: Props) {
  const {
    formState: { errors },
  } = useFormContext();

  const formError = error || errors[name];
  const formHelp = help
    ? help
    : formError
    ? (formError?.message as string)
    : undefined;

  return (
    <Form.Item
      name={name}
      label={label}
      className={helpClassName}
      help={formHelp}
      validateStatus={!!formError ? "error" : undefined}
    >
      {children}
    </Form.Item>
  );
}
