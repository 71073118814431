import { Button, DatePicker, Input, Upload } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { useUploadActivityFile } from "../../../../../../hooks/files/useUploadActivityFile";
import { CloudUploadOutlined } from "@ant-design/icons";
import "../../modalEvidence.scss";
import { ValidationEvidence } from "../../../../../../models/ValidationEvidence";
type Props = {
  onClose: () => void;
  refreshList: any;
  activity_id?: string;
  validation?: ValidationEvidence | null;
};
export default function UploadEvidence({ onClose, activity_id, refreshList, validation }: Props) {
  const { handleUpload, isUploading } = useUploadActivityFile();
  const [date, setDate] = useState("");
  const [dynamicValidation, setDynamicValidation] = useState("");
  const requiredValidation = !!validation?.dynamic_validation && validation.is_required && !dynamicValidation;
  const disable = !!date;

  return (
    <div className="container-modal-evidence">
      <div className="container-date-radio">
        <span>Defina a data de execução da evidência:</span>
        <DatePicker allowClear format={"DD/MM/YYYY"} onChange={(e: any) => setDate(dayjs(e)?.format("YYYY-MM-DD"))} />
      </div>
      {!!validation?.dynamic_validation && (
        <div className="container-date-radio">
          <span>{validation?.dynamic_validation}</span>
          <Input
            value={dynamicValidation}
            onChange={(e) => {
              setDynamicValidation(e.target.value);
            }}
          />
        </div>
      )}
      <Upload
        beforeUpload={(file) => {
          return false;
        }}
        showUploadList={false}
        className="upload-cloud-btn"
        onChange={(e) => {
          handleUpload({
            e,
            activity_id,
            setRefreshFileList: refreshList,
            execution_date: date,
            validation: validation?.id || null,
            dynamic_validation: dynamicValidation,
          });
          onClose();
        }}
        disabled={isUploading}
        capture
      >
        <Button icon={<CloudUploadOutlined />} loading={isUploading} disabled={!disable || requiredValidation}>
          {isUploading ? "Carregando arquivo" : "Selecionar arquivos..."}
        </Button>
      </Upload>
    </div>
  );
}
