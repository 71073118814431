import { useState } from "react";
import api from "../../config/axios";
import { Hired } from "../../models/Hired";
import { toastAlert } from "../../utils/toastAlert";

type Props = {
  hired: Hired;
  hiredActivities: number[] | string[];
  handleConfirm: VoidFunction;
  errorMessage?: string;
};

export const useAddManyActivities = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAddActivities = async ({
    hired,
    hiredActivities,
    handleConfirm,
    errorMessage,
  }: Props) => {
    if (hiredActivities.length === 0) {
      toastAlert({
        type: "warning",
        message: errorMessage || `Selecione uma atividade para atribuir.`,
        title: "Espere!",
      });
      return;
    }
    setIsSubmitting(true);
    await api
      .post("/v1/contracts/create/many", {
        hired_id: hired.id,
        activities: hiredActivities,
      })
      .then((response) => {
        handleConfirm();
        toastAlert({
          type: "success",
          message: "As atividades foram atribuídas e já podem ser vistas na lista de atividades da contratada.",
          title: "Novas atividades atribuídas",
        });
      })
      .catch((error) => {
        toastAlert({
          type: "error",
          message:
            "As atividades não foram atribuídas. Confira sua conexão e tente novamente em alguns minutos.",
          title: "Erro ao atribuir atividades",
        });
      })
      .finally(() => setIsSubmitting(false));
  };

  return { handleAddActivities, isSubmitting };
};
