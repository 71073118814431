import { Button, Col, Form, Pagination, PaginationProps, Progress, Row, Select, Table, TableColumnsType, Typography } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../components/SearchInput";
import CollapseCard from "../../components/Template/Card";
import Layout from "../../components/Template/Layout";
import api from "../../services/Api";
import "./activityPage.scss";
import { columns } from "./constants/columns";
import { statusEvidenceValues } from "./constants/statusEvidenceValues";
import dayjs from "dayjs";
import DatePickerComponent from "../../components/DatePicker/DatePicker";
const { Title } = Typography;

function ActivityPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [dinamicColumns, setDinamicColumns] = useState(columns);
  const [projects, setProjects] = useState([]);
  const [pageSize, setPageSize] = useState(30);
  const [date, setDate] = useState<Date[]>([]);
  const [filters, setFilters] = useState({
    activityStatus: undefined,
    measureStatus: undefined,
    status_evidence: undefined,
    contract: undefined,
    project: undefined,
    reloadActivities: true,
  });
  const [measureStatusList, setMeasureStatusList] = useState([]);
  const [activityStatusList, setActivityStatusList] = useState([]);

  const navigate = useNavigate();
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    if (!!filters.contract) {
      setDinamicColumns([
        ...columns,
        /*    {
          title: "Total consumido",
          dataIndex: "total_activities_value",
          render: (name, record) => <div>{name ? formatCurrency(name) : "-"}</div>,
          key: "total_consumido",
        },
        {
          title: "Consumo",
          dataIndex: "consumption_percentage",
          render: (name, record) => (
            <div className="mr-3">
              <Progress percent={name} />
            </div>
          ),
          key: "consumo",
          width: 180,
        }, */
      ]);
    } else {
      setDinamicColumns(columns);
    }
  }, [filters]);

  async function getData() {
    setIsLoading(true);
    const startDate = date?.length > 0 ? dayjs(date[0])?.format("YYYY-MM-DD") : "";
    const endDate = date?.length > 0 ? dayjs(date[1])?.format("YYYY-MM-DD") : "";
    await api
      .get(
        `/v1/activity/all?page=${current}&measure_status=${filters.measureStatus || ""}&activity_status=${
          filters.activityStatus || ""
        }&status_evidence=${filters.status_evidence || ""}&project=${filters.project || ""}&contract=${
          filters.contract || ""
        }&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => {
        if (response?.status === 200) {
          const data = response.data.data;

          const table = data.map((obj: any) => ({
            ...obj,
            key: obj.id,
            actions: (
              <>
                <Button type="primary" onClick={() => navigate("/activity/" + obj.id)}>
                  Abrir
                </Button>
              </>
            ),
          }));

          setDataTable(table);
          setTotal(response.data.total);
          setPageSize(response.data.per_page);
        }
      })
      .finally(() => setIsLoading(false));
  }

  async function getActivityStatus() {
    await api.get(`/v1/activity-status/filters`).then((response) => {
      const status = response.data.status.map((obj: any) => ({
        ...obj,
        key: obj.id,
        value: obj.id,
        label: obj.name,
      }));
      const contracts = response.data.contracts.map((obj: any) => ({
        value: obj.id,
        label: obj.description,
      }));
      const projects = response.data.projects.map((obj: any) => ({
        value: obj.id,
        label: obj.name,
      }));
      setProjects(projects);
      setContracts(contracts);
      setActivityStatusList(status);
    });
  }

  async function getMeasureStatus() {
    await api.get(`/v1/measure-status/all`).then((response) => {
      if (response.status === 200) {
        const data = response.data.map((obj: any) => ({
          ...obj,
          key: obj.id,
          value: obj.id,
          label: obj.show_name,
        }));

        setMeasureStatusList(data);
      }
    });
  }
  // search states and functions

  const [search, setSearch] = useState("");
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const handleSearch = (search: string) => {
    setIsLoading(true);
    setFilters({
      activityStatus: undefined,
      measureStatus: undefined,
      status_evidence: undefined,
      contract: undefined,
      project: undefined,
      reloadActivities: false,
    });
    setDate([]);

    if (search !== "") {
      api
        .post("/v1/activity/all/search", {
          search: search,
        })
        .then((r) => {
          const data = r.data;
          const table = data.map((obj: any) => ({
            ...obj,
            key: obj.id,
            actions: (
              <>
                <Button type="primary" onClick={() => navigate("/activity/" + obj.id)}>
                  Abrir
                </Button>
              </>
            ),
          }));

          setDataTable(table);
          setTotal(table.length);
          setPageSize(table.length);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      getData();
    }
  };

  // end of search

  useEffect(() => {
    api.get(`/v1/user/roles`).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        setRoles(data.roles);
      }
    });
  }, []);

  const onChange: PaginationProps["onChange"] = (page) => {
    setCurrent(page);
  };

  useEffect(() => {
    if (filters.reloadActivities) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, JSON.stringify(filters), date]);

  useEffect(() => {
    getMeasureStatus();
    getActivityStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout pageTitle="Lista de Atividades" breadcrumb="Home ⟶ Atividades">
        <Row gutter={16} className="page-row">
          <Col xs={24} md={6} className="mb-2">
            <CollapseCard title="Total">
              {!!total ? (
                <Title id="activities-total" level={2} className="total-card">
                  {total}
                </Title>
              ) : null}
            </CollapseCard>
          </Col>
          <Col xs={24} md={18} className="mb-2">
            <CollapseCard title="Filtros">
              <Col span={24} className="mb-2 activity-filters">
                <Select
                  value={filters.activityStatus}
                  style={{ width: 170 }}
                  onChange={(e) => {
                    setFilters((prevState) => ({
                      ...prevState,
                      activityStatus: e,
                      reloadActivities: true,
                    }));
                    setDate([]);
                    setSearch("");
                  }}
                  allowClear
                  placeholder="Status da atividade"
                  options={activityStatusList}
                />

                <Select
                  value={filters.status_evidence}
                  style={{ width: 170 }}
                  onChange={(e) => {
                    setFilters((prevState) => ({
                      ...prevState,
                      status_evidence: e,
                      reloadActivities: true,
                    }));
                    setDate([]);
                    setSearch("");
                  }}
                  allowClear
                  placeholder="Status da evidencia"
                  options={statusEvidenceValues}
                />

                <Select
                  value={filters.measureStatus}
                  style={{ width: 170 }}
                  onChange={(e) => {
                    setFilters((prevState) => ({
                      ...prevState,
                      measureStatus: e,
                      reloadActivities: true,
                    }));
                    setDate([]);
                    setSearch("");
                  }}
                  allowClear
                  placeholder="Status da medição"
                  options={measureStatusList}
                />
                <Select
                  value={filters.contract}
                  style={{ width: 170 }}
                  onChange={(e) => {
                    setFilters((prevState) => ({
                      ...prevState,
                      contract: e,
                      reloadActivities: true,
                    }));
                    setDate([]);
                    setSearch("");
                  }}
                  allowClear
                  placeholder="Contratos"
                  options={contracts}
                />
                <Select
                  value={filters.project}
                  style={{ width: 170 }}
                  onChange={(e) => {
                    setFilters((prevState) => ({
                      ...prevState,
                      project: e,
                      reloadActivities: true,
                    }));
                    setDate([]);
                    setSearch("");
                  }}
                  allowClear
                  placeholder="Projetos"
                  options={projects}
                />
                <DatePickerComponent setValue={setDate} label={""} />
                <SearchInput
                  type="text"
                  handleChange={handleChangeSearch}
                  value={search}
                  handleSearch={handleSearch}
                  placeholder="Busque por nome"
                  divClasse="activity-search"
                  loading={isLoading}
                />
              </Col>
            </CollapseCard>
          </Col>
          <Col xs={24} md={24} className="mb-2">
            <CollapseCard
              title={`Tabela de Atividades${roles.some((item) => ["Hired"].includes(item)) ? " com Contrato" : ""}`}
              className="card-table-no-padding"
            >
              <Row>
                <div className="table-report-container">
                  {dataTable.length > 0 ? (
                    <Table columns={columns} dataSource={dataTable} pagination={false} loading={isLoading} size="small" />
                  ) : (
                    <Table className="table-no-data-rows" columns={columns} dataSource={[]} pagination={false} loading={isLoading} size="small" />
                  )}
                </div>

                <Col
                  span={24}
                  className="text-center mt-4"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "5px",
                  }}
                >
                  <Pagination current={current} onChange={onChange} total={total} pageSize={pageSize} showSizeChanger={false} />
                </Col>
              </Row>
            </CollapseCard>
          </Col>
        </Row>
      </Layout>
    </>
  );
}

export default ActivityPage;
